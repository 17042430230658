import React, {useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {Elements} from "@stripe/react-stripe-js";
import {StripeWalletCreateCard} from "./Stripe.Wallet.Create.Card";

export const StripeWalletCreateCardContainer = (props) => {
    const [stripePromise] = useState(() => loadStripe(props.publicKey))

    return (
        <Dialog
            open={props.isOpen}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle  style={{padding:"8px 12px", textAlign:"center"}}>Ajouter une carte</DialogTitle>
            <DialogContent style={{minWidth:"340px", padding:"8px 12px"}}>
                <Elements stripe={stripePromise}>
                    <StripeWalletCreateCard clientIntentSecret={props.clientIntentSecret} onClose={props.onClose} hydrateInfoMobinaute={props.hydrateInfoMobinaute}/>
                </Elements>
            </DialogContent>
        </Dialog>
    );
}

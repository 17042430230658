import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Button} from "@material-ui/core";
import React from "react";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faUndoAlt} from "@fortawesome/free-solid-svg-icons";

export const StripeButtons = (props) => {
    const {t} = useTranslation();
    const style = useSelector(state => state.style);

    return (
       <>
           <Row>
               <Col>
                   <Button
                       type="submit"
                       variant="contained"
                       color="primary"
                       fullWidth
                       style={{ backgroundColor:style.button.backgroundColor, marginTop:"20px", marginBottom:"20px"}}
                       disabled={props.disabled}
                   >
                       {t('market_btn_validate_order')}
                       <FontAwesomeIcon
                           icon={faLock}
                           style={{ marginLeft: "10px"}}
                       />
                   </Button>
               </Col>
           </Row>
           <Row>
               <Col>
                   <Button
                       onClick={() => props.cancelOrder()}
                       variant="contained"
                       color="primary"
                       fullWidth
                       style={{ backgroundColor:style.button.backgroundColor, opacity: "0.8"}}
                   >
                       {t('form_btn_product_cancel')}
                       <FontAwesomeIcon
                           icon={faUndoAlt}
                           style={{ marginLeft: "10px"}}
                       />
                   </Button>
               </Col>
           </Row>
       </>
    );
}


import {store} from "../store/store";
import axios from "axios";
import * as Constants from "./api.constants";
import {baseApiUrlAnalytics} from "./api";


export const fetchSendContentStats = async (contentId) => {
    try {
        console.log("fetchSendContentStats");
        console.log("contentId => " + contentId);

        const persistStore = store.getState()
        const mobinaute = persistStore.profil.mobinaute;
        const application = persistStore.application;
        let mobinauteId = null;
        if(mobinaute){
            mobinauteId = mobinaute.id;
        }
        let appId = application.getApp.id;
        let deviceId = application.deviceId;

        //au format YYYY-MM-DD HH:MM:SS UTC+2
        let dateOpen = new Date().toISOString().slice(0, 19).replace("T", " ");
        let dateClose = new Date().toISOString().slice(0, 19).replace("T", " ");

        let dataJson = {
            "synAppId": appId,
            "deviceId": deviceId,
            "previousUrl": document.referrer,
            "statLogRegistred": [
                {
                    "mobinauteId":mobinauteId,
                    "contents":[
                        {
                            "contentId": contentId,
                            "dateOpen": dateOpen,
                            "dateClose": dateClose
                        }
                    ]
                }
            ]
        };
        // has been blocked by CORS policy: Response to preflight request doesn't pass access control check: No 'Access-Control-Allow-Origin' header is present on the requested resource.

        return await axios({
            method: "post",
            url: baseApiUrlAnalytics + Constants.WS_ANALYTICS_SEND_CONTENT_STATS,
            data: dataJson,
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
        })
            .then(function (response) {
                //handle success
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

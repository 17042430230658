export const ADD_PROFIL_FORM_DATA = "ADD_PROFIL_FORM_DATA";
export const ADD_MOBINAUTE_DATA = "ADD_MOBINAUTE_DATA";
export const RESET_MOBINAUTE_DATA = "RESET_MOBINAUTE_DATA";

export const addProfilFormData= (data) => {
    return {
        type: ADD_PROFIL_FORM_DATA,
        payload: data
    }
}

export const addMobinauteData= (data) => {
    return {
        type: ADD_MOBINAUTE_DATA,
        payload: data
    }
}

export const resetMobinauteData = () => ({
    type: RESET_MOBINAUTE_DATA
});

import React from "react";
import {Avatar, Container, Divider, List, ListItem, ListItemAvatar} from "@material-ui/core";
import { Col, Row, ListGroup } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {arrayFilter, buildShapperPath, dateToLocaleString, prettyPrice} from "../../../helpers/Tools";
import {CategoryList} from "../List/Category.List";

class CategoryGlossary extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const dyn_string = this.props.t

        if(arrayFilter(this.props.nodes, 'is_visible', '1').length === 0){
            return <div><h4 id="list-map-no-result">{dyn_string('g_label_no_result')}</h4></div>
        } else {
            let nodesSortedByLetter = {}
            this.props.nodes.forEach((content) => {
                if(!nodesSortedByLetter[Array.from(content.title)[0]]) {
                    nodesSortedByLetter[Array.from(content.title)[0]] = []
                }
                nodesSortedByLetter[Array.from(content.title)[0]].push(content)
            });

            const nodesSortedAlphabetically = {}
            Object.keys(nodesSortedByLetter).sort().forEach(function(key) {
                nodesSortedAlphabetically[key] = nodesSortedByLetter[key];
            });

            return (
                <div>
                    {Object.entries(nodesSortedAlphabetically).map(([key, value]) => (
                        <div key={key}>
                            <h2 style={{padding: "10px", margin: "0px", backgroundColor: "#f6f9fc"}}>{key}</h2>
                            <CategoryList nodes={value} dataPage={this.props.dataPage}/>
                        </div>
                    ))}
                </div>
            );
        }
    }
}

const mapStateToProps = (props) => ({
    styles: props.style
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps()))(CategoryGlossary);

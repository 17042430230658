import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import { withTranslation} from 'react-i18next';
import {requestGetAnnouncesPropositions, resetAnnouncesPropositions} from "../../../actions";
import {ShapperButton} from "../../../components/ShapperButton/ShapperButton";
import {DisplayAnnounces} from "./Announce.Mobinaute.Display";

class AnnounceMobinaute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            display_published_announces: true,
            display_interested_announces: false,
            active_btn_published: true,
            active_btn_interested: false,
        };
    }

    componentDidMount() {
        this.props.requestGetAnnouncesPropositions();
    }

    render() {

        const {published_announces, interested_announces} = this.props.announces;

        return (
            <React.Fragment>

                <div id="header-announce-mobinaute">

                    <ShapperButton variant={this.state.active_btn_published ? "" : "outlined"} onClick={()=>{this.setState({
                        display_published_announces: true,
                        display_interested_announces: false,
                        active_btn_published: true,
                        active_btn_interested: false,
                    })}}>
                        {this.props.t('mrkt_announce_btn_announce_mobinaute_published', {number:published_announces.length})}
                    </ShapperButton>

                    <ShapperButton variant={this.state.active_btn_interested ? "" : "outlined"} onClick={()=>{this.setState({
                        display_published_announces: false,
                        display_interested_announces: true,
                        active_btn_published: false,
                        active_btn_interested: true,
                    })}}>
                        {this.props.t('mrkt_announce_btn_announce_mobinaute_interested', {number:interested_announces.length})}
                    </ShapperButton>

                </div>

                <div id="announces-container">

                    {this.state.display_published_announces && (
                        <DisplayAnnounces announces={published_announces} type={"published"} />
                    )}

                    {this.state.display_interested_announces && (
                        <DisplayAnnounces announces={interested_announces} type={"interested"} />
                    )}

                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    styles: state.style,
    announces: state.announces
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetAnnouncesPropositions,
            resetAnnouncesPropositions
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(AnnounceMobinaute);

import {useSelector} from "react-redux";
import React from "react";
import "./SegmentedBar.css"

export const  SegmentedBar = (props) => {

    const style = useSelector(state => state.style);
    let children = (props.children.filter((a) => a)); //remove empty values

    return (
        <React.Fragment>
            {children.length !== 0 && (
                <div id="buttons-wrapper" style={style.segmentedbar}>

                    {children}

                </div>
            )}
        </React.Fragment>

    )
}

export const SegmentedBarButton = (props) => {

    const {href, icon, target} = props;
    const styles = useSelector(state => state.style);

    return (
        <a href={href} style={{padding: "5px 0"}} target={target ?? ""} onClick={props.onClick}>
            <div className="button-wrapper">

                <div className={icon} style={{color: styles.segmentedbar.icon_color, fontSize: styles.segmentedbar.fontSize,}} />

                <span style={{color: styles.segmentedbar.icon_color, fontSize: styles.segmentedbar.fontSize,}}>
                    {props.children}
                </span>
            </div>
        </a>
    )
}

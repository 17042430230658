import React from "react";
import {
    Typography
} from "@material-ui/core";
import "../mobinaute.wallet.css"
import {useTranslation} from "react-i18next";
import MediaQuery from 'react-responsive'

export const MobinauteWalletCardElementInfo = (props) => {

    return (
        <div id="card-info">
            {props.type === "stripe" && (
                <StripeInfo currentCard={props.currentCard}/>
            )}

            {props.type === "tag" && (
                <TagInfo currentCard={props.currentCard}/>
            )}
        </div>
    )
}

const StripeInfo = (props) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <MediaQuery minDeviceWidth={800}>
                <Typography component="h1" variant="h6" id="card-info-title">
                    {props.currentCard.label} ...{props.currentCard.last4}
                </Typography>

                <Typography component="h2" variant="subtitle1">
                    {t('market_label_wallet_expiration_date')} <span style={{marginLeft: "5px"}}>{props.currentCard.expiration}</span>
                </Typography>
            </MediaQuery>

            <MediaQuery maxDeviceWidth={800}>
                <span>
                    <b>{props.currentCard.label}</b> {t('market_label_last4_card')} <b>{props.currentCard.last4}</b>
                </span>
            </MediaQuery>
        </React.Fragment>
    )
}

const TagInfo = (props) => {
    const { t } = useTranslation();

    let date = t('g_label_nothing');

    if(props.currentCard.end_date){
        date = new Date(props.currentCard.end_date)
        date = date.toLocaleString('fr-FR')
    }

    return (
        <React.Fragment>
            <MediaQuery minDeviceWidth={800}>
                <Typography component="h1" variant="h6" id="card-info-title">
                    {props.currentCard.is_active !== "0" ? <i className="fa fa-check-circle" style={{color: "green", padding:"0 8px"}} /> : <i className="fa fa-times-circle" style={{color: "red", marginRight:"8px"}} />}
                    {props.currentCard.comment || props.currentCard.uid}
                </Typography>

                <Typography component="h2" variant="subtitle1">
                    {t('market_label_wallet_expiration_date')} <span style={{marginLeft: "5px"}}>{date}</span>
                </Typography>
            </MediaQuery>

            <MediaQuery maxDeviceWidth={800}>
                <span>
                    {props.currentCard.is_active !== "0" ? <i className="fa fa-check-circle" style={{color: "green", padding:"0 8px"}} /> : <i className="fa fa-times-circle" style={{color: "red", marginRight:"8px"}} />}
                    {props.currentCard.comment || props.currentCard.uid}
                </span>
            </MediaQuery>
        </React.Fragment>
    )
}

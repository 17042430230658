import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {logout} from "../NavBar.functions";

/**
 * NavBar responsive header
 */
class NavBarMobileHeader extends React.Component {

    render() {

        return (
            <div
                style={{
                    backgroundColor: this.props.styles.navbar.backgroundColor,
                    height: "50px",
                    width: "100%",
                    position: "fixed",
                    zIndex: 999,
                }}
            >
                {this.props.application.isIdentComp && this.props.connected ? (
                    <div style={{width: "100%", textAlign:"right"}}>
                            <span
                                style={{
                                    marginRight: "15px",
                                    lineHeight: "48px",
                                    color: this.props.styles.navbar.text_color,
                                    fontSize: this.props.styles.navbar.fontSize+"px",
                                }}
                                onClick={logout}
                            >
                            {this.props.t('profile_btn_logout')}
                        </span>
                    </div>
                ) : (
                    <span
                        style={{
                            marginLeft: "65px",
                            marginRight: "10px",
                            display: "block",
                            lineHeight: "48px",
                            color: this.props.styles.navbar.text_color,
                            fontSize: this.props.styles.navbar.fontSize+"px",
                            whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"
                        }}
                    >
                            {/* Recupération titre Page*/}
                        {this.props.application.title}
                        </span>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    application: state.application,
    styles: state.style,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(NavBarMobileHeader);


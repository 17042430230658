import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {StripeCardsElement} from "./Stripe.Cards.Element";
import React from "react";
import {constructStripeCardUI} from "./Stripe.functions";
import "./stripe.css"

export const StripeCards = (props) => {
    const style = useSelector(state => state.style);
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.isOpen}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle className="Dialog">{t('form_alert_msg_info_choose_payment_methods')}</DialogTitle>
            <DialogContent>
                {props.paymentMethods.map((paymentMethod, key) => {

                    let card = constructCardForRender(paymentMethod, props.paymentMethodSelected)

                    return (
                        <StripeCardsElement
                            card={card}
                            handleCardChoice={props.handleCardChoice}
                            key={key}
                            paymentMethod={paymentMethod}
                            hydratePaymentMethods={props.hydratePaymentMethods}
                        />
                    )
                })}
            </DialogContent>
            <DialogActions style={{marginTop: "8px"}}>
                <Button
                    onClick={() => {
                        props.setDisplayPickUpElement(false);
                    }}
                    style={{
                        backgroundColor: style.button.backgroundColor,
                        borderColor: style.button.backgroundColor,
                        color: style.button.color
                    }}
                >
                    {t('form_btn_product_cancel')}
                </Button>

                <Button
                    onClick={() => {
                        props.setDisplayPickUpElement(false);
                        props.setDisplayCardElement(true);
                        props.setPaymentMethodSelected(null);
                    }}
                    style={{
                        backgroundColor: style.button.backgroundColor,
                        borderColor: style.button.backgroundColor,
                        color: style.button.color,
                    }}
                >
                    <div className="CardsButton">
                        {t('market_label_add_card')}
                    </div>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

function constructCardForRender(paymentMethod, paymentMethodSelected){
    let cart = constructStripeCardUI(paymentMethod);

    return {
        ...cart,
        isSelected: paymentMethodSelected && paymentMethod.id === paymentMethodSelected.id
    }
}

import { ADD_ARTICLE, DELETE_ARTICLE, PURGE_CART} from "../actions/index";

const initState = {
    currentSupplier: null,
    items: [],
}

const cart = (state= initState, action) => {
    switch (action.type) {
        case ADD_ARTICLE:
            if(!state.currentSupplier){
                return {
                    currentSupplier: action.payload.product.supplier_id,
                    items: [...state.items, action.payload]
                }
            } else {
                return {
                    ...state,
                    items: [...state.items, action.payload]
                }
            }
        case DELETE_ARTICLE:
            let index  = state.items.findIndex(element => element.variation.id === action.payload)
            if(state.items.length === 1){
                return {
                    currentSupplier: null,
                    items: [
                        ...state.items.slice(0, index),
                        ...state.items.slice(index + 1)
                    ]
                }
            } else {
                return {
                    ...state,
                    items: [
                        ...state.items.slice(0, index),
                        ...state.items.slice(index + 1)
                    ]
                }
            }
        case PURGE_CART:
            return {
                currentSupplier: null,
                items: []
            }
        default:
            return state
    }
}

export default cart;

import React from "react";
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {useSelector} from "react-redux";

export const MobinauteWalletHeaderCardLabel = (props) => {
    const { t } = useTranslation();
    const style = useSelector(state => state.style);

    return (
        <Typography component="h1" variant="h6" style={{fontWeight: "200", cursor: "pointer"}} onClick={props.displayAddCard}>
            {props.type === "stripe" ? t('market_label_add_card') : t('tag_label_add_tag')} <AddCircleOutlineIcon style={{marginLeft: "8px", fontSize: "25px" ,color: style.button.backgroundColor}} />
        </Typography>
    )
}

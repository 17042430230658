import React from "react";
import  {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {ShapperButton} from "../../components/ShapperButton/ShapperButton";

export const ErrorForm = (props) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.isErrorForm}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>{t('form_alert_title_info_warning')}</DialogTitle>
            <DialogContent style={{minWidth: "300px"}}>
                <DialogContentText id="alert-dialog-description">
                    {props.firstError ? props.firstError : t('form_alert_msg_err_send_form')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ShapperButton onClick={props.onClose}>
                    {t('g_btn_ok')}
                </ShapperButton>
            </DialogActions>
        </Dialog>
    );
}

export const ConfirmRegister = (props) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('form_alert_msg_succ_send_form')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ShapperButton onClick={props.showLogin}>
                    {t('g_btn_ok')}
                </ShapperButton>
            </DialogActions>
        </Dialog>
    );
}

export const ConfirmProfilForm = (props) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('form_alert_msg_succ_send_form')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ShapperButton onClick={props.onClose}>
                    {t('g_btn_ok')}
                </ShapperButton>
            </DialogActions>
        </Dialog>
    );
}

export const ConfirmDynamicForm = (props) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>{t('form_alert_msg_succ_send_form')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.validationText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>

                <ConfirmDynamicFormButton dataPage={props.dataPage} onClose={props.onClose} />

            </DialogActions>
        </Dialog>
    );
}

const ConfirmDynamicFormButton = (props) => {
    const RoutePathArray = useSelector(state => state.routes.RoutePathArray);
    const { t } = useTranslation();

    if (props.dataPage){
        if (props.dataPage.form && props.dataPage.form.syn_content_redirect && RoutePathArray[props.dataPage.form.syn_content_redirect]){
            return (
                <Link to={RoutePathArray[props.dataPage.form.syn_content_redirect].url}>
                    <ShapperButton>
                        {t('g_btn_ok')}
                    </ShapperButton>
                </Link>
            )
        } else {
            return (
                <ShapperButton onClick={props.onClose}>
                    {t('g_btn_ok')}
                </ShapperButton>
            )
        }
    }
}

export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const SET_PAGE_ID = "SET_PAGE_ID";
export const SET_APP_SECRET_KEY = "SET_APP_SECRET_KEY";
export const SET_APP_GET_APP = "SET_APP_GET_APP";
export const SET_APP_DEVICE = "SET_APP_DEVICE";
export const SET_APP_ID_SSO = "SET_APP_ID_SSO";
export const SET_REMEMBER_ME = "SET_REMEMBER_ME";
export const SET_EULA = "SET_EULA";
export const SET_LANGUAGE_APP = "SET_LANGUAGE_APP";
export const SET_DEVICE_TOKEN = "SET_DEVICE_TOKEN";
export const INIT_APP_ERROR = 'INIT_APP_ERROR';
export const SET_REDIRECT_ERROR = 'SET_REDIRECT_ERROR';
export const SET_IS_IDENT_COMP = 'SET_IS_IDENT_COMP';
export const SET_APP_INNER_SIZE = 'SET_APP_INNER_SIZE';
export const SET_APP_SIZE = 'SET_APP_SIZE';
export const RESET_APP = 'RESET_APP';
export const RESET_APP_ERROR = 'RESET_APP_ERROR';
export const SET_ELEMENT_TYPE = 'SET_ELEMENT_TYPE';

export const setPageTitle = (title) => {
    return {
        type: SET_PAGE_TITLE,
        payload: title
    }
}

export const setElementType = (type) => {
    return {
        type: SET_ELEMENT_TYPE,
        payload: type
    }
}

export const setIsIdentComp = (isIdentComp) => {
    return {
        type: SET_IS_IDENT_COMP,
        payload: isIdentComp
    }
}

export const setPageId = (id) => {
    return {
        type: SET_PAGE_ID,
        payload: id
    }
}

export const setAppSecretKey = (secretKey) => {
    return {
        type: SET_APP_SECRET_KEY,
        payload: secretKey
    }
}

export const setAppGetApp = (info) => {
    return {
        type: SET_APP_GET_APP,
        payload: info
    }
}

export const setAppDevice = (device) => {
    return {
        type: SET_APP_DEVICE,
        payload: device
    }
}

export const setAppInnerSize = (sizes) => {
    return {
        type: SET_APP_INNER_SIZE,
        payload: sizes
    }
}

export const setAppSize = (sizes) => {
    return {
        type: SET_APP_SIZE,
        payload: sizes
    }
}

export const setAppIdSSO= (sso) => {
    return {
        type: SET_APP_ID_SSO,
        payload: sso
    }
}

export const errorInitApp = (info) => {

    console.log(info);

    return {
        type: INIT_APP_ERROR,
        payload: info
    }
}

export const setRememberMe = (remember_me) => {
    return {
        type: SET_REMEMBER_ME,
        payload: remember_me
    }
}

export const setEulaHaveBeenAccepted = (setEulaHaveBeenAccepted) => {
    return {
        type: SET_EULA,
        payload: setEulaHaveBeenAccepted
    }
}

export const setLanguageApp = (lang) => {
    return {
        type: SET_LANGUAGE_APP,
        payload: lang
    }
}

export const setRedirectError = (toggleError) => {
    return {
        type: SET_REDIRECT_ERROR,
        payload: toggleError
    }
}

export const resetApp = () => {
    return {
        type: RESET_APP,
    }
}

export const resetAppError = () => {
    return {
        type: RESET_APP_ERROR,
    }
}

import {getTitleById, isResponsive} from "../helpers/Tools";
import {Breadcrumbs} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";


class BreadCrumbs extends React.Component {
    render() {
        let myTab = []
        let breadCrumbs = getTitleById( this.props.routes.RoutePathArray, this.props.id, myTab);

        if (breadCrumbs.length <= 1 || isResponsive()) {
            return ""
        } else {
            return (
                <Breadcrumbs   aria-label="breadcrumb" style={{marginLeft:"10px", marginBottom:"20px"}}>
                    {breadCrumbs.map((tile, key) => (
                        <Link color="inherit" to={this.props.routes.RoutePathArray[tile.id].url} key={key} style={{textDecoration: "none", color:"black"}}>
                            {tile.title}
                        </Link>
                    ))}
                </Breadcrumbs>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbs);

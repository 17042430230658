import React from "react";
import image_not_found from "../../assets/image_not_found.png";
import {ImageCustom, youtubeUrl} from "../../helpers/Tools";


class PageMedia extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        const {dataPage} = this.props;
        let media = dataPage.type === "event" ? dataPage.medias[0] : dataPage.media_url;

        return (
            <div>
                <div id="media-page">
                    {dataPage.youtube_url ? (
                        <VideoHeader youtube_url={dataPage.youtube_url} />
                    ) : media ? (
                        <ImageHeader media_url={media} />
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>
        )
    }
}

const ImageHeader = ({media_url}) => {

    return (
        <ImageCustom
            src={media_url}
            alt="test"
            fallback={
                <img style={{width: "100%"}} src={image_not_found} alt={"image_not_found"}/>
            }
            type="page"
        />
    );
}

const VideoHeader = ({youtube_url}) => {
    return (
        <iframe
            title="youtube"
            width="100%"
            height="250px"
            src={
                youtubeUrl(youtube_url)+
                "?autoplay=1" +
                "&controls=1" +
                "&loop=1"
            }
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    );
}

export default PageMedia;

import {INIT_PLAYLIST, NEXT_PLAYLIST_ELEMENT, PREVIOUS_PLAYLIST_ELEMENT, SELECT_PLAYLIST_ELEMENT} from "../actions/index";

const playlistPlayer = (state={}, action) => {
    switch (action.type) {
        case INIT_PLAYLIST:
            return action.payload;
        case NEXT_PLAYLIST_ELEMENT:
            const hasReachedEnd = state.currentIndex === state.list.length - 1;
            return {
                ...state,
                currentIndex: hasReachedEnd ? 0 : state.currentIndex + 1,
            };
        case PREVIOUS_PLAYLIST_ELEMENT:
            const hasReachedStart = state.currentIndex === 0;
            return {
                ...state,
                currentIndex: hasReachedStart ? state.list.length - 1 : state.currentIndex - 1,
            };
            default:
            return state
        case SELECT_PLAYLIST_ELEMENT:
            return {
                ...state,
                currentIndex: action.payload,
            };
    }
}

export default playlistPlayer;

export const ADD_ARTICLE = "ADD_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const PURGE_CART = "PURGE_CART";

export const addArticle = (product) => {
    return {
        type: ADD_ARTICLE,
        payload: product
    }
}

export const deleteArticle = (indexToRemove) => {
    return {
        type: DELETE_ARTICLE,
        payload: indexToRemove
    }
}

export const purgeCart = () => {
    return {
        type: PURGE_CART,
    }
}

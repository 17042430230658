import React from "react";
import { stack as Menu } from "react-burger-menu";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import NavBarMobileHeader from "./NavBar.Mobile.Header";
import {NavBarMobileLogo} from "./NavBar.Mobile.Logo";
import {NavBarMobileBody} from "./NavBar.Mobile.Body";
import {NavBarMobileStyle} from "./NavBar.Mobile.Style"
import {arrayFilter} from "../../../helpers/Tools";

/**
 * NavBar responsive
 */
class NavBarMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            menuOpen: false,
        };

        this.closeMenu = this.closeMenu.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    closeMenu(e) {
        e.preventDefault();
        this.setState({ menuOpen: false });
    }

    handleStateChange(state) {
        this.setState({ menuOpen: state.isOpen });
    }

    render() {

        if(!this.props.application.getApp.webParams){
            return <h1> Activer le mode Web</h1>
        }

        return (
            <>
                <NavBarMobileHeader connected={this.props.connected} />

                <Menu
                    isOpen={this.state.menuOpen}
                    onStateChange={(state) => this.handleStateChange(state)}
                    styles={NavBarMobileStyle(this.props.styles.menu.backgroundColor)}
                    width={"250px"}
                >

                    <NavBarMobileLogo />

                    <NavBarMobileBody
                        items={arrayFilter(this.props.result, 'is_visible', '1')}
                        connected={this.props.connected}
                        width={this.state.width}
                        height={this.state.height}
                        closeMenu={this.closeMenu}
                        dataPage={this.props.dataPage}
                    />

                </Menu>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.cart.items,
    application: state.application,
    styles: state.style,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(NavBarMobile);


import {ADD_PROFIL_FORM_DATA, ADD_MOBINAUTE_DATA, RESET_MOBINAUTE_DATA} from "../actions/index";

const initState = {
    profil_form: {},
    mobinaute: {}
}

const profil = (state= initState, action) => {
    switch (action.type) {
        case ADD_PROFIL_FORM_DATA:
            return {
                ...state,
               profil_form: action.payload
            };
        case ADD_MOBINAUTE_DATA:
            return {
                ...state,
                mobinaute: action.payload
            };
        case RESET_MOBINAUTE_DATA:
            return {
                ...state,
                mobinaute: {}
            };
        default:
            return state
    }
}

export default profil;

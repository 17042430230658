import React, {useEffect, useState} from "react";
import "./Stripe/stripe.css"
import {useSelector} from "react-redux";
import {StripeInitializer} from "./Stripe/Stripe.Initializer";

export const CartCheckout = (props) => {
    const application = useSelector(state => state.application);
    const [isDisplayPayment, displayPayment] = useState(false);
    const order = props.order;

    useEffect( () => {
        if(order){
            if(application.getApp.has_market_payment === "1" &&  order.payment && order.payment.id === "stripe"){
                displayPayment(true)
            }
        }
    }, [application.getApp.has_market_payment, order])

    return (
        <div style={{padding: "16px 16px"}}>

            {isDisplayPayment ?
                (
                    <StripeInitializer stripeData={order.payment.info} cancelOrder={props.cancelOrder} handleBlur={props.handleBlur} />
                ) : ""
            }
        </div>
    );
}

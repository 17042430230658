import React from "react";
import { withTranslation } from 'react-i18next';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {arrayFilter} from "../../../helpers/Tools";
import OpenItem from "../../../helpers/OpenItem";
import {CardLandscape} from "../../../components/Card/Card.Landscape";
import "./CategoryProduct.css"

class CategoryProduct extends React.Component {

    render() {

        const list = arrayFilter(this.props.nodes, 'is_visible', '1')

        if(list.length === 0){
            return (
                <h4 style={{marginTop:"70px", textAlign:"center"}}>{this.props.t('g_label_no_result')}</h4>
            )
        }

        return (
            <div id="category-product-container">
                {list.map((product, key) => {

                    product.media = product.preview_url

                    return (
                        <OpenItem
                            key={key}
                            contentToRedirect={product.id}
                            isFor={'category'}
                        >
                            <CardLandscape item={product} type="product"  />
                        </OpenItem>
                    )
                })}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.style
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CategoryProduct);

import React from "react";
import Container from "reactstrap/es/Container";
import UpdatePasswordFormulaire from "./Mobinaute.UpdatePassword.Formulaire";
import Formulaire from "../../Formulaire/Formulaire";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {TextField} from "@material-ui/core";

/**
 * Composant Mobinaute, vérification si l'utilisateur est connecté, si oui affichage du profil, si non affichage des formulaires
*/
class MobinauteUpdatePassword extends React.Component {

    render() {
        
        //if (this.props.isConnected) {
            return (
                <>

                    <UpdatePasswordFormulaire
                        {...this.props}
                        mobinauteId={this.props.profil.mobinaute.id}
                    />
                </>

            );
        //}
    }
}

const mapStateToProps = (state) => ({
    application: state.application,
    profil: state.profil
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MobinauteUpdatePassword);

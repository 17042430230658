import {useTranslation} from "react-i18next";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {ShapperButton} from "../../components/ShapperButton/ShapperButton";
import {dateToLocaleString, isResponsive, prettyPrice} from "../../helpers/Tools";
import TextField from "@material-ui/core/TextField/TextField";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import OpenItem from "../../helpers/OpenItem";
import {getAnnouncePropositionExchanges} from "../../api/api.market";
import {Loader} from "semantic-ui-react";
import {StripeInitializer} from "../Market/Cart/Stripe/Stripe.Initializer";
import "./Announce.css"

export const ConfirmAnnounceExchangeForm = (props) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState("");

    const {announce} = props;

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
            fullScreen={isResponsive()}
        >
            <DialogTitle >
                {t('mrkt_announce_title_announce_form_title', {title: announce.title})}
            </DialogTitle>

            <DialogTitle>{t('mrkt_announce_title_announce_form_price', {price: prettyPrice(announce.price_ttc)})}</DialogTitle>

            <DialogContent>

                <DialogContentText id="alert-dialog-description">
                    {t('mrkt_announce_label_announce_form_body')}
                </DialogContentText>

                <form>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        multiline
                        rows={3}
                        rowsMax={4}
                        id={"comment"}
                        name={"comment"}
                        type="text"
                        placeholder={t('mrkt_announce_placeholder_announce_form_comment')}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        label={t('mrkt_announce_label_announce_form_comment')}
                    />
                </form>
            </DialogContent>

            <DialogActions>
                <ShapperButton onClick={props.onClose}>
                    {t('g_btn_cancel')}
                </ShapperButton>

                <ShapperButton onClick={() => props.sendResponseAnnounceProposition(comment)}>
                    {t('mrkt_announce_btn_apply')}
                </ShapperButton>
            </DialogActions>
        </Dialog>
    );
}


export const ErrorConfirmAnnounceExchangeNotConnected = (props) => {
    const { t } = useTranslation();
    const PathIdentification = useSelector(state => state.routes.PathIdentification);

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>{t('profile_alert_title_info_warning')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('mrkt_announce_alert_msg_info_announce_not_connected')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ShapperButton onClick={props.onClose}>
                    {t('g_btn_ok')}
                </ShapperButton>

                <Link to={{pathname: PathIdentification, state: {id: props.dataPage.id}}}>
                    <ShapperButton>
                        {t('auth_btn_login')}
                    </ShapperButton>
                </Link>
            </DialogActions>
        </Dialog>
    );
}

export const ErrorForm = (props) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>{t('form_alert_title_info_warning')}</DialogTitle>
            <DialogContent style={{minWidth: "300px"}}>
                <DialogContentText id="alert-dialog-description">
                    {t('form_alert_msg_err_send_form')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ShapperButton onClick={props.onClose}>
                    {t('g_btn_ok')}
                </ShapperButton>
            </DialogActions>
        </Dialog>
    );
}

export const ConfirmAnnounceExchange = (props) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>{t('form_alert_msg_succ_send_form')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">

                    {t('mrkt_announce_alert_msg_info_announce_proposition_success')}

                </DialogContentText>
            </DialogContent>
            <DialogActions>

                <ShapperButton onClick={props.onClose}>
                    {t('g_btn_ok')}
                </ShapperButton>

            </DialogActions>
        </Dialog>
    );
}

export const DisplayPropositions = (props) => {
    const { t } = useTranslation();
    const {announce} = props

    if(!announce) return ""

    const announceRedirect = "announce-"+props.type+"-"+announce.id;

    console.log(announceRedirect)

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
            scroll={"paper"}
            fullScreen={isResponsive()}
        >
            <DialogTitle id="scroll-dialog-title">{t('mrkt_announce_title_announce_form_title', {title: announce.title})}</DialogTitle>

            <DialogContent dividers={false}>

                {announce.propositions.length === 0 && (
                    <h4 style={{textAlign: "center"}}>{t('g_label_no_result')}</h4>
                )}

                {announce.propositions.map((proposition, key) => {

                    return (
                        <List key={key}
                              style={{borderRadius:"0.5rem", boxShadow:"rgb(60 64 67 / 30%) 0 1px 2px 0, rgb(60 64 67 / 15%) 0 2px 6px 2px", marginBottom:"1rem"}}
                              onClick={() => props.openExchanges(proposition)}
                        >

                            <ListItem button className="wrapper-items-propositions">

                                {proposition.candidate && (
                                    <span>{proposition.candidate.firstname} {proposition.candidate.lastname}</span>
                                )}

                                {proposition.last_exchange && (
                                    <span>{t('mrkt_announce_label_announce_last_exchange')+dateToLocaleString(proposition.last_exchange.created_at, t)}</span>
                                )}

                                <span>{proposition.state}</span>


                                {proposition.amount && (
                                    <span>{prettyPrice(proposition.amount)} €</span>
                                )}

                            </ListItem>
                        </List>
                    )
                })}

            </DialogContent>

            <DialogActions>

                <ShapperButton onClick={props.onClose}>
                    {t('g_btn_ok')}
                </ShapperButton>

                <OpenItem contentToRedirect={announceRedirect}>
                    <ShapperButton >
                        {t('mrkt_announce_btn_announce_redirect')}
                    </ShapperButton>
                </OpenItem>

            </DialogActions>
        </Dialog>
    );

}

export const DisplayPropositionExchanges = (props) => {
    const { t } = useTranslation();
    const mobinaute = useSelector(state => state.profil.mobinaute);
    const [loading, toggleLoading] = React.useState(true);
    const [exchanges, setExchanges] = React.useState([]);
    const {proposition} = props

    useEffect(() => {
        if(proposition){

            getAnnouncePropositionExchanges({propositionId: proposition.id})
                .then(response => {
                    if(response.success){
                        setExchanges(response.result)
                    }
                    toggleLoading(false)
                })
                .catch(e => {
                    console.log(e)
                    toggleLoading(false)
                })

        }
    }, [proposition])

    if(!proposition) return "";

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
            scroll={"paper"}
            fullScreen={isResponsive()}
        >
            <DialogTitle id="scroll-dialog-title">{t('mrkt_announce_alert_title_info_announce_exchange_history')}</DialogTitle>

            <DialogContent dividers={false}>

                {loading && (
                    <div style={{padding: "2rem"}}>
                        <Loader active={loading} />
                    </div>
                )}

                {(!loading && exchanges.length === 0) ? (
                    <h4 style={{textAlign: "center"}}>{t('g_label_no_result')}</h4>
                ) : ""}

                {!loading && (
                    <React.Fragment>
                        {exchanges.map((exchange, key) => {

                            return (
                                <List key={key}
                                      style={{borderRadius:"0.5rem", boxShadow:"rgb(60 64 67 / 30%) 0 1px 2px 0, rgb(60 64 67 / 15%) 0 2px 6px 2px", marginBottom:"1rem"}}
                                >

                                    <ListItem button style={{display:"flex", alignItems:"center", justifyContent:"space-between", flexWrap:"wrap", gap:"0.5rem"}}>

                                        <span>{dateToLocaleString(exchange.created_at, t)}</span>

                                        <span>{exchange.message}</span>

                                        {exchange.amount && (
                                            <span>{prettyPrice(exchange.amount)} €</span>
                                        )}

                                    </ListItem>
                                </List>
                            )
                        })}
                    </React.Fragment>
                )}

            </DialogContent>


            {!loading && (

                <DialogActions>

                    {(proposition.state !== "pending"
                        || (props.type === "interested" && proposition.candidate_id === mobinaute.id)
                        || (props.type === "published" && proposition.candidate.id === mobinaute.id))
                        ? (
                        <ShapperButton onClick={props.onClose} >
                            {t('mrkt_announce_btn_announce_close')}
                        </ShapperButton>
                    ) : (
                        <div style={{width: "100%", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                            <ShapperButton onClick={props.onClose} >
                                {t('mrkt_announce_btn_announce_close')}
                            </ShapperButton>

                            <div>
                                <ShapperButton style={{marginRight: "1rem"}} onClick={() => {props.handleExchange({state:"accepted", propositionId:proposition.id, announceId: proposition.mrkt_announce_id})}} >
                                    {t('mrkt_announce_btn_announce_accept')}
                                </ShapperButton>

                                <ShapperButton variant={"outlined"} onClick={() => {props.handleExchange({state:"refused", propositionId:proposition.id, announceId: proposition.mrkt_announce_id})}}>
                                    {t('mrkt_announce_btn_announce_refuse')}
                                </ShapperButton>
                            </div>
                        </div>
                    )}

                </DialogActions>

            )}

        </Dialog>
    );

}

export const DisplayStripeElement = (props) => {
    const { t } = useTranslation();
    let {stripeData} = props;

    if(!stripeData) return "";

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
            scroll={"paper"}
            fullScreen={isResponsive()}
        >
            <DialogTitle id="scroll-dialog-title">{t('mrkt_announce_btn_announce_payment_page')}</DialogTitle>

            <DialogContent dividers={false}>

                <StripeInitializer stripeData={stripeData} cancelOrder={props.onClose} type={"announce"} onClose={props.onClose} />

            </DialogContent>

        </Dialog>
    );

}

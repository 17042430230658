import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import BreadCrumbs from "../../components/BreadCrumbs";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import "./page.css"
import PageBody from "./Page.Body";
import PageMedia from "./Page.Media";
import {handleConnector} from "../Category/Category.functions";
import {requestGetConnectorsData} from "../../actions";


class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        window.scrollTo(0,0);

        if(this.props.dataPage.connector_id){
            if(this.props.connectors.success){
                handleConnector(this.props.connectors.result, this.props.dataPage, this.props.variableStorage).then(isLoading => {
                    this.setState({loading: isLoading});
                })
            }
        }
    }

    /*async componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.props.dataPage.connector_id){
            if(this.props.connectors.success){

                if(Object.keys(prevProps.variableStorage).length === 0){
                    if(Object.keys(this.props.variableStorage).length > 0){
                        this.setState({loading_update: true});
                        handleConnector(this.props.connectors.result, this.props.dataPage, this.props.variableStorage).then(isLoading => {
                            this.setState({loading_update: isLoading});
                        })
                    }
                } else {
                    if(Object.keys(this.props.variableStorage).length > 0){
                        Object.entries(prevProps.variableStorage).forEach(([key, data]) => {
                            if(this.props.variableStorage[key]){
                                if (this.props.variableStorage[key] !== data){
                                    this.setState({loading_update: true});
                                    handleConnector(this.props.connectors.result, this.props.dataPage, this.props.variableStorage).then(isLoading => {
                                        this.setState({loading_update: isLoading});
                                    })
                                }
                            }
                        })
                    }
                }
            }
        }
    }*/

    render() {

        const {dataPage} = this.props;
        return (
            <React.Fragment>

                <BreadCrumbs id={dataPage.id} />

                <div className="page-wrapper">

                    {((dataPage.youtube_url && dataPage.youtube_url.length > 0) || (dataPage.media_url && dataPage.media_url.length > 0) || (dataPage.type === "event" && dataPage.medias[0])) && (
                        <div id="header-page-wrapper">
                            <PageMedia dataPage={dataPage} />
                        </div>
                    )}

                    <div id="body-page">

                        <PageBody dataPage={dataPage} />

                        {this.props.children}

                    </div>

                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    connectors: state.connectors,
    styles: state.style
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetConnectorsData
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Page);

import {SET_ROUTES_INFO, RESET_ROUTE_DATA} from "../actions/index";
import {urlrewriting} from "../helpers/Tools";

const initState = {
    RoutePathArray: {},
    PathIdentification: "",
    PathToCart: "",
}

const routes = (state= initState, action) => {
    switch (action.type) {
        case SET_ROUTES_INFO:
            return action.payload
        case RESET_ROUTE_DATA:
            return initState;
        default:
            return state
    }
}

export default routes;

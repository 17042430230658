import React from "react";
import {SectionCarousel} from "./SectionCarousel";
import {clone, getPageById} from "../../../helpers/Tools";

export class Carousel extends React.Component {
    render() {
        if(this.props.data.content){
            return (
                <div
                    style={{
                        position: "absolute",
                        top: this.props.y,
                        left: this.props.x,
                        width: this.props.width,
                        height: this.props.height,
                        backgroundColor: this.props.data.color
                    }}
                >
                    <SectionCarousel
                        data={this.props.data}
                        width={this.props.width}
                        height={this.props.height}
                        type="Carousel"
                        parentId={this.props.parentId}
                    />
                </div>
            );
        }else{
            let array =[];
            let dataInCarousel = clone(this.props.allData);
            let data = getPageById(dataInCarousel,this.props.data.alias);

            let link;
            let image;
            if(data){
                data.nodes.forEach(res => {

                        link = res.id.toString();
                        if(this.props.data['display-type']){

                            if(this.props.data['display-type'] === "banner"){
                                image = res.banner_url;
                            }
                            else if( this.props.data['display-type'] === "thumbnail"){
                                image = res.preview_url;
                            }else if(this.props.data['display-type'] === "icon"){
                                image = res.preview_url;
                            }else if(this.props.data['display-type'] === "content"){
                                image = res.media_url;
                            }
                        }else {
                            image = res.banner_url;
                        }
                        let text = res.title;
                        let corner_radius=this.props.data.corner_radius;
                        let image_aspect=this.props.data.image_aspect;
                        let opacity=this.props.data.opacity;
                        let rotation=this.props.data.rotation;
                        let text_align=this.props.data.text_align;
                        let text_color=this.props.data.text_color;
                        let text_font=this.props.data.text_font;
                        let text_size=this.props.data.text_size;
                        array.push({link,image,text,corner_radius,image_aspect,opacity,rotation,text_align,text_color,text_font,text_size})
                    }
                )
            }



            this.props.data['content'] = array;
            return (
                <div
                    style={{
                        position: "absolute",
                        top: this.props.y,
                        left: this.props.x,
                        width: this.props.width,
                        height: this.props.height,
                        backgroundColor: this.props.data.color
                    }}
                >

                    <SectionCarousel
                        data={this.props.data}
                        width={this.props.width}
                        height={this.props.height}
                        type="Previsualisation"
                        parentId={this.props.parentId}
                    />
                </div>
            )
        }

    }
}

import React from "react";
import { Container } from "@material-ui/core";
import { Col, Row, ListGroup } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { getDistance, convertDistance } from 'geolib';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {arrayFilter} from "../../../helpers/Tools";
import {CategoryList} from "../List/Category.List";

class CategoryDistance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pointMap: [],
            targetDestination:{},
            initialCenter: {},
            width: window.innerWidth,
            height: window.innerHeight,
            isCustomerEmplacement:false,
            customerEmplacement: null,
            first: true,
            list: arrayFilter(this.props.nodes, 'is_visible', '1')
        };

        this.onMarkerClick = this.onMarkerClick.bind(this);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

   componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);

        this.setState({
            first:true,
        })
    }

    async componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);

        await this.setState({
            targetDestination:{lat : "0", lng : "0"},
            initialCenter: {lat : "0", lng : "0"},
            first: true
        })
    }

    async componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if(this.state.first || (prevState.isCustomerEmplacement !== this.state.isCustomerEmplacement)){
            let pointMap = [];

            if (navigator.geolocation) {
                await navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        await this.setState({
                            customerEmplacement : {
                                lat : position.coords.latitude,
                                lng : position.coords.longitude
                            },
                            isCustomerEmplacement: true
                        })
                    },
                    function(error) {
                        console.error("Get Geolocalisation Not available! Error Code = " + error.code + " - " + error.message);
                    })
            } else {
                console.warn("Geolocalisation Not available!");
            }

            arrayFilter(this.props.nodes, 'is_visible', '1').forEach((item, key) => {
                let distance = null;

                if(this.state.isCustomerEmplacement && item.address_lat && item.address_lng){
                    distance = getDistance(
                        {latitude : this.state.customerEmplacement.lat, longitude : this.state.customerEmplacement.lng},
                        {latitude : item.address_lat, longitude : item.address_lng}
                    )
                }

                pointMap[key] = {
                    ...item,
                    position: {lat : item.address_lat, lng : item.address_lng},
                    distanceToCustomer: this.state.isCustomerEmplacement && distance ? Math.round(convertDistance(distance, 'km')) : ""
                }
            })

            let place = pointMap.find(place => place.accessibility === "public")
            this.state.initialCenter = place ? place.position : {lat : "0", lng : "0"};

            await this.setState({
                pointMap: pointMap,
                initialCenter: this.state.initialCenter,
                targetDestination: this.state.initialCenter,
                first: false,
            })
        }
    }

    onMarkerClick(destination_id) {
        const destination = arrayFilter(this.props.nodes, 'is_visible', '1').find(element => element.id === destination_id);

        if(this.state.width <= 800)
            window.scrollTo(0,0);

        if(destination.address_lat && destination.address_lng){
            this.setState({targetDestination: {
                    lat: destination.address_lat,
                    lng: destination.address_lng
                }});
        }
    }

    render() {
        const dyn_string = this.props.t

        if(arrayFilter(this.props.nodes, 'is_visible', '1').length === 0){
            return <div><h4 id="list-map-no-result">{dyn_string('g_label_no_result')}</h4></div>
        } else {
            let pointMap = [];
            this.props.nodes.forEach((content) => {
                this.state.pointMap.map((item, key) => {
                    if(item.id === content.id){
                        pointMap[key] = item;
                    }
                })
            });

            return (
                <CategoryList dataPage={this.props.dataPage} nodes={pointMap} />
            );
        }
    }
}

const mapStateToProps = (state) => ({
    getApp: state.application.getApp,
    RoutePathArray: state.routes.RoutePathArray,
    style: state.style
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(CategoryDistance);

import { call, put, takeLatest } from "redux-saga/effects";

import {
    REQUEST_GETCONTENT_DATA,
    receiveGetContentData,
    REQUEST_GETAUTHENTICATION_DATA,
    receiveGetAuthenticationData,
    REQUEST_GETAUTHENTICATIONSSO_DATA,
    receiveGetAuthenticationSSOData,
    REQUEST_GETPLANNINGS_DATA,
    receiveGetPlanningsData,
    REQUEST_GET_ANNOUNCES_DATA,
    receiveGetAnnouncesData,
    REQUEST_GET_ANNOUNCES_PROPOSITIONS,
    receiveGetAnnouncesPropositions,
    REQUEST_GETCONNECTORS_DATA,
    receiveGetConnectorsData,
    REQUEST_GETMARKET_DATA,
    receiveGetMarketData,
} from "../actions/index";

import {
    fetchGetContent,
    fetchGetAuthentication,
    fetchGetAuthenticationSSO,
    fetchGetPlannings,
    fetchGetAnnounces,
    fetchGetConnectors,
    fetchSendContentStats,
    fetchGetMarket, getAnnouncePropositions,
} from "../api/api";



//Sagas for GetContent
function* getContent(action) {
    try {
        // do api call
        const data = yield call(fetchGetContent);
        yield put(receiveGetContentData(data));
    } catch (e) {
        console.log(e);
    }
}

function* sendContentStats() {
    try {
        // do api call
        const data = yield call(fetchSendContentStats);
    } catch (e) {
        console.log(e);
    }
}


export function* accountSagaContent() {
    yield takeLatest(REQUEST_GETCONTENT_DATA, getContent);
}

//Sagas for GetAuthentification
function* getAuthentification(props) {
    try {
        // do api call
        const data = yield call(fetchGetAuthentication, props.data);
        yield put(receiveGetAuthenticationData(data));
    } catch (e) {
        console.log(e);
    }
}
export function* accountSagaAuth() {
    yield takeLatest(REQUEST_GETAUTHENTICATION_DATA, getAuthentification);
}

//Sagas for GetPlannings
function* getPlannings(action) {
    try {
        // do api call
        const data = yield call(fetchGetPlannings);
        yield put(receiveGetPlanningsData(data));
    } catch (e) {
        console.log(e);
    }
}
export function* accountSagaPlannings() {
    yield takeLatest(REQUEST_GETPLANNINGS_DATA, getPlannings);
}

//Sagas for GetAnnounces
function* getAnnounces(props) {
    try {
        // do api call
        const data = yield call(fetchGetAnnounces, props.data);
        yield put(receiveGetAnnouncesData(data));
    } catch (e) {
        console.log(e);
    }
}
export function* accountSagaAnnouces() {
    yield takeLatest(REQUEST_GET_ANNOUNCES_DATA, getAnnounces);
}

//Sagas for GetAnnouncesPropositions
function* getAnnouncesPropositions() {
    try {
        // do api call
        const data = yield call(getAnnouncePropositions);
        yield put(receiveGetAnnouncesPropositions(data));
    } catch (e) {
        console.log(e);
    }
}
export function* accountSagaAnnouncesPropositions() {
    yield takeLatest(REQUEST_GET_ANNOUNCES_PROPOSITIONS, getAnnouncesPropositions);
}

//Sagas for GetConnectors
function* getConnectors(action) {
    try {
        // do api call
        const data = yield call(fetchGetConnectors);
        yield put(receiveGetConnectorsData(data));
    } catch (e) {
        console.log(e);
    }
}
export function* accountSagaConnectors() {
    yield takeLatest(REQUEST_GETCONNECTORS_DATA, getConnectors);
}

//Sagas for GetMarket
function* getMarket(action) {
    try {
        // do api call
        const data = yield call(fetchGetMarket);
        yield put(receiveGetMarketData(data));
    } catch (e) {
        console.log(e);
    }
}
export function* accountSagaMarket() {
    yield takeLatest(REQUEST_GETMARKET_DATA, getMarket);
}

//Sagas for GetAuthentificationSSO
function* getAuthentificationSSO(action) {
    try {
        // do api call
        const data = yield call(fetchGetAuthenticationSSO);
        yield put(receiveGetAuthenticationSSOData(data));
    } catch (e) {
        console.log(e);
    }
}
export function* accountSagaSSO() {
    yield takeLatest(REQUEST_GETAUTHENTICATIONSSO_DATA, getAuthentificationSSO);
}



import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, List, ListItem, ListItemText} from "@material-ui/core";
import {Link} from "react-router-dom";
import {joinEvent, leaveEvent} from "../../../api/api";
import {requestGetPlanningsData} from "../../../actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {ShapperButton} from "../../../components/ShapperButton/ShapperButton";
import Page from "../../Page/Page";

class Event extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_guest: false,
            show_confirm_leave: false,
            show_confirm_join: false,
            show_error_join_log: false,
            show_error_join: false,
            error_message: null,
        }

        this.joinEvent = this.joinEvent.bind(this);
        this.leaveEvent = this.leaveEvent.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);
    }

    async joinEvent(){
        if(this.props.profil.mobinaute && this.props.profil.mobinaute.id){
            let objectToJoin = {
                eventId: this.props.dataPage.id,
                mobinauteId: this.props.profil.mobinaute.id,
                appId: this.props.application.getApp.id
            }

            await joinEvent(objectToJoin).then(res => {
                if (res.data.success){
                    this.props.requestGetPlanningsData();
                    this.setState({show_confirm_join: true})
                } else{
                    this.setState({
                        show_error_join: true,
                        error_message: res.data.error,
                    })
                }
            });
        } else {
            this.setState({show_error_join_log: true})
        }
    }

    async leaveEvent(){
        let objectToLeave = {
            eventId: this.props.dataPage.id,
            mobinauteId: this.props.profil.mobinaute.id,
            appId: this.props.application.getApp.id
        }

        await leaveEvent(objectToLeave).then(res => {
            if (res.data.success){
                this.props.requestGetPlanningsData();
                this.setState({show_confirm_leave: true})
            } else{
                alert(res.data.error)
            }
        });
    }

    render() {

        const dyn_string = this.props.t;

        // DIALOG ---------------------------------------------------------------------------------------------

        let display_guest = (
            <Dialog
                open={this.state.show_guest}
                onClose={() => this.setState({show_guest: false})}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>{dyn_string('planning_label_participants')}</DialogTitle>
                <DialogContent>
                    {this.props.dataPage.participants.length === 0 ? (
                        <p>{dyn_string('planning_alert_msg_info_no_participants')}</p>
                    ) : (
                        this.props.dataPage.participants.map((res, key) => {
                            return (
                                <div key={key}>
                                    <List>
                                        <ListItem>
                                            <ListItemText
                                                primary={res.firstname+" "+res.lastname}
                                                style={{ color:'black' }}
                                            />
                                        </ListItem>
                                    </List>
                                    <Divider variant="middle" />
                                </div>
                            );
                        })
                    )}
                </DialogContent>
                <DialogActions>
                    <ShapperButton onClick={() => this.setState({show_guest: false})}>
                        {dyn_string('g_btn_ok')}
                    </ShapperButton>
                </DialogActions>
            </Dialog>
        );

        let confirm_join = (
            <Dialog
                open={this.state.show_confirm_join}
                onClose={() => this.setState({show_confirm_join: false})}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>{this.props.dataPage.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dyn_string('planning_alert_title_succ_join_event')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ShapperButton onClick={() => this.setState({show_confirm_join: false})}>
                        {dyn_string('g_btn_ok')}
                    </ShapperButton>
                </DialogActions>
            </Dialog>
        );

        let error_join_log = (
            <Dialog
                open={this.state.show_error_join_log}
                onClose={() => this.setState({show_error_join_log: false})}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>{dyn_string('profile_alert_title_info_warning')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dyn_string('planning_alert_msg_info_join_event_not_auth')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ShapperButton onClick={() => this.setState({show_error_join_log: false})}>
                        {dyn_string('g_btn_ok')}
                    </ShapperButton>

                    <Link to={{pathname: this.props.routes.PathIdentification, state: {id: this.props.dataPage.id}}}>
                        <ShapperButton>
                            {dyn_string('auth_btn_login')}
                        </ShapperButton>
                    </Link>
                </DialogActions>
            </Dialog>
        );

        let error_join = (
            <Dialog
                open={this.state.show_error_join}
                onClose={() => this.setState({show_error_join: false})}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>{dyn_string('planning_alert_title_err_join_event')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {this.state.error_message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ShapperButton onClick={() => this.setState({show_error_join: false})}>
                        {dyn_string('g_btn_ok')}
                    </ShapperButton>
                </DialogActions>
            </Dialog>
        );

        let confirm_leave = (
            <Dialog
                open={this.state.show_confirm_leave}
                onClose={() => this.setState({show_confirm_leave: false})}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>{this.props.dataPage.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dyn_string('planning_alert_title_succ_cancel_event')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ShapperButton onClick={() => this.setState({show_confirm_leave: false})}>
                        {dyn_string('g_btn_ok')}
                    </ShapperButton>
                </DialogActions>
            </Dialog>
        );

        // ---------------------------------------------------------------------------------------------------

        return (
            <React.Fragment>

                <Page dataPage={this.props.dataPage} displayGuest={() => this.setState({show_guest: true})}>

                    <div style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>

                        <EventAction {...this.props} joinEvent={() => this.joinEvent()} leaveEvent={() => this.leaveEvent()} />

                    </div>

                </Page>

                {display_guest}
                {confirm_join}
                {confirm_leave}
                {error_join_log}
                {error_join}
            </React.Fragment>
        )

    }
}

const EventAction = (props) => {

    const dyn_string = props.t

    if (props.dataPage.participants.length > 0){
        if(props.profil.mobinaute && props.dataPage.participants.find((element) => element.mobinaute_id === props.profil.mobinaute.id)){
            return (
                <ShapperButton onClick={props.leaveEvent}>
                    {dyn_string('planning_btn_leave_event')}
                </ShapperButton>
            );
        } else {
            return (
                <ShapperButton onClick={props.joinEvent}>
                    {dyn_string('planning_btn_join_event')}
                </ShapperButton>
            );
        }
    } else {
        return (
            <ShapperButton onClick={props.joinEvent}>
                {dyn_string('planning_btn_join_event')}
            </ShapperButton>
        );
    }

}

const mapStateToProps = (state) => ({
    plannings: state.plannings,
    styles: state.style,
    application: state.application,
    profil: state.profil,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetPlanningsData,
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Event);

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import Page from "../Page/Page";
import {ShapperButton} from "../../components/ShapperButton/ShapperButton";
import {ConfirmAnnounceExchangeForm, ErrorConfirmAnnounceExchangeNotConnected, ErrorForm, ConfirmAnnounceExchange} from "./Announce.popup";
import {sendAnnounceProposition} from "../../api/api.market";

class Announce extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_confirm_announce_exchange_form:false,
            show_confirm_announce_exchange:false,
            show_error_no_connected:false,
            show_error_confirm:false,
        };

        this.handleShowConfirmation = this.handleShowConfirmation.bind(this);
        this.sendResponseAnnounceProposition = this.sendResponseAnnounceProposition.bind(this);
    }

    handleShowConfirmation(){
        if(this.props.profil.mobinaute && this.props.profil.mobinaute.id){
            this.setState({
                show_confirm_announce_exchange_form: true
            })
        } else {
            this.setState({
                show_error_no_connected: true
            })
        }
    }

    async sendResponseAnnounceProposition(comment){

        let data = {
            mobinauteId : this.props.profil.mobinaute.id,
            announceId : this.props.dataPage.announce_id,
            message : comment,
            amount : this.props.dataPage.price_ttc
        }

        await sendAnnounceProposition  (data).then(async (res) => {

            if (res.success) {
                if(res.result){
                    await this.setState({
                        show_confirm_announce_exchange_form: false,
                        show_confirm_announce_exchange: true,
                    });
                } else {
                    await this.setState({show_error_confirm: true, show_confirm_announce_exchange_form:false})
                    console.log(res.error)
                }
            } else {
                await this.setState({show_error_confirm: true, show_confirm_announce_exchange_form:false})
                console.log(res.error)
            }
        }).catch(async (e) => {
            await this.setState({show_error_confirm: true, show_confirm_announce_exchange_form:false})
            console.log(e)
        });
    }

    render() {

        const {dataPage} = this.props;

        return (
            <React.Fragment>

                <Page dataPage={dataPage} >

                    <div style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>

                        {
                            dataPage.author
                            && dataPage.author.id
                            && this.props.profil.mobinaute
                            && this.props.profil.mobinaute.id
                            && dataPage.author.id !== this.props.profil.mobinaute.id
                                ? (
                                    <ShapperButton onClick={() => this.handleShowConfirmation()}>
                                        {this.props.t('mrkt_announce_btn_apply')}
                                    </ShapperButton>
                                ) : ""
                        }

                    </div>

                </Page>

                <ConfirmAnnounceExchangeForm
                    isOpen={this.state.show_confirm_announce_exchange_form}
                    onClose={() => this.setState({show_confirm_announce_exchange_form: false})}
                    announce={dataPage}
                    sendResponseAnnounceProposition={this.sendResponseAnnounceProposition}
                />

                <ErrorConfirmAnnounceExchangeNotConnected
                    isOpen={this.state.show_error_no_connected}
                    onClose={() => this.setState({show_error_no_connected: false})}
                    dataPage={dataPage}
                />

                <ErrorForm
                    isOpen={this.state.show_error_confirm}
                    onClose={() => this.setState({show_error_confirm: false})}
                />

                <ConfirmAnnounceExchange
                    isOpen={this.state.show_confirm_announce_exchange}
                    onClose={() => this.setState({show_confirm_announce_exchange: false})}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    styles: state.style,
    profil: state.profil
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Announce);

import React from "react";
import {
    Button,
} from "@material-ui/core";
import {useSelector} from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete';
import "../mobinaute.wallet.css"
import {useTranslation} from "react-i18next";
import MediaQuery from 'react-responsive'

export const MobinauteWalletCardElementDelete = (props) => {
    const style = useSelector(state => state.style);
    const { t } = useTranslation();

    return (
        <div id="card-delete">
            <MediaQuery minDeviceWidth={800}>
                <Button
                    style={{backgroundColor: style.button.backgroundColor, borderColor: style.button.backgroundColor, color: style.button.text_color}}
                    fullWidth
                    onClick={() => {props.toggleConfirmDelete(true)}}
                >
                    <DeleteIcon style={{marginRight: "5%", fontSize: "25px" ,color: style.button.text_color}} /> {t('market_label_remove_card')}
                </Button>
            </MediaQuery>

            <MediaQuery maxDeviceWidth={800}>
                <DeleteIcon style={{fontSize: "25px" ,color: style.button.backgroundColor}} onClick={() => {props.toggleConfirmDelete(true)}}/>
            </MediaQuery>
        </div>
    )
}

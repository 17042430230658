
import {store} from "../store/store";
import axios from "axios";
import * as Constants from "./api.constants";
import {
    errorInitApp,
    resetAuthenticationData,
    resetAuthenticationSsoData, resetContentData,
    resetMobinauteData,
    resetRouteData
} from "../actions";
import {logout} from "../containers/NavBar/NavBar.functions"
import {getQueryVariable, pushHistory, urlrewriting} from "../helpers/Tools";
import localForage from "localforage";
import Cookies from "universal-cookie";
import {baseApiUrl} from "./api";

const cookies = new Cookies();

//getContent
export const fetchGetContent = async () => {
    try {
        let bodyFormData = new FormData();
        let state = store.getState().application;

        bodyFormData.set("deviceId", state.deviceId);
        bodyFormData.set("mobinauteId", "");
        bodyFormData.set("appId", state.getApp.id);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_CONTENT,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                store.dispatch(resetAuthenticationSsoData())
                store.dispatch(resetAuthenticationData())
                store.dispatch(resetRouteData())
                store.dispatch(resetMobinauteData())

                response.data.loading = false;

                if(response.data.success){

                    let data_str = JSON.stringify(response.data);
                    data_str = data_str.replace("[%DEVICE_TOKEN]", store.getState().application.deviceToken.access_token);
                    response.data = JSON.parse(data_str);

                    return response.data;
                } else {
                    console.log(response.data)

                    store.dispatch(errorInitApp({info:"fetchGetContent", data: response}))

                    return null;
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response);

                store.dispatch(errorInitApp({info:"fetchGetContent", data: response}))
            });
    } catch (e) {
        console.log(e);

        store.dispatch(errorInitApp({info:"fetchGetContent", data: e}))
    }
};

//GetAuthentication
export const fetchGetAuthentication = async (props) => {
    try {
        let state = store.getState().application;

        let appId = state.getApp.id;
        let deviceId = state.deviceId;
        let remember_me = state.remember_me;

        let login = props.login;
        let password = props.password;
        let eulaHaveBeenAccepted = props.eulaHaveBeenAccepted;

        let inApp = props.inApp;
        let pageId = props.pageId;

        return await authentification(appId, deviceId, login, password, eulaHaveBeenAccepted, remember_me, inApp, pageId);


    } catch (e) {
        console.log(e);

        store.dispatch(errorInitApp({info:"fetchGetAuthentication", data: e}))
    }
};

export async function authentification(appId, deviceId, login, password, eulaHaveBeenAccepted, remember_me, inApp, pageId) {

    let RoutePathArray = store.getState().routes.RoutePathArray;

    let bodyFormData = new FormData();
    bodyFormData.set("appId", appId);
    bodyFormData.set("deviceId", deviceId);
    bodyFormData.set("login", login);
    bodyFormData.set("password", password);

    if(eulaHaveBeenAccepted) {
        bodyFormData.set("eulaHaveBeenAccepted", "1");
    }

    console.log("ShapperWebRect - Try to login mobinaute :");

    return await axios({
        method: "post",
        url: baseApiUrl + Constants.WS_AUTH,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
    })
        .then(function (response) {
            if(response.data.error_code === 1077){
                logout();
            }
            else if(response.data.success || response.data.error_code === 1078){

                if (remember_me){
                    const date = new Date(Date.now());
                    date.setFullYear(date.getFullYear() + 1);
                    cookies.set("login", login, { path: "/", expires: date});
                    cookies.set("password", password, {path: "/", expires: date});
                } else {
                    cookies.set("login", login, { path: "/" });
                    cookies.set("password", password, {path: "/",});
                }

                if(response.data.error_code === 1078){
                    response.data.success = true

                    RoutePathArray['update_password'] = {
                        url: urlrewriting("update_password"),
                        title: "update_password",
                        parentId: null,
                        type: "update_password",
                        accessibility: "public"
                    }

                    if(RoutePathArray["update_password"]){
                        pushHistory(RoutePathArray["update_password"].url)
                    }
                }
                else if(inApp && pageId && RoutePathArray[pageId]){
                    pushHistory(RoutePathArray[pageId].url)
                }

                store.dispatch(resetContentData())
                store.dispatch(resetAuthenticationSsoData())
                store.dispatch(resetRouteData())
            }

            response.data.loading = false

            return response.data;
        })
        .catch(function (response) {
            //handle error
            console.log(response);

            store.dispatch(errorInitApp({info:"fetchGetAuthentication", data: response}))
        });

}

//GetAuthenticationSSO
export const fetchGetAuthenticationSSO = async () => {
    try {
        let state = store.getState().application;
        let bodyFormData = new FormData();

        bodyFormData.set("deviceId", state.deviceId);
        bodyFormData.set("idConnectorSSO", state.id_sso ?? 0);
        bodyFormData.set("appId", state.getApp.id);

        let code = getQueryVariable('code');
        let access_token = getQueryVariable('access_token');

        if(code) bodyFormData.set("code", code);
        if(access_token) bodyFormData.set("access_token", access_token);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_AUTH_WITH_ACCESS_TOKEN,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {

                const date = new Date(Date.now());
                date.setFullYear(date.getFullYear() + 1);

                if(!response.data.success){

                    cookies.remove("code", { path: "/"});
                    cookies.remove("access_token", {path: "/"});

                    localForage.clear().then(() => {
                        window.localStorage.clear();

                    }).catch((e) => {
                        console.log(e)
                        store.dispatch(errorInitApp({info:"localForage", data: e}))
                    })

                    store.dispatch(errorInitApp({info:"localForage", data: {}}))

                } else {

                    cookies.set("code", code, { path: "/", expires: date});
                    cookies.set("access_token", access_token, {path: "/", expires: date});
                }

                store.dispatch(resetContentData())
                store.dispatch(resetAuthenticationData())
                store.dispatch(resetRouteData())

                pushHistory("/")
                response.data.loading = false

                return response.data;

            })
            .catch(function (response) {
                console.log("catch1",response);
                store.dispatch(errorInitApp({info:"fetchGetAuthenticationSSO", data: response}))

                store.dispatch(errorInitApp("fetchGetAuthenticationSSO"))
            });
    } catch (e) {
        console.log("catch2",e);
        store.dispatch(errorInitApp({info:"fetchGetAuthenticationSSO", data: e}))
    }
};


import {store} from "../store/store";
import axios from "axios";
import * as Constants from "./api.constants";
import {errorInitApp, RESET_APP_ERROR} from "../actions";
import {baseApiUrl, baseApiUrlAnalytics} from "./api";

const parserXML = require('fast-xml-parser');
const he = require('he');

//GetConnectors
export const fetchGetConnectors = async () => {
    try {
        let bodyFormData = new FormData();

        bodyFormData.set("appId", store.getState().application.getApp.id);

        if(store.getState().profil.mobinaute.id)
            bodyFormData.set("mobinauteId", parseInt(store.getState().profil.mobinaute.id, 10));


        return await axios({
            method: "post",
            url:
                baseApiUrl +
                Constants.WS_GET_CONNECTORS,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                if(!response.data.success){
                    store.dispatch(errorInitApp({info:"fetchGetConnectors", data: response}))
                }
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);

                store.dispatch(errorInitApp({info:"fetchGetConnectors", data: response}))
            });
    } catch (e) {
        console.log(e);

        store.dispatch(errorInitApp({info:"fetchGetConnectors", data: e}))
    }
};

//GetContentConnector
export const fetchGetContentConnector = async (props) => {

    try {

        let config = {};

        if(props.auth_type){
            switch (props.auth_type) {
                case "bearer":
                    let token = "";
                    if(props.auth_login){
                        switch (props.auth_login){
                            case "zen_token":
                                token = store.getState().profil.mobinaute.accounts.zen_token;
                                break;
                            case "shapper_token":
                                if(typeof store.getState().profil.mobinaute.accounts != 'undefined' && typeof store.getState().profil.mobinaute.accounts.shapper_token != 'undefined'){
                                    token = store.getState().profil.mobinaute.accounts.shapper_token.access_token;
                                }
                                else if(typeof store.getState().application.deviceToken != 'undefined'){
                                    token = store.getState().application.deviceToken.access_token;
                                }

                                break
                        }
                    }

                    config = { Authorization: `Bearer `+token };
                    break;
                case "none":
                default :

            }

        }

        let regExParam = /(\?|&)(?<var>[^=]+)=(?<value>.[^&]*)/gim;
        let all_matches = props.url.match(regExParam);
        //let all_matches_params = props.params.match(regExParam);

        var dataAxios;

        console.log(all_matches);
        console.log(props);

        switch (props.request_method_body){
            case 'raw' :
                console.log("raw");
                config = { ...config, 'Content-Type': 'application/json' };

                dataAxios= props.params;

                /*if(all_matches){
                    for (let all_match of all_matches){
                        let matches = all_match.matchAll(regExParam)
                        for(let match of matches){
                            dataAxios[match.groups.var] = match.groups.value;
                        }
                    }
                }*/
                break;
            case 'x-www-form-urlencoded' :
                console.log("x-www-form-urlencoded");
                config = { ...config, 'Content-Type': 'application/x-www-form-urlencoded' };
                break;
            case 'form-data' :
            case 'none' :
            default :
                console.log("default");
                config = { ...config, 'Content-Type': 'multipart/form-data' };

                dataAxios = new FormData();

                if(all_matches){
                    for (let all_match of all_matches){
                        let matches = all_match.matchAll(regExParam)
                        for(let match of matches){
                            dataAxios.set(match.groups.var, match.groups.value);
                        }
                    }
                }

                break;
        }

        console.log("dataAxios");
        console.log(dataAxios);

        return await axios({
            method: props.request_method,
            request_method_body: props.request_method_body,
            url: props.url,
            data: dataAxios,
            headers: config
        })
            .then(function (response) {

                let final_response = getConnectorData(props.type, props.mapper_tree, response);

                return {
                    success: true,
                    data: final_response,
                    type: props.type
                };
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                return {
                    success: false
                }
            });
    } catch (e) {
        console.log(e);

        return {
            success: false
        }
    }
};

function getConnectorData(type, mapper_tree, response){

    let final_response = [];

    if(type === "rss"){
        let options = {
            attrValueProcessor: (val) => he.decode(val, {isAttributeValue: true}),
            tagValueProcessor : (val) => he.decode(val),
        };
        let jsonResponse = parserXML.parse(response.data, options);

        if(jsonResponse.rss){
            if(jsonResponse.rss.channel && jsonResponse.rss.channel.item){
                final_response = jsonResponse.rss.channel.item
            }
        } else if(jsonResponse.feed && jsonResponse.feed.entry){
            final_response = jsonResponse.feed.entry
        }

    } else {

        final_response = response.data;

        if(mapper_tree == null || typeof mapper_tree == 'undefined' || mapper_tree === "PATH://" || mapper_tree === ""){
            //final_response = results;
        }
        else {
            const mapperTreeArr = mapper_tree.split('//');
            mapperTreeArr.forEach((propertie, i) => {
                if(i!==0 && typeof propertie !== "undefined") {
                    if(final_response[propertie]) {
                        final_response = final_response[propertie];
                    }
                }
            });
        }
    }
    return final_response;
}

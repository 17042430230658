import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import React from "react";
import {requestGetAnnouncesPropositions, setLoader, unsetLoader} from "../../../actions";
import {sendAnnounceProposition} from "../../../api/api.market";
import {buildShapperPath, dateToLocaleString, prettyPrice} from "../../../helpers/Tools";
import {Avatar, Divider, List, ListItem, ListItemAvatar} from "@material-ui/core";
import {DisplayPropositionExchanges, DisplayPropositions, DisplayStripeElement} from "../Announce.popup";

export const DisplayAnnounces = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [displayPropositions, setDisplayPropositions] = React.useState(false);
    const [displayPropositionExchanges, setDisplayPropositionExchanges] = React.useState(false);
    const [displayStripeElement, setDisplayStripeElement] = React.useState(false);
    const [currentAnnounce, setCurrentAnnounce] = React.useState(null);
    const [currentProposition, setCurrentProposition] = React.useState(null);
    const [stripeData, setStripeData] = React.useState(null);

    const openAnnounce = (announce) => {
        if(announce){
            setDisplayPropositions(true)
            setCurrentAnnounce(announce)
        }
    }

    const openExchanges = (proposition) => {
        if(proposition){
            setDisplayPropositions(false);
            setDisplayPropositionExchanges(true);
            setCurrentProposition(proposition);
        }
    }

    const handleExchange = async (data) => {
        const {state} = data;
        dispatch(setLoader());

        switch (state){
            case "accepted":
            case "refused":

                sendAnnounceProposition({announceId: data.announceId, state: state, propositionId: data.propositionId})
                    .then(response => {

                        if(response.success){

                            dispatch(requestGetAnnouncesPropositions())

                            if(response.result && response.result.payment && response.result.payment.id === "stripe"){
                                let stripeData = response.result.payment.info;

                                setDisplayStripeElement(true);
                                setStripeData(stripeData);
                            }
                        }

                        setDisplayPropositionExchanges(false)
                        setDisplayPropositions(false);

                        dispatch(unsetLoader());
                    })
                    .catch(e => {
                        console.log(e)

                        setDisplayPropositionExchanges(false)
                        setDisplayPropositions(false);

                        dispatch(unsetLoader());
                    })

                break;
            default:
                setDisplayPropositionExchanges(false)
                setDisplayPropositions(false);

                dispatch(unsetLoader());
                break;
        }

    }


    if(props.announces.length === 0) {
        return (
            <h4 style={{marginTop: "70px", textAlign: "center"}}>{t('g_label_no_result')}</h4>
        )
    }

    return (
        <React.Fragment>

            {props.announces.map((announce, key) => {

                announce.created_at_string = dateToLocaleString(announce.created_at, t)

                return (
                    <React.Fragment key={key}>

                        <List onClick={() => openAnnounce(announce)}>

                            <ListItem button >

                                <ListItemAvatar>
                                    <Avatar src={buildShapperPath(announce.preview_url)} style={{maxWidth:'100%'}} variant="rounded" />
                                </ListItemAvatar>

                                <div className="list-body-wrapper">

                                    <div className="list-body">
                                        <span className="title">{announce.title}</span>
                                        <span className="sub-info">{announce.created_at_string}</span>
                                    </div>

                                    <div className="list-announce-info">
                                        <span>{t('mrkt_announce_label_announce_number_propositions', {number:announce.propositions.length})}</span>

                                        {announce.price_ttc ? (
                                            <span>{prettyPrice(announce.price_ttc)} €</span>
                                        ) : <span >{t('mrkt_announce_label_announce_no_price')}</span>}
                                    </div>

                                </div>

                            </ListItem>

                        </List>

                        <Divider variant="middle" />

                    </React.Fragment>
                )
            })}

            <DisplayPropositions
                isOpen={displayPropositions}
                onClose={() => setDisplayPropositions(false)}
                announce={currentAnnounce}
                type={props.type}
                openExchanges={openExchanges}
            />

            <DisplayPropositionExchanges
                isOpen={displayPropositionExchanges}
                onClose={() => setDisplayPropositionExchanges(false)}
                proposition={currentProposition}
                type={props.type}
                handleExchange={handleExchange}
            />

            <DisplayStripeElement
                isOpen={displayStripeElement}
                onClose={() => setDisplayStripeElement(false)}
                stripeData={stripeData}
            />

        </React.Fragment>
    )
}

import {
    SET_LOADER,
    UNSET_LOADER,
} from "../actions/index";

const loader = (state = false, action) => {
    switch (action.type) {
        case SET_LOADER:
            return true;
        case UNSET_LOADER:
            return false;
        default:
            return state;
    }
};

export default loader

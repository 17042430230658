import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import {ShapperButton} from "../../components/ShapperButton/ShapperButton";

export const RedirectError = (props) => {
    const {t} = useTranslation();

    return (
        <Dialog
            open={props.isOpen}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>{t('auth_alert_title_info_protected_content')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('auth_btn_content_limited')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Link to="/">
                    <ShapperButton onClick={props.onClose} >
                        {t('g_btn_ok')}
                    </ShapperButton>
                </Link>
            </DialogActions>
        </Dialog>
    )
}

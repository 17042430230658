import {useSelector} from "react-redux";
import {Checkbox, FormControl, FormControlLabel, withStyles} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

export const StripeCardCheckBox = (props) => {
    const style = useSelector(state => state.style);
    const {t} = useTranslation();

    const CustomCheckbox = withStyles({
        root: {
            color: style.button.backgroundColor,
            "&$checked": {
                color: style.button.backgroundColor
            }
        },
        checked: {}
    })((props) => <Checkbox color="default" {...props} />);

    return (
        <FormControl component="fieldset">
            <FormControlLabel
                control={
                    <CustomCheckbox
                        checked={props.createCard}
                        onChange={props.allowCreateCard}
                    />
                }
                label={t('market_label_save_card')}
            />
        </FormControl>
    )
}

export const REQUEST_GETMARKET_DATA = "REQUEST_GETMARKET_DATA";
export const RECEIVE_GETMARKET_DATA = "RECEIVE_GETMARKET_DATA";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";

export const requestGetMarketData = () => ({
    type: REQUEST_GETMARKET_DATA
});

export const receiveGetMarketData = data => ({
    type: RECEIVE_GETMARKET_DATA,
    data
});

import React from "react";
import Home from "../containers/Home/Home";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    addProfilFormData,
    requestGetAuthenticationData,
    requestGetAuthenticationSSOData,
    requestGetContentData,
} from "../actions";
import Cookies from "universal-cookie";
import Container from "@material-ui/core/Container";
import IdentificationFormulaire from "../views/Mobinaute/MobinauteIdentification/Mobinaute.Identification.Formulaire";
import UpdatePassword from "../views/Mobinaute/MobinauteUpdatePassword/Mobinaute.UpdatePassword";
import {getQueryVariable} from "./Tools";
import {logout} from "../containers/NavBar/NavBar.functions"
import {ShapperLoader} from "../components/ShapperLoader/ShapperLoader";

const cookies = new Cookies();

class CheckCredentials extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            display_auth_start: parseInt(this.props.application.getApp.identification_start),
        };
    }

    async componentDidMount() {

        if(getQueryVariable('code') || getQueryVariable('access_token')){

            console.log("SSO")

            this.props.requestGetAuthenticationSSOData()

        }
        else if(this.props.application.getApp.connectorAuthSSOs && this.props.application.getApp.authSSO && (cookies.get("code") || cookies.get("access_token"))) {
            let dataSSO = this.props.application.getApp.connectorAuthSSOs[0];
            let url_redirect = dataSSO.urlEnv + dataSSO.endPointAuthorize + "?acr_values=" + dataSSO.acr_values + "&response_type=code&client_id=" + dataSSO.appId + "&state="+ dataSSO.state +"&nonce=" + Math.random(14) + "&redirect_uri=" + dataSSO.redirect + "&scope="+ dataSSO.scope;


            cookies.remove("code", { path: "/"});
            cookies.remove("access_token", {path: "/"});

            window.open(url_redirect,"_self")
        }
        else {

            if (cookies.get("login") && cookies.get("password")){

                this.props.requestGetAuthenticationData({login: cookies.get("login"), password: cookies.get("password"), inApp:false})

            } else {

                this.props.requestGetContentData();

                if(this.state.display_auth_start === 1){
                    this.setState({
                        display_auth_start: 0,
                    })
                }

            }

        }
    }

    render() {
        //console.log("%c CHECK CREDENTIALS",'background: #222; color: #bada55');

        if(this.props.dataAuth.loading || this.props.data.loading || this.props.dataSSO.loading ){
            return <ShapperLoader />;
        }

        if((this.props.dataAuth.error_code === 1200 ||this.props.dataAuth.error_code === 1072) && (cookies.get('login') || cookies.get('password'))){

            console.log("pass identifiants invalides")

            logout()

            return <ShapperLoader/>;
        }

        if(this.props.dataSSO.success || this.props.dataAuth.success){
            return  <Home />;
        } else {

            if (parseInt(this.props.application.getApp.identification_start) === 0) {
                return <Home />;
            }

            if(this.props.dataAuth.error_code === 1078){
                return <UpdatePassword />;
            }
            else if(this.state.display_auth_start === 0){
                let form = null
                if(this.props.data.result
                    && this.props.data.result.find((element) => element.profil_form)
                    && this.props.data.result.find((element) => element.profil_form).profil_form) {
                    form = this.props.data.result.find((element) => element.profil_form).profil_form.inputs_form
                }
                return (
                    <Container component="main" style={{ marginTop: "10%" }} maxWidth="xs">
                        <IdentificationFormulaire Form={form}/>
                    </Container>
                );
            } else {
                return <ShapperLoader/>;
            }
        }
    }
}

//REDUX
const mapStateToProps = (state) => ({
    dataAuth : state.contentauth,
    dataSSO : state.contentauthSso,
    data: state.content,
    application : state.application
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetContentData,
            requestGetAuthenticationData,
            requestGetAuthenticationSSOData,
            addProfilFormData
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CheckCredentials);

import React from "react";
import {Link} from "react-router-dom";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {pushHistory} from "../../helpers/Tools";


class ContentUndefined extends React.Component {

    componentDidMount() {

        console.log(this.props)
        //temporaire
        pushHistory('/');
    }


    render() {
        console.log("%c ////////ROUTE__NOT_FOUND////////",  'background: #222; color: #bada55')

        const dyn_string = this.props.t;

        if((this.props.dataAuth && this.props.dataAuth.success) || (this.props.dataAuthSSO && this.props.dataAuthSSO.success)){
            return (
                <div style={{textAlign:"center", height:this.props.height-250}}>
                    <h1 style={{color: '#888888'}}>{dyn_string('g_alert_title_info_page_not_found')}</h1>
                    <h1 style={{fontSize:"220px", fontWeight:"300", paddingBottom:"20px", marginBottom:"20px"}}>404</h1>
                    <p style={{textAlign:"center"}}>
                        <Link to="/">{dyn_string('g_alert_msg_info_page_not_found')}</Link>
                    </p>
                </div>
            );
        }else{
            return (
                <div style={{textAlign:"center", height:this.props.height-250}}>
                    <p style={{textAlign:"center"}}>
                        {/* Le contenu demandé est inaccessible, veuillez vous <Link to={{pathname: this.props.routes.PathIdentification}}>connecter</Link> pour tenter d'y accéder*/}
                        {dyn_string('auth_alert_msg_info_protected_content')}
                    </p>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ContentUndefined);

import React, { useEffect, useState } from "react";
import {detachPaymentMethod, getStripeInfoMobinaute} from "../../../../api/api.market";
import {ShapperLoader} from "../../../../components/ShapperLoader/ShapperLoader";
import {MobinauteWalletHeader} from "../MobinauteWalletHeader/Mobinaute.Wallet.Header";
import {MobinauteWalletBody} from "../MobinauteWalletBody/Mobinaute.Wallet.Body";
import {ErrorDeleteCard} from "../Mobinaute.Wallet.popup"
import {useSelector} from "react-redux";
import {isNotEmpty} from "../../../../helpers/Tools";

export const StripeWallet = (props) => {
    const mobinaute = useSelector(state => state.profil.mobinaute);
    const [cardList, setCardList] = useState([]);
    const [clientIntentSecret, setClientIntentSecret] = useState(null);
    const [publicKey, setPublicKey] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorDeleteCard, setErrorDeleteCard] = useState(false);

    useEffect(() => {
        if(isNotEmpty(mobinaute)){
            getStripeInfoMobinaute().then(data => {
                if(data.success){
                    setPublicKey(data.result.publicKey)
                    setCardList(data.result.cards.data)
                    setClientIntentSecret(data.result.clientIntentSecret)
                    setLoading(false)
                }
            }).catch(e => {
                console.log("erreur get info use effect", e)
            })
        }
    }, [mobinaute])

    async function deleteCard(pmId){
        setLoading(true)

        detachPaymentMethod({pmId: pmId})
            .then(async (data) => {
                if(data.success && !data.result.customer){
                    await hydrateInfoStripe()
                } else {
                    console.log(data)

                    setErrorDeleteCard(true);
                    setLoading(false)
                }
            })
            .catch(async (e) => {
                console.log("erreur detach", e)

                setErrorDeleteCard(true);
                setLoading(false)
            })
    }

    async function hydrateInfoStripe(){
        setLoading(true)

        getStripeInfoMobinaute().then(data => {
            if(data.success){
                setPublicKey(data.result.publicKey)
                setCardList(data.result.cards.data)
                setClientIntentSecret(data.result.clientIntentSecret)
                setLoading(false)
            }
        }).catch(e => {
            console.log("erreur get info", e)
        })
    }

    if(loading){
        return (
            <ShapperLoader width={props.width} height={props.height}/>
        )
    } else {
        return (
            <div style={{height: props.height}}>
                <MobinauteWalletHeader publicKey={publicKey} clientIntentSecret={clientIntentSecret} hydrateInfoMobinaute={hydrateInfoStripe} type="stripe"/>

                <MobinauteWalletBody cardList={cardList} deleteCard={deleteCard} type="stripe"/>

                <ErrorDeleteCard isOpen={errorDeleteCard} onClose={() => setErrorDeleteCard(true)} />
            </div>
        )
    }
};

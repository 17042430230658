export const REQUEST_GET_ANNOUNCES_DATA = "REQUEST_GET_ANNOUNCES_DATA";
export const RECEIVE_GET_ANNOUNCES_DATA = "RECEIVE_GET_ANNOUNCES_DATA";
export const REQUEST_GET_ANNOUNCES_PROPOSITIONS = "REQUEST_GET_ANNOUNCES_PROPOSITIONS";
export const RECEIVE_GET_ANNOUNCES_PROPOSITIONS = "RECEIVE_GET_ANNOUNCES_PROPOSITIONS";
export const RESET_ANNOUNCES_LIST = "RESET_ANNOUNCES_LIST";
export const RESET_ANNOUNCES_PROPOSITIONS = "RESET_ANNOUNCES_PROPOSITIONS";

export const requestGetAnnouncesData = data => ({ type: REQUEST_GET_ANNOUNCES_DATA, data });

export const receiveGetAnnouncesData = (data) => {
    return {
        type: RECEIVE_GET_ANNOUNCES_DATA,
        data
    }
};


export const requestGetAnnouncesPropositions = () => ({ type: REQUEST_GET_ANNOUNCES_PROPOSITIONS });
export const receiveGetAnnouncesPropositions = data => ({ type: RECEIVE_GET_ANNOUNCES_PROPOSITIONS, data });

export const resetAnnouncesList = () => ({ type: RESET_ANNOUNCES_LIST });
export const resetAnnouncesPropositions = () => ({ type: RESET_ANNOUNCES_PROPOSITIONS });

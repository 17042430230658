export const REQUEST_GETCONNECTORS_DATA = "REQUEST_GETCONNECTORS_DATA";
export const RECEIVE_GETCONNECTORS_DATA = "RECEIVE_GETCONNECTORS_DATA";

export const requestGetConnectorsData = () => ({
    type: REQUEST_GETCONNECTORS_DATA
});

export const receiveGetConnectorsData = (data) => {
    return {
        type: RECEIVE_GETCONNECTORS_DATA,
        payload: data
    }
};


export function constructText(props){
    let text = "";

    if (props.data.text) {
        text = props.data.text;
    } else if (props.text) {
        text = props.data.text;
    }

    return text;
}

export function constructObjectFitPosition(props){
    let objectFit;
    let objectPosition = "";

    switch (props.data.image_aspect) {
        case "fit":
            objectFit = "contain";
            break;
        case "fill":
            objectFit = "fill";
            break;
        case "center":
            objectFit = "cover";
            objectPosition = "center";
            break;
        case "left":
            objectFit = "cover";
            objectPosition = "left";
            break;
        case "right":
            objectFit = "cover";
            objectPosition = "right";
            break;
        case "top":
            objectFit = "cover";
            objectPosition = "top";
            break;
        case "bottom":
            objectFit = "cover";
            objectPosition = "bottom";
            break;
        case "bottom-left":
            objectFit = "cover";
            objectPosition = "bottom left";
            break;
        case "bottom-right":
            objectFit = "cover";
            objectPosition = "bottom right";
            break;
        case "top-left":
            objectFit = "cover";
            objectPosition = "top left";
            break;
        case "top-right":
            objectFit = "cover";
            objectPosition = "top right";
            break;
        default :
            objectFit = "cover";
            objectPosition = "center";
            break;

    }

    return {
        fit: objectFit,
        position: objectPosition
    }
}

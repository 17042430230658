import React from "react";
import "./mobinaute.wallet.css"
import {MobinauteWalletCardElement} from "./MobinauteWalletCard/Mobinaute.Wallet.Card.Element";

export const MobinauteWalletBody = (props) => {

    return (
        <div id="body-wallet">

            {props.cardList.map((cardData, key) =>{

                return (
                    <MobinauteWalletCardElement cardData={cardData} key={key} deleteCard={props.deleteCard} type={props.type}/>
                )

            })}

        </div>
    )
};

export const SET_LOADER = "SET_LOADER";
export const UNSET_LOADER = "UNSET_LOADER";

export const setLoader = () => {
    return {
        type: SET_LOADER,
    }
}

export const unsetLoader = () => {
    return {
        type: UNSET_LOADER
    }
}

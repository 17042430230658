import React from "react";
import Image from "react-bootstrap/Image";
import {withTranslation} from "react-i18next";
import {buildShapperPath} from "../../helpers/Tools";

// Composant permettant l'affichage d'élément de type Photo
class Photo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            url: buildShapperPath(this.props.dataPage.media_url)
        };
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);

    }

    render() {
        console.log(" %c ////////CONTENU-PHOTO////////",  'background: #222; color: #bada55');

        return (
            <div style={{textAlign:"center"}}>
                <Image src={this.state.url} fluid />
            </div>
        );
    }
}

export default withTranslation()(Photo);

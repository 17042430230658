import React from "react";
import {Link} from "react-router-dom";
import BreadCrumbs from "../../../components/BreadCrumbs";
import 'font-awesome/css/font-awesome.min.css';
import {Col, Form, Row} from "react-bootstrap";
import {Container, GridList, GridListTile, GridListTileBar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {isSame, prettyPrice, getVariationFromAttributs, clone, parserContent,} from "../../../helpers/Tools";
import {addArticle, deleteArticle, purgeCart} from "../../../actions";
import {connect} from "react-redux";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import image_not_found from "../../../assets/image_not_found.png"
import {bindActionCreators, compose} from "redux";
import {withTranslation} from "react-i18next";
import "./Product.css"
import {ShapperButton} from "../../../components/ShapperButton/ShapperButton";

let Attributs = {};

class Product extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.market.products.length > 0 && this.props.dataPage.product_id){
            if (typeof this.props.market.products.find((element) => element.id === this.props.dataPage.product_id) !== "undefined"){
                this.state = {
                    medias: this.props.market.products.find((element) => element.id === this.props.dataPage.product_id).medias,
                    price_ttc: this.props.market.products.find((element) => element.id === this.props.dataPage.product_id).price_ttc,
                    price_ht: this.props.market.products.find((element) => element.id === this.props.dataPage.product_id).price_ht,
                    tva: this.props.market.tvas.find((element) => element.id === this.props.market.products.find((element) => element.id === this.props.dataPage.product_id).mrkt_tva_id).title,
                    quantity: this.props.market.products.find((element) => element.id === this.props.dataPage.product_id).quantity_default,
                    isUpdateState: true,
                    width: window.innerWidth,
                    height: window.innerHeight,
                    isActive: true,
                    add_to_cart: false,
                    config_error: false,
                    attributs_error: false,
                    supplier_error: false,
                    supplier_error_confirmation: false,
                    no_supp_id: false
                };
            } else {
                this.state = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                    isActive: false,
                    add_to_cart: false,
                    config_error: false,
                    attributs_error: false,
                    supplier_error: false,
                    supplier_error_confirmation: false,
                    no_supp_id: false
                }
            }
        } else {
            this.state = {
                width: window.innerWidth,
                height: window.innerHeight,
                isActive: false,
                add_to_cart: false,
                config_error: false,
                attributs_error: false,
                supplier_error: false,
                supplier_error_confirmation: false,
                no_supp_id: false
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.AddToCart = this.AddToCart.bind(this);

        Attributs = {};
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);

        if (this.state.isActive){
            if (this.props.market.products.length > 0 && this.props.dataPage.product_id){
                let product = this.props.market.products.find((element) => element.id === this.props.dataPage.product_id);
                let default_variation = product.variations.find((element) => element.is_default === true);

                if (typeof default_variation !== 'undefined' && product.attributs.length > 0){
                    if (default_variation.attributValues.length > 0){
                        this.setState({
                            medias: default_variation.medias,
                            price_ttc: default_variation.price_ttc,
                            price_ht: default_variation.price_ht,
                            tva: this.props.market.tvas.find((element) => element.id === default_variation.mrkt_tva_id).title,
                        })

                        if (product.attributs.length === default_variation.attributValues.length){
                            for(let i = 0 ; i < product.attributs.length ; i++)
                            {
                                this.setState({
                                    [product.attributs[i]]: default_variation.attributValues[i],
                                })
                                Attributs[product.attributs[i]] = default_variation.attributValues[i];
                            }
                        }
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: SS) {
        if (this.state.isActive){
            if (this.props.market.products.length > 0 && this.props.dataPage.product_id){
                let product = this.props.market.products.find((element) => element.id === this.props.dataPage.product_id);
                let tvas = this.props.market.tvas;
                let count; //pour savoir si la variation existe

                //mise à jour des prix et des images en fonction des attributs sélectionnés
                if (product.attributs.length > 0 && product.variations.length > 0){
                    if (Object.values(Attributs).length === product.variations[0].attributValues.length){
                        count = 0;
                        for(let variation of product.variations) {
                            if (isSame(variation.attributValues, Object.values(Attributs))) {
                                if (this.state.isUpdateState){
                                    if(variation.medias.length > 0){
                                        this.setState({
                                            price_ht: variation.price_ht,
                                            price_ttc: variation.price_ttc,
                                            medias: variation.medias,
                                            tva: tvas.find((element) => element.id === variation.mrkt_tva_id).title,
                                            isUpdateState: false,
                                        })
                                    } else {
                                        this.setState({
                                            price_ht: variation.price_ht,
                                            price_ttc: variation.price_ttc,
                                            medias: product.medias,
                                            tva: tvas.find((element) => element.id === variation.mrkt_tva_id).title,
                                            isUpdateState: false,
                                        })
                                    }
                                }
                                break;
                            } else {
                                count++;
                            }
                        }

                        if (count === product.variations.length){
                            if (this.state.isUpdateState){
                                this.setState({
                                    price_ht: product.price_ht,
                                    price_ttc: product.price_ttc,
                                    medias: product.medias,
                                    tva: tvas.find((element) => element.id === product.mrkt_tva_id).title,
                                    isUpdateState: false,
                                })
                            }
                        }
                    }
                }
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            isUpdateState: true,
        })

        if (name !== 'quantity'){
            Attributs[name] = value;
        }
    }

    constructProductForCart(product, variation, url) {
        let productForCart = {};

        productForCart.variation = variation;
        productForCart.product = product;
        productForCart.quantity = this.state.quantity;
        productForCart.url = url;
        productForCart.id = variation.id;

        // Gestion des doublons (peux pas mettre la même variation deux fois -> changement de la quantité)
        if (this.props.products.length > 0) {
            let inner_product = this.props.products.find(element => element.id === productForCart.id);
            if (inner_product) {
                let inner_qty = parseFloat(inner_product.quantity);
                let add_qty = parseFloat(productForCart.quantity);
                let total_qty = inner_qty + add_qty;
                productForCart.quantity = total_qty.toString();
                this.props.deleteArticle(inner_product.variation.id);
            }
        }

        return productForCart;
    }

    AddToCart(isFor) {
        let product = this.props.market.products.find((element) => element.id === this.props.dataPage.product_id)
        let productForCart = {};
        let RoutePathArray = this.props.routes.RoutePathArray

        if (product.attributs.length === Object.values(Attributs).length){
            let variation = getVariationFromAttributs(product.variations, Object.values(Attributs));
            let url ;

            if (typeof RoutePathArray[product.id] === 'undefined'){
                url = RoutePathArray[this.props.dataPage.id].url
            } else {
                url = RoutePathArray[product.id].url
            }

            if (Object.values(variation).length > 0){
                productForCart = this.constructProductForCart(product, variation, url)

                if(isFor === 'confirmation'){
                    this.props.purgeCart();
                    this.props.addArticle(productForCart); //ajoute objet dans store redux pour que le component panier y ait accès
                    this.setState({add_to_cart: true, supplier_error_confirmation: false})
                } else {
                    if(this.props.application.getApp.has_marketplace === "1"){
                        if(!product.supplier_id){
                            this.setState({no_supp_id: true})
                        } else {
                            if(this.props.application.getApp.has_market_exclusive === "1"){
                                if(!this.props.currentSupplier || (product.supplier_id === this.props.currentSupplier)){
                                    this.props.addArticle(productForCart); //ajoute objet dans store redux pour que le component panier y ait accès
                                    this.setState({add_to_cart: true})
                                } else {
                                    this.setState({supplier_error: true})
                                }
                            } else {
                                this.props.addArticle(productForCart); //ajoute objet dans store redux pour que le component panier y ait accès
                                this.setState({add_to_cart: true})
                            }
                        }
                    } else {
                        this.props.addArticle(productForCart); //ajoute objet dans store redux pour que le component panier y ait accès
                        this.setState({add_to_cart: true})
                    }
                }
            } else {
                this.setState({config_error: true})
            }
        } else {
            this.setState({attributs_error: true})
        }
    }

    render() {
        console.log(" %c ////////CONTENU-PRODUIT////////", 'background: #222; color: #bada55');

        const dyn_string = this.props.t;

        if (this.state.isActive){
            //Récupération du produit
            let product = this.props.market.products.find((element) => element.id === this.props.dataPage.product_id)
            let market_attributs = clone(this.props.market.attributs);
            let attributsTab = [];
            let attributsTmp = [];
            let attributsDisplay;
            let mediasDisplay;
            let price_ttc = prettyPrice((this.state.price_ttc*this.state.quantity).toFixed(2))
            let price_ht = prettyPrice((this.state.price_ht*this.state.quantity).toFixed(2))
            let label_quantity = product.quantity_unit ? dyn_string('market_label_quantity')+" "+product.quantity_unit : dyn_string('market_label_quantity');
            let product_description = parserContent(product.description);
            let RoutePathArray = this.props.routes.RoutePathArray

            if (market_attributs){
                product.attributs.forEach((attribut) => {
                    attributsTab.push(market_attributs.find((element) => element.id === attribut)); //récupération des bons attributs en fonction du produit
                })

                if (attributsTab.length > 0){
                    //filtration des valeurs d'attributs non utilisées
                    attributsTab.forEach((attribut) => {
                        attributsTmp = [];
                        attribut.attributValues.forEach((attributValue) => {
                            for (let variation of product.variations){
                                if (variation.attributValues.find((element) => element === attributValue.id)){
                                    attributsTmp.push(attributValue)
                                    break;
                                }
                            }
                        })
                        attribut.attributValues = attributsTmp;
                    })

                    //contruction des listes des choix des attributs
                    attributsDisplay = (
                        <div style={{marginTop: "10px"}}>
                            {attributsTab.map((res, key) => {
                                if (Object.values(Attributs).length > 0){
                                    if (typeof Object.values(Attributs)[key] !== "undefined"){
                                        return (
                                            <Form.Group key={key} style={{textAlign:"left"}}>
                                                <Form.Label>{res.title}</Form.Label>
                                                <Form.Control
                                                    value={Object.values(Attributs)[key]}
                                                    onChange={this.handleChange}
                                                    name={res.id}
                                                    as="select"
                                                    custom
                                                >
                                                    {res.attributValues.map((value, key2) => {
                                                        return (
                                                            <option key={key2} value={value.id} >
                                                                {value.title}
                                                            </option>
                                                        );
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                        );
                                    } else {
                                        return (
                                            <Form.Group key={key} style={{textAlign:"left"}}>
                                                <Form.Label>{res.title}</Form.Label>
                                                <Form.Control
                                                    value={this.state.value}
                                                    onChange={this.handleChange}
                                                    name={res.title}
                                                    as="select"
                                                    custom
                                                    defaultValue={-1}
                                                >
                                                    <option disabled="disabled" value={-1}>{dyn_string('market_placeholder_choose_attribute')}</option>
                                                    {res.attributValues.map((value, key2) => {
                                                        return (
                                                            <option key={key2} value={value.id} >
                                                                {value.title}
                                                            </option>
                                                        );
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                        );
                                    }
                                } else {
                                    return (
                                        <Form.Group key={key} style={{textAlign:"left"}}>
                                            <Form.Label>{res.title}</Form.Label>
                                            <Form.Control
                                                value={this.state.value}
                                                onChange={this.handleChange}
                                                name={res.title}
                                                as="select"
                                                custom
                                                defaultValue={-1}
                                            >
                                                <option disabled="disabled" value={-1}>{dyn_string('market_placeholder_choose_attribute')}</option>
                                                {res.attributValues.map((value, key2) => {
                                                    return (
                                                        <option key={key2} value={value.id} >
                                                            {value.title}
                                                        </option>
                                                    );
                                                })}
                                            </Form.Control>
                                        </Form.Group>
                                    );
                                }
                            })}
                        </div>
                    );
                }
            }

            if (this.state.medias.length > 0) {
                let medias = this.state.medias;
                let images = [];
                let image = {};
                medias.forEach((media => {
                    image={};
                    image["original"] = media;
                    image["thumbnail"] = media;
                    images.push(image)
                }))
                let showThumbnail = medias.length > 1;
                mediasDisplay = (
                    <Col style={{marginTop: "24px"}}>
                        <ImageGallery
                            items={images}
                            infinite={true}
                            showFullscreenButton={true}
                            showPlayButton={false}
                            slideOnThumbnailOver={true}
                            showNav={false}
                            showBullets={false}
                            showThumbnails={showThumbnail}
                        />
                    </Col>
                );
            } else {
                mediasDisplay = "";
            }

            let linked_productsDisplay = "";

            if (product.linked_products.length > 0){
                let linked_products = [];

                //récupération des produits à suggérer
                product.linked_products.forEach((linked_product_id) => {
                    if (typeof this.props.market.products.find((element) => element.id === linked_product_id) !== 'undefined'){
                        linked_products.push(this.props.market.products.find((element) => element.id === linked_product_id))
                    }
                })

                if (linked_products.length > 0){
                    linked_productsDisplay = (
                        <div style={{margin: "40px 0", display:"flex", flexWrap:"wrap", justifyContent:"space-around", overflow:"hidden"}}>
                            <GridList cellHeight={180} style={{width:500, height:180, textAlign:"center"}}>
                                {linked_products.map((res, key) => {
                                    let url ;
                                    let img_url;
                                    if (typeof RoutePathArray[res.id] === 'undefined'){
                                        url = RoutePathArray[this.props.dataPage.id].url
                                    } else {
                                        url = RoutePathArray[res.id].url
                                    }

                                    if (res.medias.length > 0){
                                        img_url = res.medias[0];
                                    } else {
                                        img_url = image_not_found;
                                    }

                                    return (
                                        <GridListTile key={key}>
                                            <img src={img_url} alt={res.title} />
                                            <Link to={url}>
                                                <GridListTileBar
                                                    title={res.title}
                                                    subtitle={prettyPrice(parseFloat(res.price_ttc).toFixed(2))+" €"}
                                                />
                                            </Link>
                                        </GridListTile>
                                    );
                                })}
                            </GridList>
                        </div>
                    );
                }
            }

            // popup

            let go_to_cart = "";
            if(this.props.routes.PathToCart !== "" && this.props.routes.PathToCart !== undefined){
                go_to_cart = (
                    <Link to={this.props.routes.PathToCart}>
                        <ShapperButton >{dyn_string('market_title_cart')}</ShapperButton>
                    </Link>
                );
           }

            let confirm_add_to_cart = (
                <Dialog
                    open={this.state.add_to_cart}
                    onClose={() => this.setState({add_to_cart: false})}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>{product.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dyn_string('market_alert_msg_succ_add_to_cart')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ShapperButton onClick={() => this.setState({add_to_cart: false})}>
                            {dyn_string('g_btn_ok')}
                        </ShapperButton>

                        {go_to_cart}
                    </DialogActions>
                </Dialog>
            );

            let config_error = (
                <Dialog
                    open={this.state.config_error}
                    onClose={() => this.setState({config_error: false})}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>{dyn_string('form_alert_title_info_warning')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dyn_string('market_alert_msg_err_variation_not_exist')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ShapperButton onClick={() => this.setState({config_error: false})}>
                            {dyn_string('g_btn_ok')}
                        </ShapperButton>
                    </DialogActions>
                </Dialog>
            );

            let attributs_error = (
                <Dialog
                    open={this.state.attributs_error}
                    onClose={() => this.setState({attributs_error: false})}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>{dyn_string('form_alert_title_info_warning')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dyn_string('market_alert_title_info_select_attributes')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ShapperButton onClick={() => this.setState({attributs_error: false})}>
                            {dyn_string('g_btn_ok')}
                        </ShapperButton>
                    </DialogActions>
                </Dialog>
            );

            let error_no_supp_id = (
                <Dialog
                    open={this.state.no_supp_id}
                    onClose={() => this.setState({no_supp_id: false})}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>{dyn_string('market_alert_msg_market_exclusive_title')}</DialogTitle>
                    <DialogActions>
                        <ShapperButton onClick={() => this.setState({no_supp_id: false})}>
                            {dyn_string('g_btn_ok')}
                        </ShapperButton>
                    </DialogActions>
                </Dialog>
            );

            let display_supplier_error = (
                <Dialog
                    open={this.state.supplier_error}
                    onClose={() => this.setState({supplier_error: false})}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>{dyn_string('market_alert_msg_market_exclusive_title')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dyn_string('market_alert_msg_market_exclusive_msg')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ShapperButton onClick={() => this.setState({supplier_error: false})}>
                            {dyn_string('market_alert_msg_market_exclusive_remove_product')}
                        </ShapperButton>

                        <ShapperButton onClick={() => this.setState({supplier_error_confirmation: true, supplier_error: false})}>
                            {dyn_string('market_alert_msg_market_exclusive_remove_cart')}
                        </ShapperButton>
                    </DialogActions>
                </Dialog>
            );

            let display_supplier_error_confirmation = (
                <Dialog
                    open={this.state.supplier_error_confirmation}
                    onClose={() => this.setState({supplier_error_confirmation: false})}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>{dyn_string('market_alert_msg_market_exclusive_remove_cart_confirmation')}</DialogTitle>
                    <DialogActions>
                        <ShapperButton onClick={() => this.setState({supplier_error_confirmation: false})}>
                            {dyn_string('g_btn_cancel')}
                        </ShapperButton>

                        <ShapperButton onClick={() => {this.AddToCart("confirmation")}}>
                            {dyn_string('market_alert_msg_market_exclusive_remove_cart_confirmation_empty')}
                        </ShapperButton>
                    </DialogActions>
                </Dialog>
            );

            return (
                <>
                    <BreadCrumbs id={this.props.dataPage.id} />
                    <Container
                        maxWidth={"lg"}
                        style={{ paddingTop: 40, paddingBottom: 40}}
                    >
                        <Row className="justify-content-center">
                            {mediasDisplay}
                            <Col md={6}>
                                <h1 className="header" style={{color: this.props.styles.navbar.color, fontSize: this.props.styles.navbar.fontSize, margin: this.props.styles.navbar.margin, heigth: this.props.styles.navbar.height, textAlign: 'center'}}>
                                    {product.title}
                                </h1>
                                <h2 className="header" style={{color: this.props.styles.navbar.color, fontSize: this.props.styles.navbar.fontSize, margin: this.props.styles.navbar.margin, heigth: this.props.styles.navbar.height, textAlign: 'center', marginTop: '5px'}}>
                                    {product.short_description}
                                </h2>
                                <div style={{ marginTop: "20px", textAlign: "right" }}>
                                    <h3 style={{marginBottom: "0px"}}>
                                        {dyn_string('market_label_price_euro', {price: price_ttc})}
                                    </h3>
                                    <h4 style={{marginTop: "0px"}}>
                                        {dyn_string('market_label_price_ht_euro', {price: price_ht})} / {this.state.tva}
                                    </h4>
                                </div>

                                {attributsDisplay}  {/*pas de div ici car deja dans la const*/}

                                <Form.Group>
                                    <Form.Label>{label_quantity}</Form.Label>
                                    <Form.Control
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        name="quantity"
                                        type="number"
                                        min={product.min_quantity}
                                        max={product.max_quantity}
                                        step={product.quantity_step}
                                        defaultValue={product.quantity_default}
                                    />
                                </Form.Group>

                                <div style={{ marginTop: "20px" }}>
                                    {product_description}
                                </div>

                                <div>
                                    {linked_productsDisplay}
                                </div>

                                <div style={{textAlign:'center', marginTop:'20px'}}>
                                    <ShapperButton onClick={this.AddToCart} >
                                        {dyn_string('market_btn_add_to_cart')}
                                    </ShapperButton>
                                </div>
                            </Col>
                        </Row>

                        {/* popup */}
                        {confirm_add_to_cart}
                        {config_error}
                        {attributs_error}
                        {error_no_supp_id}
                        {display_supplier_error}
                        {display_supplier_error_confirmation}
                    </Container>
                </>
            );
        } else {
            return (
                <>
                    <BreadCrumbs id={this.props.dataPage.id} />
                    <div style={{textAlign:'center', height: this.state.height-250}}>
                        <h4>{dyn_string('g_label_no_result')}</h4>
                    </div>
                </>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    products: state.cart.items,
    currentSupplier: state.cart.currentSupplier,
    styles: state.style,
    routes: state.routes,
    application: state.application
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addArticle,
            deleteArticle,
            purgeCart,
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Product);

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from './serviceWorker';
import { CookiesProvider } from 'react-cookie';
import {Router, Route} from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css'
import {Provider} from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {store, persistor} from "./store/store";
import history from "./history";
import { I18nextProvider } from 'react-i18next';
import i18n from './config/i18n';
import ErrorBoundary from "./helpers/ErrorBoundary";
import UpdatePassword from "./views/Mobinaute/MobinauteUpdatePassword/Mobinaute.UpdatePassword";

if(process.env.NODE_ENV === "production"){
    console.log = () => {};

    if(localStorage.hasOwnProperty('persist:store')){
        window.localStorage.clear();
    }
} else {
    window.store = store
}

ReactDOM.render(
            <I18nextProvider i18n={i18n}>
                <Router history={history}>
                    <Route path="update_password" component={UpdatePassword} />
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <CookiesProvider>
                                <ErrorBoundary>
                                    <App />
                                </ErrorBoundary>
                            </CookiesProvider>
                        </PersistGate>
                    </Provider>
                </Router>
            </I18nextProvider>
    , document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import {useSelector} from "react-redux";
import {buildShapperPath} from "../../../helpers/Tools";
import React from "react";

export const NavBarMobileLogo = () => {

    const application = useSelector(state => state.application);

    if(application.getApp.webParams.logo_url){
        return (
            <div style={{textAlign:"center", paddingBottom:"0.8em"}}>
                <img
                    src={buildShapperPath(application.getApp.webParams.logo_url)}
                    height="100"
                    className="d-inline-block align-top"
                    alt="logo"
                />
            </div>
        )
    } else {
        return ""
    }
}

export const REQUEST_GETAUTHENTICATIONSSO_DATA = "REQUEST_GETAUTHENTICATIONSSO_DATA";
export const RECEIVE_GETAUTHENTICATIONSSO_DATA = "RECEIVE_GETAUTHENTICATIONSSO_DATA";
export const RESET_GETAUTHENTICATION_SSO_DATA = "RESET_GETAUTHENTICATION_SSO_DATA";
export const NEW_DATA_CONTENT_AUTH_SSO_FROM_CONNECTOR = "NEW_DATA_CONTENT_AUTH_SSO_FROM_CONNECTOR";

export const requestGetAuthenticationSSOData = () => ({
    type: REQUEST_GETAUTHENTICATIONSSO_DATA
});

export const receiveGetAuthenticationSSOData = data => ({
    type: RECEIVE_GETAUTHENTICATIONSSO_DATA,
    payload: data
});

export const newDataContentAuthSsoFromConnector = result => {
    return {
        type: NEW_DATA_CONTENT_AUTH_SSO_FROM_CONNECTOR,
        payload : result
    }
};

export const resetAuthenticationSsoData = () => ({
    type: RESET_GETAUTHENTICATION_SSO_DATA
});

import React, {useEffect, useState} from "react";
import {isNotEmpty} from "../../../../helpers/Tools";
import {getTags, detachTag} from "../../../../api/api";
import {useSelector} from "react-redux";
import {ShapperLoader} from "../../../../components/ShapperLoader/ShapperLoader";
import {MobinauteWalletHeader} from "../MobinauteWalletHeader/Mobinaute.Wallet.Header";
import {MobinauteWalletBody} from "../MobinauteWalletBody/Mobinaute.Wallet.Body";
import {ErrorDeleteCard} from "../Mobinaute.Wallet.popup";

export const TagWallet = (props) => {
    const mobinaute = useSelector(state => state.profil.mobinaute);
    const [loading, setLoading] = useState(true);
    const [errorDeleteCard, setErrorDeleteCard] = useState(false);
    const [cardList, setCardList] = useState([]);

    useEffect(() => {
        if(isNotEmpty(mobinaute)){
            getTags().then(data => {
                //console.log(data);

                if(data.success){
                    setLoading(false)
                    setCardList(data.result)
                }
            }).catch(e => {
                console.log("erreur get tags use effect", e)
            })
        }
    }, [mobinaute])

    async function deleteCard(tagId){
        setLoading(true)

        detachTag(tagId)
            .then(async (data) => {
                if(data.success){
                    await hydrateInfoTags()
                } else {
                    console.log(data)

                    setErrorDeleteCard(true);
                    setLoading(false)
                }
            })
            .catch(async (e) => {
                console.log("erreur detach", e)

                setErrorDeleteCard(true);
                setLoading(false)
            })
    }

    async function hydrateInfoTags(){
        setLoading(true)

        getTags().then(data => {
            if(data.success){
                setCardList(data.result)
                setLoading(false)
                window.scrollTo(0,0);
            }
        }).catch(e => {
            console.log("erreur get tags", e)
        })
    }

    if(loading){
        return (
            <ShapperLoader width={props.width} height={props.height}/>
        )
    } else {
        return (
            <div style={{height: props.height}}>
                <MobinauteWalletHeader hydrateInfoMobinaute={hydrateInfoTags} type="tag"/>

                <MobinauteWalletBody cardList={cardList} deleteCard={deleteCard} type="tag"/>

                <ErrorDeleteCard isOpen={errorDeleteCard} onClose={() => setErrorDeleteCard(true)} />
            </div>
        )
    }
};

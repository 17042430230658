import React from "react";
import { withTranslation } from 'react-i18next';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {arrayFilter} from "../../helpers/Tools";
import OpenItem from "../../helpers/OpenItem";
import {CardLandscape} from "../../components/Card/Card.Landscape";
import "./Announce.css"
import {requestGetAnnouncesData, resetAnnouncesList} from "../../actions";
import FlatList from "flatlist-react";

class AnnounceList extends React.Component {

    componentDidMount() {
        this.props.requestGetAnnouncesData({
            categoryProductId: this.props.dataPage.category_product_id ?? null
        });
    }

    componentWillUnmount() {
        //this.props.resetAnnouncesList()
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        let unknownDivs = document.getElementsByClassName("erd_scroll_detection_container_animation_active");

        for(let unknownDiv of Array.from(unknownDivs)){
            unknownDiv.remove()
        }
    }

    render() {

        const list = arrayFilter(this.props.dataPage.nodes, 'is_visible', '1');

        if(this.props.inner_app_loading){
            return ""
        }

        return (

            <ul  style={{padding: "1rem", gridTemplateColumns : "repeat(auto-fill, minmax(300px, 1fr))"}}>
                <FlatList
                    list={list}
                    renderItem={this.renderCategory}
                    renderWhenEmpty={() => {
                        return this.props.inner_app_loading ? "" : <h4 style={{marginTop:"70px", textAlign:"center"}}>{this.props.t('g_label_no_result')}</h4>
                    }}
                    display={{
                        grid: true,
                        gridGap : "1rem",
                    }}
                />
            </ul>
        )
    }

    renderCategory = (announce, key) => {

        return (
            <OpenItem
                key={key}
                contentToRedirect={announce.id}
                isFor={'category'}
            >
                <CardLandscape item={announce} type="announce"  />
            </OpenItem>
        );
    }
}

const mapStateToProps = (state) => ({
    style: state.style,
    application: state.application,
    announces: state.announces,
    inner_app_loading: state.loader
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetAnnouncesData,
            resetAnnouncesList
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(AnnounceList);

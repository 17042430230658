export function constructStripeCardUI(paymentMethod, isForWallet = false){
    let className;
    let label;

    switch (paymentMethod.card.brand){
        case "visa":
            className="fa fa-cc-visa"
            label = "Visa"
            break;
        case "amex":
            className="fa fa-cc-amex"
            label = "American Express"
            break;
        case "mastercard":
            className="fa fa-cc-mastercard"
            label = "Mastercard"
            break;
        default :
            className="fa fa-credit-card"
            label = paymentMethod.card.brand
            break;
    }

    return {
        className: isForWallet ? className : className +" PickUpIcon",
        label: label,
        last4: paymentMethod.card.last4,
        id: paymentMethod.id,
        exp_month: paymentMethod.card.exp_month,
        exp_year: paymentMethod.card.exp_year,
        expiration: paymentMethod.card.exp_month+"/"+paymentMethod.card.exp_year
    }
}

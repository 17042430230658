
import axios from "axios";
import * as Constants from "./api.constants";
import {store} from "../store/store";
import {
    errorInitApp,
    resetAuthenticationData,
    resetAuthenticationSsoData,
    resetContentData,
    resetRouteData, setAppDevice, setAppGetApp,
    setAppSecretKey, setDeviceToken
} from "../actions";
import {getBrowser, getOS, makeId} from "../helpers/Tools";
import {baseApiUrl} from "./api";
import md5 from "md5";
import Cookies from "universal-cookie";

const cookies = new Cookies();

//GetSecretKey
export const fetchGetSecretKey = async () => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("domain", window.location.hostname);

        // TEMPORAIRE VERIF NCNEWS ET CFECGC ET GIANT
        let www = "www.";
        if (bodyFormData.get("domain") === "ncnews.nc" || bodyFormData.get("domain") === "cfecgcagro.org" || bodyFormData.get("domain") ==="giantfrancedealerbook.fr") {
            bodyFormData.set("domain", www.concat('', window.location.hostname));
        }

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_SECRET_KEY_BY_DOMAIN_NAME,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: Constants.TIMEOUT,
        })
            .then(function (response) {
                if(response.data.success){
                    store.dispatch(setAppSecretKey({secretKey: response.data.result}))
                    store.dispatch(resetAuthenticationSsoData())
                    store.dispatch(resetAuthenticationData())
                    store.dispatch(resetContentData())
                    store.dispatch(resetRouteData())
                } else {
                    store.dispatch(errorInitApp({info:"fetchGetSecretKey", data: response}))
                }

                return response.data.result;
            })
            .catch(function (response) {
                console.log(response);
                store.dispatch(errorInitApp({info:"fetchGetSecretKey", data: response}))
            });
    } catch (e) {
        console.log(e);
        store.dispatch(errorInitApp({info:"fetchGetSecretKey", data: e}))
    }
};

//getApplication
export const fetchGetApplication = async () => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("appSecretKey", store.getState().application.secretKey);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_APPLICATION,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: Constants.TIMEOUT,
        })
            .then(function (response) {
                //handle success'(('
                if(response.data.success){
                    if(response.data.error_code !== 1005) {
                        if (!response.data.result.id) {
                            window.location.reload(false);
                        }

                        store.dispatch(setAppGetApp(response.data.result))
                    }
                } else {
                    store.dispatch(errorInitApp({info:"fetchGetApplication", data: response}))
                }
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                store.dispatch(errorInitApp({info:"fetchGetApplication", data: response}))

            });
    } catch (e) {
        console.log(e);
        store.dispatch(errorInitApp({info:"fetchGetApplication", data: e}))
    }
};

//GetAppColor
export const fetchGetAppColor = async () => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("appId", store.getState().application.getApp.id);
        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_APP_COLOR,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: Constants.TIMEOUT,
        })
            .then(function (response) {
                //handle success
                if(!response.data.success){
                    store.dispatch(errorInitApp({info:"fetchGetAppColor", data: response}))

                }

                return response.data.result;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                store.dispatch(errorInitApp({info:"fetchGetAppColor", data: response}))
            });
    } catch (e) {
        console.log(e);
        store.dispatch(errorInitApp({info:"fetchGetAppColor", data: e}))
    }
};

//GetAppStyle
export const fetchGetAppStyle = async () => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("appId", store.getState().application.getApp.id);
        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_APP_STYLES,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: Constants.TIMEOUT,
        })
            .then(function (response) {
                if(!response.data.success)
                    store.dispatch(errorInitApp({info:"fetchGetAppStyle", data: response}))

                return response.data.result;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                store.dispatch(errorInitApp({info:"fetchGetAppStyle", data: response}))

            });
    } catch (e) {
        console.log(e);
        store.dispatch(errorInitApp({info:"fetchGetAppStyle", data: e}))
    }
};

//GetAppFonts
export const fetchGetAppFonts = async () => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("appId", store.getState().application.getApp.id);
        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_APP_FONTS,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: Constants.TIMEOUT,
        })
            .then(function (response) {
                //handle success
                if(!response.data.success)
                    store.dispatch(errorInitApp({info:"fetchGetAppFonts", data: response}))

                return response.data.result;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                store.dispatch(errorInitApp({info:"fetchGetAppFonts", data: response}))
            });
    } catch (e) {
        console.log(e);
        store.dispatch(errorInitApp({info:"fetchGetAppFonts", data: e}))
    }
};

//UpdatePassword
export const fetchUpdatePassword = async (props) => {

    let oldPassword = md5(props.oldPassword);
    let newPassword = md5(props.newPassword);

    return updatePassword(oldPassword, newPassword).then(response => {
        if(response.result){
            return response.result
        }
        else {
            return response;
        }
    });
};


export const updatePassword = async (oldPassword, newPassword) => {
    try {
        let state = store.getState()

        let appId = state.application.getApp.id;
        let mobinauteId = state.profil.mobinaute.id;

        let bodyFormData = new FormData();
        bodyFormData.set("appId", appId);
        bodyFormData.set("oldPassword", oldPassword);
        bodyFormData.set("newPassword", newPassword);

        if(mobinauteId)
            bodyFormData.set("mobinauteId", parseInt(mobinauteId, 10));

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_UPDATE_PASSWORD,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: Constants.TIMEOUT,
        })
            .then(function (response) {
                //handle success
                if(!response.data.success) {
                    //window.location.assign('/');
                }
                else if(response.data.success){
                    const date = new Date(Date.now());
                    date.setFullYear(date.getFullYear() + 1);

                    cookies.set("password", newPassword, {path: "/", expires: date});
                    //window.location.href = "/" ;
                }

                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//RegisterDevice
export const fetchRegisterDevice = async () => {
    try {
        let uid;
        let state = store.getState()

        if (state.application.randomize) {
            uid = state.application.randomize;
        } else {
            uid = getOS() + "_" + makeId(16) + "_" + state.application.getApp.id;
        }

        let bodyFormData = new FormData();
        bodyFormData.set("appId", state.application.getApp.id);
        bodyFormData.set("deviceUdid", uid);
        bodyFormData.set("deviceName", getOS());
        bodyFormData.set("deviceType", getOS());
        bodyFormData.set("token", "");
        bodyFormData.set("osName", getOS());
        bodyFormData.set("osVersion", getBrowser().fullVersion);
        bodyFormData.set("model", getBrowser().browserName);
        bodyFormData.set("appIdentifier", state.application.getApp.identifier_android);
        bodyFormData.set("appName", state.application.getApp.title);
        bodyFormData.set("appVersion", state.application.getApp.title);
        bodyFormData.set("pushMessageEnable", "1");
        bodyFormData.set("pushSoundEnable", "1");
        bodyFormData.set("pushBadgeEnable", "1");
        bodyFormData.set("locationEnable", "1");
        bodyFormData.set("lang", state.application.language.substr(0,2));
        bodyFormData.set("badgeValue", "10");
        bodyFormData.set("isDev", "0");

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_REGISTER_DEVICE,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: Constants.TIMEOUT,
        })
            .then(function (response) {

                if(response.data.success){
                    store.dispatch(setAppDevice({
                        randomize: uid,
                        deviceId: response.data.result.device_id,
                        deviceToken: response.data.result.shapper_token,
                    }))
                } else {
                    store.dispatch(errorInitApp({info:"fetchRegisterDevice", data: response}))
                }

                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
                store.dispatch(errorInitApp({info:"fetchRegisterDevice", data: response}))

            });
    } catch (e) {
        console.log(e);
        store.dispatch(errorInitApp({info:"fetchRegisterDevice", data: e}))
    }
};

import React, {useState, useRef} from "react";
import TextField from "@material-ui/core/TextField/TextField";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import CropFreeIcon from '@material-ui/icons/CropFree';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import QrReader from 'react-qr-reader';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import "../Formulaire.css"

/*Attention de regarder s'il a des MAJs sur QrReader si jamais vous voulez update sur un React + récent */

export const FormulaireDynamicQrCode = (props) => {
    const [scanCode, setScanCode] = useState(false);

    return (
        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
            <TextField
                noValidate
                required={props.required}
                disabled={props.disabled || props.formState['v_' + props.res.id+"_loading"]}
                variant="outlined"
                margin="normal"
                fullWidth
                id={props.res.id}
                name={'v_' + props.res.id}
                type="text"
                placeholder={props.res.placeholder_message}
                value={props.formState['v_' + props.res.id] ? props.formState['v_' + props.res.id] : ""}
                onChange={props.handleChange}
                error={props.error}
                label={props.label}
                inputProps={{
                    is_interpret: props.res.interpret,
                    ...props.inputProps
                }}
                style={{marginRight: "8px"}}
            />

            {props.formState['v_' + props.res.id+"_loading"] && (
                <CircularProgress size={25} style={{color:"black"}}/>
            )}

            {!props.formState['v_' + props.res.id+"_loading"] && (
                <CropFreeIcon className="form-action-icons" onClick={() => setScanCode(true)}/>

            )}

            {!props.formState['v_' + props.res.id+"_loading"] && props.formState['v_' + props.res.id] && (
                <DeleteOutlineIcon className="form-action-icons" onClick={() => props.resetState(props.res.id, props.res.type)}/>
            )}

            <QrCodeContainer
                isOpen={scanCode}
                onClose={() => setScanCode(false)}
                handleTagTreatment={props.handleTagTreatment}
                {...props}
            />
        </div>
    )
}

const QrCodeContainer = (props) => {
    const [scanResultWebCam, setScanResultWebCam] =  useState('');
    const [scanResultFile, setScanResultFile] = useState('');
    const [isErrorWebCam, setErrorWebCam] =  useState(false);
    const styles = useSelector(state => state.style);
    const { t } = useTranslation();
    const qrRef = useRef(null);

    const handleErrorFile = (error) => {
        console.log(error);
        props.onClose();
    }
    const handleScanFile = (result) => {
        if (result) {
            setScanResultFile(result);
            props.handleTagTreatment(result, props.res.id, true)
            setScanResultFile("");
            props.onClose()
        }
    }
    const onScanFile = () => {
        qrRef.current.openImageDialog();
        setScanResultFile("");
    }

    const handleErrorWebCam = (error) => {
        console.log(error);
        setErrorWebCam(true);
    }

    const handleScanWebCam = (result) => {
        if (result){
            setScanResultWebCam(result);
            props.handleTagTreatment(result, props.res.id, true)
            setScanResultFile("");
            props.onClose()
        }
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle  style={{padding:"8px 12px", textAlign:"center"}}>{t('qr_title_select')}</DialogTitle>
            <DialogContent style={{minWidth:"340px", padding:"8px 12px"}}>
                {!isErrorWebCam && (
                    <React.Fragment>
                        <QrReader
                            delay={300}
                            style={{width: '100%'}}
                            onError={handleErrorWebCam}
                            onScan={handleScanWebCam}
                        />
                        <h3 style={{display:"none"}}>{scanResultWebCam}</h3>
                    </React.Fragment>
                )}

                {isErrorWebCam && (
                    <React.Fragment>
                        <Button onClick={onScanFile} style={{backgroundColor: styles.button.backgroundColor, borderColor: styles.button.backgroundColor, color: styles.button.color}}>
                            {t('qr_label_select')}
                        </Button>
                        <QrReader
                            ref={qrRef}
                            delay={300}
                            style={{width: '100%', marginTop:"10px"}}
                            onError={handleErrorFile}
                            onScan={handleScanFile}
                            legacyMode
                        />
                        <h3 style={{display:"none"}}>{scanResultFile}</h3>
                    </React.Fragment>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                    style={{backgroundColor: "white", borderColor: styles.button.backgroundColor, color:styles.button.backgroundColor, border: "solid 1px"}}
                >
                    {t('g_btn_cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

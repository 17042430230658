import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faUndoAlt} from "@fortawesome/free-solid-svg-icons";
import {attachTag} from "../../../../api/api";
import "../MobinauteWalletBody/mobinaute.wallet.css"
import {ErrorMessage} from "../../../../components/ErrorMessage/Error.Message";

export const TagWalletCreateTag = (props) => {
    const style = useSelector(state => state.style);
    const { t } = useTranslation();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tagUid, setTagUid] = useState("");

    async function handleCreateTag(){
        setLoading(true)

        attachTag(tagUid)
            .then(async (data) => {

                if(data.success){
                    props.hydrateInfoMobinaute();
                } else {
                    setError(data.error);
                    setLoading(false)
                }
            })
            .catch(async (e) => {
                console.log("erreur attach", e)

                setError(t('g_alert_title_err_loading'));
                setLoading(false)
            })
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle  style={{padding:"8px 12px", textAlign:"center"}}>{t('tag_label_add_tag')}</DialogTitle>
            <DialogContent style={{minWidth:"340px", padding:"8px 12px"}}>
                <div id="container-add-card-wallet">
                    <div className="CardHolder">
                        <fieldset className="FormGroup">
                            <div className="FormRow">
                                <input
                                    className="FormRowInput"
                                    id="cardHolder"
                                    type="text"
                                    placeholder={t('tag_label_uid_tag')}
                                    value={tagUid}
                                    onChange={(e) => {setTagUid(e.target.value)}}
                                />
                            </div>
                        </fieldset>
                    </div>

                    {error && <ErrorMessage>{error}</ErrorMessage>}

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        style={{ backgroundColor:style.button.backgroundColor, marginTop:"20px", marginBottom:"10px"}}
                        onClick={handleCreateTag}
                        disabled={loading}
                    >
                        {loading ? (
                            <CircularProgress size={25} style={{color:"white"}}/>
                        ) : (
                            <>
                                {t('tag_label_save_tag')}
                                <FontAwesomeIcon
                                    icon={faLock}
                                    style={{ marginLeft: "10px"}}
                                /></>
                        )}
                    </Button>

                    {!loading && (
                        <Button
                            onClick={props.onClose}
                            variant="contained"
                            color="primary"
                            fullWidth
                            style={{ backgroundColor:style.button.backgroundColor, opacity: "0.8"}}
                        >
                            {t('form_btn_product_cancel')}
                            <FontAwesomeIcon
                                icon={faUndoAlt}
                                style={{ marginLeft: "10px"}}
                            />
                        </Button>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
}

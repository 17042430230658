import React from "react";
import {Grid,} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";

export const FormulaireDynamicAddress = (props) => {

    return (
        <div style={{marginTop: "16px"}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        type="address"
                        required={props.required}
                        disabled={props.disabled}
                        fullWidth
                        id={'address_address1_' + props.res.id}
                        label="Adresse 1"
                        name={'v_' + props.res.id+"['address1']"}
                        placeholder={props.res.placeholder_message}
                        value={props.formState['v_' + props.res.id] && props.formState['v_' + props.res.id]['address1'] ? props.formState['v_' + props.res.id]['address1'] : ""}
                        onChange={props.handleChange}
                        error={props.error}
                        inputProps={props.inputProps}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        type="address"
                        disabled={props.disabled}
                        fullWidth
                        id={'address_address2_' + props.res.id}
                        label="Adresse 2"
                        name={'v_' + props.res.id+"['address2']"}
                        placeholder={props.res.placeholder_message}
                        value={props.formState['v_' + props.res.id] && props.formState['v_' + props.res.id]['address2'] ? props.formState['v_' + props.res.id]['address2'] : ""}
                        onChange={props.handleChange}
                        inputProps={props.inputProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required={props.required}
                        disabled={props.disabled}
                        id={'address_city_'  + props.res.id}
                        variant="outlined"
                        type="address"
                        name={'v_' + props.res.id+"['city']"}
                        label="Ville"
                        fullWidth
                        placeholder={props.res.placeholder_message}
                        value={props.formState['v_' + props.res.id] && props.formState['v_' + props.res.id]['city'] ? props.formState['v_' + props.res.id]['city'] : ""}
                        onChange={props.handleChange}
                        error={props.error}
                        inputProps={props.inputProps}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        type="address"
                        required={props.required}
                        id={'address_zip_' + props.res.id}
                        variant="outlined"
                        name={'v_' + props.res.id+"['zip']"}
                        label="Code Postal"
                        fullWidth
                        placeholder={props.res.placeholder_message}
                        value={props.formState['v_' + props.res.id] && props.formState['v_' + props.res.id]['zip'] ? props.formState['v_' + props.res.id]['zip'] : ""}
                        onChange={props.handleChange}
                        error={props.error}
                        inputProps={props.inputProps}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="address"
                        required={props.required}
                        disabled={props.disabled}
                        id={'address_country_' + props.res.id}
                        variant="outlined"
                        name={'v_' + props.res.id+"['country']"}
                        label="Pays"
                        fullWidth
                        placeholder={props.res.placeholder_message}
                        value={props.formState['v_' + props.res.id] && props.formState['v_' + props.res.id]['country'] ? props.formState['v_' + props.res.id]['country'] : ""}
                        onChange={props.handleChange}
                        error={props.error}
                        inputProps={props.inputProps}
                    />
                </Grid>
            </Grid>
        </div>
    )
};

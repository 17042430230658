export function calcAdaptiveVue(pos, Graph, Screen, adapt) {

    if(adapt){
        return ((parseInt(pos.replace(/px/g, ""))/parseInt(Graph))* parseInt(Screen))
    }else {
        return parseInt(pos.replace(/px/g, ""))
    }

}

//Trouver le type d'url d'un objet graphique
export function typeUrl(link) {
    if (link) {
        if (!isNaN(link)) {
            return "AppLink";
        } else {
            return "ExternalLink";
        }
    } else return "NoLink";
}

export const ADD_VARIABLE_DATA = "ADD_VARIABLE_DATA";
export const CLEAR_VARIABLE_DATA = "CLEAR_VARIABLE_DATA";

export const addVariableData = (variable_object) => {
    return {
        type: ADD_VARIABLE_DATA,
        payload: variable_object
    }
}

export const clearVariableData = () => {
    return {
        type: CLEAR_VARIABLE_DATA,
    }
}

import React, {useState} from "react";
import {Carousel, CarouselControl, CarouselIndicators, CarouselItem} from "reactstrap";
import {OneDisplay} from "../OneDisplay/OneDisplay";
import {typeUrl} from "../GraphicalView.functions";
import {buildShapperPath} from "../../../helpers/Tools";

export const SectionCarousel = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === props.data.content.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? props.data.content.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = props.data.content.map((item,key) => {
        if(props.type === "Carousel"){
            let url = item.url ? item.url : props.data.link;
            return (
                <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={key}
                >
                    <div style={{width : props.width, height : props.height, x : props.x, y:props.y}} >
                        <OneDisplay
                            src={buildShapperPath(item.image)}
                            x={props.x}
                            y={props.y}
                            width={props.width}
                            height={props.height}
                            link={url}
                            typeLink={typeUrl(url)}
                            data={item}
                            parentId={props.parentId}
                        />
                    </div>
                </CarouselItem>
            );
        }
        else{
            return (
                <CarouselItem
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={key}
                >
                    <div style={{width : props.width, height : props.height, x : props.x, y:props.y}} >
                        <OneDisplay
                            src={buildShapperPath(item.image)}
                            x={props.x}
                            y={props.y}
                            width={props.width}
                            height={props.height}
                            link={item.link}
                            typeLink="AppLink"
                            data={item}
                            parentId={props.parentId}
                        />
                    </div>
                </CarouselItem>

            );

        }
    });

    let hasOptionIndicator ;
    let hasOptionManualScroll;
    let scrollTime = 0;

    if(props.data.hasOptionIndicator){
        hasOptionIndicator = <CarouselIndicators items={props.data.content} activeIndex={activeIndex} onClickHandler={goToIndex} />
    }
    if(props.data.hasOptionManualScroll){
        hasOptionManualScroll =<> <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} /> <CarouselControl direction="next" directionText="Next" onClickHandler={next} /> </>
    }
    if(props.data["scroll-time"]){
        scrollTime = props.data["scroll-time"]*1000;
    }

    return (
        <Carousel
            activeIndex={activeIndex}
            ride={scrollTime > 0 ? "carousel" : ""}
            next={next}
            previous={previous}
            interval={scrollTime}
        >
            {hasOptionIndicator}
            {slides}
            {hasOptionManualScroll}
        </Carousel>
    );
}

import React from "react";
import { youtubeUrl} from "../../helpers/Tools";
import {withTranslation} from "react-i18next";

// Composant permettant l'affichage d'élément de type Photo
class video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    render() {
        console.log("%c ////////CONTENU-VIDEO////////",  'background: #222; color: #bada55');

        return (
            <iframe
                style={{ width: "100%", height: "100vh" }}
                title="youtube"
                width="560"
                height="315"
                src={youtubeUrl(this.props.dataPage.youtube_url)}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        );
    }
}

export default withTranslation()(video);

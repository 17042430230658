
export const SET_ROUTES_INFO = 'SET_ROUTES_INFO';
export const RESET_ROUTE_DATA = 'RESET_ROUTE_DATA';

export const setRoutesInfo = (info) => {
    return {
        type: SET_ROUTES_INFO,
        payload: info
    }
}

export const resetRouteData = () => ({
    type: RESET_ROUTE_DATA
});

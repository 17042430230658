import {
    RECEIVE_GETAUTHENTICATION_DATA,
    RESET_GETAUTHENTICATION_DATA,
    NEW_DATA_CONTENT_AUTH_FROM_CONNECTOR,
    REQUEST_GETAUTHENTICATION_DATA
} from "../actions/index";

const contentauth = (state = {
    loading: false
}, action) => {
    switch (action.type) {
        case REQUEST_GETAUTHENTICATION_DATA:
            return {
                loading: true
            }
        case RECEIVE_GETAUTHENTICATION_DATA:
            return action.payload;
        case RESET_GETAUTHENTICATION_DATA:
            return {
                loading: false
            };
        case NEW_DATA_CONTENT_AUTH_FROM_CONNECTOR:
            return {
                ...state,
                result: action.payload
            }
        default:
            return state;
    }
};

export default contentauth

export const INIT_PLAYLIST = "INIT_PLAYLIST";
export const NEXT_PLAYLIST_ELEMENT = "NEXT_PLAYLIST_ELEMENT";
export const PREVIOUS_PLAYLIST_ELEMENT = "PREVIOUS_PLAYLIST_ELEMENT";
export const SELECT_PLAYLIST_ELEMENT = "SELECT_PLAYLIST_ELEMENT";

export const initPlaylist = (playlist) => {
    return {
        type: INIT_PLAYLIST,
        payload: playlist
    }
}
export const nextPlaylistElement = (nextMedia) => {
    return {
        type: NEXT_PLAYLIST_ELEMENT,
        payload: nextMedia
    }
}
export const previousPlaylistElement = (previousMedia) => {
    return {
        type: PREVIOUS_PLAYLIST_ELEMENT,
        payload: previousMedia
    }
}
export const selectPlaylistElement = (mediaIndex) => {
    return {
        type: SELECT_PLAYLIST_ELEMENT,
        payload: mediaIndex
    }
}

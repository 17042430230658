import React from "react";

const footerStyle = {
    MozBoxShadow: "3px 3px 5px 6px #ccc",
    WebkitBoxShadow: "3px 3px 5px 6px #ccc",
    boxShadow: "3px 3px 5px 6px #ccc",
    backgroundColor: "white",
    fontSize: "20px",
    color: "black",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "10px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "40px",
    width: "100%",
    zIndex: 2,
};

const phantomStyle = {
    display: "block",
    padding: "10px",
    height: "40px",
    width: "100%"
};

function Footer({children}) {
    return (
        <div id="footer">
            <div style={phantomStyle} />
            <div style={footerStyle}>
                {children}
            </div>
        </div>
    );
}

export default Footer;

import React, {useState} from "react";
import {Divider} from "@material-ui/core";
import MediaQuery from 'react-responsive'
import {MobinauteWalletHeaderCardLabel} from "./Mobinaute.Wallet.Header.Card.Label";
import {StripeWalletCreateCardContainer} from "../StripeWallet/StripeWalletCreateCard/Stripe.Wallet.Create.Card.Container";
import {TagWalletCreateTag} from "../TagWallet/Tag.Wallet.Create.Tag";

export const MobinauteWalletHeader = (props) => {
    const [addCard, setAddCart] = useState(false);

    return (
        <div id="container-header-wallet">

            <MediaQuery minDeviceWidth={800}>
                <div id="header-wallet" style={{padding: "15px 0", display: "flex", justifyContent: "flex-end"}}>
                   <MobinauteWalletHeaderCardLabel displayAddCard={() => setAddCart(true)} type={props.type}/>
                </div>
            </MediaQuery>

            <MediaQuery maxDeviceWidth={800}>
                <div id="header-wallet" style={{padding: "15px", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
                    <MobinauteWalletHeaderCardLabel displayAddCard={() => setAddCart(true)} type={props.type}/>

                    <Divider style={{margin: "15px 0 0"}}/>
                </div>
            </MediaQuery>

            {props.type === "stripe" && (
                <StripeWalletCreateCardContainer
                    isOpen={addCard}
                    onClose={() => setAddCart(false)}
                    clientIntentSecret={props.clientIntentSecret}
                    publicKey={props.publicKey}
                    hydrateInfoMobinaute={props.hydrateInfoMobinaute}
                />
            )}

            {props.type === "tag" && (
                <TagWalletCreateTag
                    isOpen={addCard}
                    onClose={() => setAddCart(false)}
                    hydrateInfoMobinaute={props.hydrateInfoMobinaute}
                />
            )}
        </div>
    )
};

import {useSelector} from "react-redux";
import React from "react";
import {Button} from "@material-ui/core";
import {isNotEmpty} from "../../helpers/Tools";

export const  ShapperButton = (props) => {

    const style = useSelector(state => state.style);

    let buttonStyle = {...style.button, ...props.style};

    if(props.variant === "outlined" && isNotEmpty(style)){
        buttonStyle.backgroundColor = style.button.color;
        buttonStyle.color = style.button.backgroundColor;
    }

    return (
        <Button
            fullWidth={props.fullWidth}
            disabled={props.disabled}
            size={props.size}
            style={buttonStyle}
            onClick={props.onClick}
            type={props.type ?? "button"}
        >
            {props.children}
        </Button>
    )
}

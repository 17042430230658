
export function  NavBarMobileStyle (backgroundColor){
    return {
        bmBurgerButton: {
            position: "fixed",
            width: "25px",
            height: "20px",
            left: "20px",
            top: "14px",
        },
        bmBurgerBars: {
            background: "white",
        },
        bmBurgerBarsHover: {
            background: "#a90000",
        },
        bmCrossButton: {
            height: "24px",
            width: "24px",
        },
        bmCross: {
            background: "#bdc3c7",
        },
        bmMenuWrap: {
            top: 0,
            position: "fixed",
            height: "100%",
        },
        bmMenu: {
            background: backgroundColor,
            padding: "2.5em 0",
            fontSize: "1.15em",
        },
        bmMorphShape: {
            fill: "#373a47",
        },
        bmItemList: {
            display: "flex",
            flexDirection: "column",
            color: "#b8b7ad",
            padding: "0.8em",
        },
        bmItem: {
            display: "inline-block",
            transition: "none",
            outline:"none"
        },
        bmOverlay: {
            top: 0,
            background: "rgba(0, 0, 0, 0.3)",
        },
    }
};


import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {purgeCart, requestGetMarketData} from "../../../../actions";
import {Button, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import React from "react";

export const StripeValidate = (props) => {
    const {t} = useTranslation();
    const style = useSelector(state => state.style);
    const dispatch  = useDispatch()

    const handleFinish = () => {
        dispatch(purgeCart())
        dispatch(requestGetMarketData())
    }

    return (
        <Dialog
            open={props.isOpen}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>{t('market_alert_msg_succ_validate_order')}</DialogTitle>
            <DialogActions>
                <Button
                    onClick={() => handleFinish()}
                    style={{
                        backgroundColor: style.button.backgroundColor,
                        borderColor: style.button.backgroundColor,
                        color: style.button.color
                    }}
                >
                    {t('market_btn_continue')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

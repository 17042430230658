export const REQUEST_GETCONTENT_DATA = "REQUEST_GETCONTENT_DATA";
export const RECEIVE_GETCONTENT_DATA = "RECEIVE_GETCONTENT_DATA";
export const NEW_DATA_CONTENT_FROM_CONNECTOR = "NEW_DATA_CONTENT_FROM_CONNECTOR";
export const RESET_GETCONTENT_DATA = "RESET_GETCONTENT_DATA";

export const requestGetContentData = () => ({
    type: REQUEST_GETCONTENT_DATA
})

export const receiveGetContentData = data => {
    return {
        type: RECEIVE_GETCONTENT_DATA,
        payload:data
    }
};

export const newDataContentFromConnector = result => {
    return {
        type: NEW_DATA_CONTENT_FROM_CONNECTOR,
        payload: result
    }
};

export const resetContentData = () => ({
    type: RESET_GETCONTENT_DATA
});

import React from "react";
import {Route} from "react-router-dom";
import {buildShapperPath} from "../../helpers/Tools";
import {withTranslation} from "react-i18next";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {logout} from "../../containers/NavBar/NavBar.functions";
import {registerMobinaute, SetProfilForm} from "../../api/api.form";
import {authentification} from "../../api/api.content";
import {updatePassword} from "../../api/api";
import {store} from "../../store/store";
import {redirect, openItem} from "../../containers/Home/Home.functions";


class Web extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: buildShapperPath(this.props.dataPage.web_url)
        };
    }

    async componentWillUnmount() {
        if (window.removeEventListener) {
            window.removeEventListener('message', this.onMessage, false);
        }
    }

    async componentDidMount() {
        if (window.addEventListener) {
            window.addEventListener("message", this.onMessage, false);
            window.props = this.props;
        }
        else if (window.attachEvent) {
            window.attachEvent("onmessage", this.onMessage, false);
            window.props = this.props;
        }
    }

    async onMessage(event) {
        // Check sender origin to be trusted
        if (typeof(event.data.shapper_token) == 'undefined' || typeof(event.data.func) == 'undefined') return;

        let availableFunc = ["disconnect", "cancelRegistration", "authentificationUser", "registerUser", "updatePassword", "updateUser", "openItem"];
        let currentFunc = event.data.func;
        let data = event.data;
        if (!availableFunc.includes(currentFunc)) return;

        if(currentFunc === "disconnect"){
            logout();
        }
        else if(currentFunc === "registerUser"){

            if(typeof data.message.firstName !== 'undefined' && data.message.firstName !== ""
                && typeof data.message.lastName !== 'undefined' && data.message.lastName !== ""
                && typeof data.message.login !== 'undefined' && data.message.login !== ""
                && typeof data.message.password !== 'undefined' && data.message.password !== "") {

                let newMobinaute = {
                    "appId": event.currentTarget.props.application.getApp.id,
                    "deviceId": event.currentTarget.props.application.deviceId,
                    "firstname": data.message.firstName,
                    "lastname": data.message.lastName,
                    "login": data.message.login,
                    "mail": data.message.mail,
                    "pass": data.message.password,
                    "responses": data.message.responses
                }

                registerMobinaute(newMobinaute).then(response => {
                    if(response.success){
                        let message = {
                            'sender': "wa",
                            'func': 'onRegisterResult',
                            'result': {
                                'result': response.result,
                                'success': response.success,
                            }
                        };
                        document.getElementById('cross_domain_page').contentWindow.postMessage(message, "*")
                    }
                    else {
                        let message = {
                            'sender': "wa",
                            'func': 'onRegisterResult',
                            'result': {
                                'error': response.error,
                                'success': response.success,
                            }
                        };
                        document.getElementById('cross_domain_page').contentWindow.postMessage(message, "*")
                    }
                })
            }
            else {

            }
        }
        else if(currentFunc === "updateUser"){

            let state = store.getState();
            let mobinauteId = state.profil.mobinaute.id;

            if(typeof data.message.firstName !== 'undefined' && data.message.firstName !== ""
                && typeof data.message.lastName !== 'undefined' && data.message.lastName !== ""
                && typeof data.message.login !== 'undefined' && data.message.login !== ""
                && typeof mobinauteId !== 'undefined' && mobinauteId !== "") {

                let editMobinaute = {
                    "appId": event.currentTarget.props.application.getApp.id,
                    "mobinauteId" : data.message.mobinauteId,
                    "firstname": data.message.firstName,
                    "lastname": data.message.lastName,
                    "login": data.message.login,
                    "mail": data.message.mail,
                    "responses": data.message.responses
                }
                SetProfilForm(editMobinaute).then(response => {
                    if(response.success){
                        let message = {
                            'sender': "wa",
                            'func': 'onUpdateResult',
                            'result': {
                                'result': response.result,
                                'success': response.success,
                            }
                        };
                        document.getElementById('cross_domain_page').contentWindow.postMessage(message, "*")
                    }
                    else {
                        let message = {
                            'sender': "wa",
                            'func': 'onUpdateResult',
                            'result': {
                                'error': response.error,
                                'success': response.success,
                            }
                        };
                        document.getElementById('cross_domain_page').contentWindow.postMessage(message, "*")
                    }
                })
            }
            else {
            }
        }
        else if(currentFunc === "authentificationUser"){

            if( typeof data.message.login !== 'undefined' && data.message.login !== ""
                && typeof data.message.password !== 'undefined' && data.message.password !== "") {

                let appId =  event.currentTarget.props.application.getApp.id;
                let deviceId = event.currentTarget.props.application.deviceId;
                let login =  data.message.login;
                let password = data.message.password;

                let response = await authentification(appId, deviceId, login, password);
                window.location.href = "/" ;

            }

        }
        else if(currentFunc === "updatePassword"){

            if( typeof data.message.oldPassword !== 'undefined' && data.message.oldPassword !== ""
                && typeof data.message.newPassword !== 'undefined' && data.message.newPassword !== "") {


                let oldPassword =  data.message.oldPassword;
                let newPassword = data.message.newPassword;


                updatePassword(oldPassword, newPassword).then(response => {

                    if(response.success){
                        let message = {
                            'sender': "wa",
                            'func': 'onUpdatePasswordResult',
                            'result': {
                                'result': response.result,
                                'success': response.success,
                            }
                        };
                        document.getElementById('cross_domain_page').contentWindow.postMessage(message, "*")
                    }
                    else {
                        let message = {
                            'sender': "wa",
                            'func': 'onUpdatePasswordResult',
                            'result': {
                                'error': response.error,
                                'success': response.success,
                            }
                        };
                        document.getElementById('cross_domain_page').contentWindow.postMessage(message, "*")
                    }

                });



                //window.location.href = "/" ;

            }

        }
        else if(currentFunc === "openItem"){
            if( typeof data.message.contentId !== 'undefined' && data.message.contentId !== "") {
                openItem(data.message.contentId);
            }

        }
    }

    render() {
        console.log("%c ////////CONTENU-WEB////////",  'background: #222; color: #bada55');

        if (this.props.dataPage.category_type === 'web_browser'){
            return (
                <Route component={() => {

                    window.open(this.state.url, '_blank');

                    return (
                        <div style={{textAlign:"center"}}>
                            <h1 style={{color: '#888888'}}>{this.props.t('media_alert_msg_info_web_redirection')}</h1>
                        </div>
                    );
                }}/>
            );
        } else {
            return (
                <iframe
                    id="cross_domain_page"
                    title="web_page"
                    style={{ width: "100%", height: this.props.application.innerHeight }}
                    width="1080"
                    height="760"
                    src={this.state.url}
                    frameBorder="0"
                    allowFullScreen
                />
            );
        }
    }
}

const mapStateToProps = (state) => ({
    application: state.application
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Web);

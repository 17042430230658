
import {store} from "../store/store";
import axios from "axios";
import * as Constants from "./api.constants";
import {baseApiUrl, baseApiUrlAnalytics} from "./api";
import {setLoader, unsetLoader} from "../actions";
import {WS_ANALYTICS_SEND_CONTENT_STATS} from "./api.constants";

//GetMarket
export const fetchGetMarket = async () => {
    try {
        let bodyFormData = new FormData();
        let state = store.getState()

        bodyFormData.set("appId", state.application.getApp.id);
        bodyFormData.set("mobinauteId", parseInt(state.profil.mobinaute.id, 10))

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_MARKET,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//createOrder
export const createOrder = async (props) => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("appId", props.appId);
        bodyFormData.set("order", JSON.stringify(props.order));
        bodyFormData.set("mobinauteId", props.mobinauteId);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_CREATE_ORDER,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

export const getOrder = async (props) => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("appId", props.appId);
        bodyFormData.set("mobinauteId", props.mobinauteId);
        bodyFormData.set("orderId", props.orderId);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_ORDER,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};


export const addDiscountToOrder = async (props) => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("appId", props.appId);
        bodyFormData.set("mobinauteId", props.mobinauteId);
        bodyFormData.set("orderId", props.orderId);
        bodyFormData.set("codePromo", props.codePromo);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_ADD_DISCOUNT_TO_ORDER,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//orderValidation
export const orderValidation = async (props) => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("appId", props.appId);
        bodyFormData.set("orderId", props.orderId);
        bodyFormData.set("mobinauteId", props.mobinauteId);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_ORDER_VALIDATION,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//delete Card Stripe
export const detachPaymentMethod = async (props) => {
    try {
        let bodyFormData = new FormData();

        bodyFormData.set("pmId", props.pmId);
        bodyFormData.set("appId", store.getState().application.getApp.id);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_DETACH_PM,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(function (response) {
            //handle success
            return response.data
        });
    } catch (e) {
        console.log(e);
    }
};

//get Stripe information
export const getStripeInfoMobinaute = async () => {
    try {
        let bodyFormData = new FormData();
        let customerId = null;
        let mobinaute = store.getState().profil.mobinaute

        if(mobinaute){
            customerId = mobinaute.accounts.stripe;
        }

        bodyFormData.set("customerId", customerId);
        bodyFormData.set("appId", store.getState().application.getApp.id);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_STRIPE_INFOS,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(function (response) {
            //handle success

            return response.data
        });
    } catch (e) {
        console.log(e);
    }
};

//creation d'annonce
export const setMrktAnnounce = async (props) => {
    try {
        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_SET_MARKET_ANNOUNCE,
            data: props,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//GetAnnounces
export const fetchGetAnnounces = async (props) => {
    try {

        store.dispatch(setLoader())

        let bodyFormData = new FormData();
        let state = store.getState()

        bodyFormData.set("appId", state.application.getApp.id);
        bodyFormData.set("categoryId", props.categoryProductId);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_MARKET_ANNOUNCES,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {

                store.dispatch(unsetLoader())

                if(response.data.success){
                    return response.data.result;
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response);

                store.dispatch(unsetLoader())
            });
    } catch (e) {
        console.log(e);

        store.dispatch(unsetLoader())
    }
};

//sendAnnounceProposition
export const sendAnnounceProposition = async (props) => {
    try {
        let bodyFormData = new FormData();
        let state = store.getState()

        bodyFormData.set("appId", state.application.getApp.id);
        bodyFormData.set("mobinauteId", state.profil.mobinaute.id);
        bodyFormData.set("announceId", props.announceId);
        if(props.amount) bodyFormData.set("amount", props.amount);
        if(props.message) bodyFormData.set("message", props.message);
        if(props.state) bodyFormData.set("state", props.state);
        if(props.propositionId) bodyFormData.set("propositionId", props.propositionId);


        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_SEND_ANNOUNCE_PROPOSITION,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//getAnnouncePropositions
export const getAnnouncePropositions = async () => {
    try {
        let bodyFormData = new FormData();
        let state = store.getState()

        bodyFormData.set("appId", state.application.getApp.id);
        bodyFormData.set("mobinauteId", state.profil.mobinaute.id);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_ANNOUNCE_PROPOSITIONS,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {

                if(response.data.success){
                    return response.data.result
                }

            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//getAnnouncePropositionExchanges
export const getAnnouncePropositionExchanges = async (props) => {
    try {
        let bodyFormData = new FormData();
        let state = store.getState()

        bodyFormData.set("appId", state.application.getApp.id);
        bodyFormData.set("mobinauteId", state.profil.mobinaute.id);
        bodyFormData.set("mrktPropositionId", props.propositionId);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_ANNOUNCE_PROPOSITION_EXCHANGES,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {

                return response.data

            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

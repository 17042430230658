import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from "@material-ui/core";
import {ShapperButton} from "../components/ShapperButton/ShapperButton";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {resetAppError, requestGetContentData, resetApp} from "../actions";
import {withTranslation} from "react-i18next";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.warn("error", error);

        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.warn("error", error);
        console.warn("errorInfo", errorInfo);
    }

    handleError(e){
        e.preventDefault()

        const Error =  this.props.application.initAppError;
        const contentError = ["fetchGetContent", "fetchGetAuthentication", "fetchGetAuthenticationSSO"];

        if(contentError.includes(Error.info)){

            this.props.resetAppError();
            this.props.requestGetContentData();

            this.setState({hasError:false})
        } else {
            this.props.resetApp();

            this.setState({hasError:false})
        }
    }

    render() {

        const {t} = this.props

        if (this.state.hasError || this.props.application.initAppError.error) {

            return (
                <Dialog
                    open={true}
                    maxWidth={"sm"}
                    fullWidth={true}
                >
                    <DialogTitle>{this.state.hasError ? t('g_alert_title_err_loading') : t('loading_alert_title_err_error')}</DialogTitle>
                    <DialogContent>
                        {this.state.hasError ? "" : t('loading_alert_msg_err_retry')}
                    </DialogContent>
                    <DialogActions>
                        <ShapperButton onClick={(e)=>this.handleError(e)} >
                            {t('loading_btn_retry')}
                        </ShapperButton>
                    </DialogActions>
                </Dialog>
            )
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => ({
    application: state.application
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            resetAppError,requestGetContentData, resetApp
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ErrorBoundary);

import React from "react";
import {FormulaireDynamicNfc} from "./Formulaire.Dynamic.Nfc";
import {FormulaireDynamicQrCode} from "./Formulaire.Dynamic.QrCode";

export const FormulaireDynamicTags = (props) => {

    if(props.type === "nfc"){
        return (
            <FormulaireDynamicNfc {...props} />
        )
    } else {
        return (
            <FormulaireDynamicQrCode {...props} />
        )
    }
};

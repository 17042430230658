//Reducers for GetMarket
import { RECEIVE_GETMARKET_DATA } from "../actions/index";

const market = (state = {}, { type, data }) => {
    switch (type) {
        case RECEIVE_GETMARKET_DATA:
            return data;
        default:
            return state;
    }
};

export default market

import * as Constants from "./api.constants";

export * from './api.app'
export * from './api.connector'
export * from './api.analytics'
export * from './api.evt'
export * from './api.content'
export * from './api.form'
export * from './api.market'
export * from './api.tags'

export const protocol = window.location.protocol === "https:" ? Constants.WS_PROTOCOL_HTTPS : Constants.WS_PROTOCOL;
export const processEnv = window.location.protocol === "https:" ? process.env.REACT_APP_WS_URL_HTTPS : process.env.REACT_APP_WS_URL;
export const baseApiUrl = protocol + processEnv + Constants.WS_ENDPOINT ;
export const baseApiUrlAnalytics = protocol + process.env.REACT_APP_ANALYTICS_URL;

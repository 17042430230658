import {store} from "../store/store";
import axios from "axios";
import * as Constants from "./api.constants";
import {baseApiUrl} from "./api";

export const getTags = async () => {
    try {
        let bodyFormData = new FormData();
        let state = store.getState()

        bodyFormData.set("appId", state.application.getApp.id);
        bodyFormData.set("mobinauteId", parseInt(state.profil.mobinaute.id, 10))

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_TAGS,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

export const detachTag = async (tagId) => {
    try {
        let bodyFormData = new FormData();
        let state = store.getState()

        bodyFormData.set("appId", state.application.getApp.id);
        bodyFormData.set("tagId", tagId)

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_DETACH_TAG,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

export const attachTag = async (tagUid) => {
    try {
        let bodyFormData = new FormData();
        let state = store.getState()

        bodyFormData.set("appId", state.application.getApp.id);
        bodyFormData.set("tagUid", tagUid);
        bodyFormData.set("mobinauteId", parseInt(state.profil.mobinaute.id, 10));

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_ATTACH_TAG,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

export const tagTreatment = async (tagUid) => {
    try {
        let bodyFormData = new FormData();
        let state = store.getState()

        bodyFormData.set("appId", state.application.getApp.id);
        bodyFormData.set("tagUid", tagUid);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_TAG_TREATMENT,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

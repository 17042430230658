import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import reducer from "../reducers";
import rootSaga from "../sagas";
import { persistStore, persistReducer, createMigrate } from "redux-persist";
import localForage from 'localforage';
import {migrations} from "./migrations";

const persistConfig = {
    key: "store",
    storage: localForage,
    version: 4,
    migrate: createMigrate(migrations, { debug: true }),
}

const persistorReducer = persistReducer(persistConfig, reducer);
const sagaMiddleware = createSagaMiddleware();

export const store =  createStore(persistorReducer, applyMiddleware(sagaMiddleware));
export const persistor = persistStore(store);

// then run the saga
sagaMiddleware.run(rootSaga);

import React from "react";
import Image from "react-bootstrap/Image";
import {buildShapperPath, isNotEmpty} from "../../helpers/Tools";
import { MetroSpinner } from "react-spinners-kit";
import {useSelector} from "react-redux";
import "./shapper.loader.css"
import {Dialog, DialogContent} from "@material-ui/core";
import {Loader} from "semantic-ui-react";

export const  ShapperLoader = (props) => {
    const application = useSelector(state => state.application);
    const style = useSelector(state => state.style);

    let width = props.inner ? application.innerWidth : application.width;
    let height = props.inner ? application.innerHeight : application.height;

    if(application.getApp && isNotEmpty(style)){
        return (
            <div style={{width: width, height: height}} className="loader-container">
                {application.getApp.webParams.logo_url && (
                    <Image id="loader-logo" src={buildShapperPath(application.getApp.webParams.logo_url)} fluid/>
                )}
                <div className="spinner-container">
                    <MetroSpinner size={40} color={style.principal_color} loading={true} />
                </div>
            </div>
        )
    } else {
        return <SpinnerLoader width={width} height={height} />
    }
}

export const  SpinnerLoader = ({width, height}) => {
    return (
        <div style={{width: width, height: height}} className="loader-container">
            <div className="spinner-container">
                <MetroSpinner size={40} color={"black"} loading={true} />
            </div>
        </div>
    )
}

export const PopUpLoader = (props) => {
    return (
        <Dialog
            open={props.active}
        >
            <DialogContent style={{padding: "40px"}}>
                <Loader active={props.active} size='large' />
            </DialogContent>
        </Dialog>
    );
}

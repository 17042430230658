import React, {useState} from "react";
import {constructStripeCardUI} from "../../../../Market/Cart/Stripe/Stripe.functions"
import "../mobinaute.wallet.css"
import {ConfirmDeleteCard} from "../../Mobinaute.Wallet.popup"
import {MobinauteWalletCardElementInfo} from "./Mobinaute.Wallet.Card.Element.Info"
import {MobinauteWalletCardElementDelete} from "./Mobinaute.Wallet.Card.Element.Delete"
import {MobinauteWalletCardElementLogo} from "./Mobinaute.Wallet.Card.Element.Logo";

export const MobinauteWalletCardElement = (props) => {
    const [confirmDelete, toggleConfirmDelete] = useState(false);
    let currentCard = {};

    function closeDeletePopUp(){
        toggleConfirmDelete(false);
    }

    if(props.type === "stripe"){
        currentCard = constructStripeCardUI(props.cardData, true)
    } else if(props.type === "tag"){
        currentCard = props.cardData;
    }

    return (
        <div id="container-cards-display">
            <MobinauteWalletCardElementLogo currentCard={currentCard} type={props.type}/>

            <MobinauteWalletCardElementInfo currentCard={currentCard} type={props.type}/>

            <MobinauteWalletCardElementDelete toggleConfirmDelete={toggleConfirmDelete}/>

           <ConfirmDeleteCard isOpen={confirmDelete} onClose={closeDeletePopUp} deleteCard={props.deleteCard} cardId={currentCard.id} />
        </div>
    )
}

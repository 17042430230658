import React from "react";
import CheckCredentials from "./helpers/CheckCredentials";
import {initApp} from "./App.functions"
import i18n from './config/i18n';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {setAppIdSSO, requestGetConnectorsData, setLanguageApp, setAppSize} from "./actions";
import {ShapperLoader} from "./components/ShapperLoader/ShapperLoader";
import {HttpsRedirect} from "./helpers/HttpsProvider";

import {logout} from "./containers/NavBar/NavBar.functions"
import {registerMobinaute} from "./api/api.form"
import {store} from "./store/store";
import {sendContentStats} from "./api/api.market";

let isMounted = false;

class App extends React.Component {

     constructor(props) {
         super(props);
         this.state = {
             width: 0,
             height: 0,
             loading: true,
             getApp: null
         };
     }

     updateDimensions = () => {
         if((this.state.width !== window.innerWidth || (this.state.height !== window.innerHeight))){

             this.props.setAppSize({
                 height: window.innerHeight,
                 width: window.innerWidth
             })

             this.setState({
                 width: window.innerWidth,
                 height: window.innerHeight,
             });
         }


         //console.log("----------variableStorage----------");
         //console.log(store.getState().variableStorage);
         //console.log("----------variableStorage----------");
     };

     componentWillUnmount() {
         window.removeEventListener("resize", this.updateDimensions);
         isMounted = false;
     }

     componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
         this.updateDimensions();
     }

    async componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        isMounted = true;

        //recup language actuel & set language trad
        this.props.setLanguageApp(navigator.language)
        let lng = navigator.language.substr(0,2).toLowerCase();

        if(lng !== i18n.language){
            await i18n.changeLanguage(lng);
        }

        if(!this.props.application.initAppError.error){

            let getApp = await initApp();

            if(getApp && getApp.success){
                if(isMounted) await this.setState({loading:false, getApp:getApp})
            }

        }

    }

    render() {

        if(this.state.loading){

            return <ShapperLoader />

        } else if(this.state.getApp && this.state.getApp.success) {

            return (
                <HttpsRedirect force_https={this.state.getApp.result.webParams.force_https}>
                    <CheckCredentials />
                </HttpsRedirect>
            )

        } else {

            return <ShapperLoader />

        }
    }
}

const mapStateToProps = (state) => ({
    application: state.application
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setAppIdSSO,
            requestGetConnectorsData,
            setLanguageApp,
            setAppSize
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(App);

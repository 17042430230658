import React from "react";
import TextField from "@material-ui/core/TextField/TextField";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckIcon from '@material-ui/icons/Check';
import "../Formulaire.css"
import {CircularProgress} from "@material-ui/core";

export const FormulaireDynamicNfc = (props) => {
    return (
        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
            <TextField
                noValidate
                required={props.required}
                disabled={props.disabled || props.formState['v_' + props.res.id+"_loading"]}
                variant="outlined"
                margin="normal"
                fullWidth
                id={props.res.id}
                name={'v_' + props.res.id}
                type="text"
                placeholder={props.res.placeholder_message}
                value={props.formState['v_' + props.res.id] ? props.formState['v_' + props.res.id] : ""}
                onChange={props.handleChange}
                error={props.error}
                label={props.label}
                inputProps={{
                    is_interpret: props.res.interpret,
                    ...props.inputProps
                }}
                style={{marginRight: "8px"}}
            />

            {props.formState['v_' + props.res.id+"_loading"] && (
                <CircularProgress size={25} style={{color:"black"}}/>
            )}

            {(!props.formState['v_' + props.res.id+"_loading"] && props.res.interpret === "1" && !props.formState['v_' + props.res.id+"_treated"])  && (
                <AutorenewIcon className="form-action-icons" onClick={() => props.handleTagTreatment(props.formState['v_' + props.res.id], props.res.id)}/>
            )}

            {(! props.formState['v_' + props.res.id+"_loading"] && props.res.interpret === "1" && props.formState['v_' + props.res.id+"_treated"])  && (
                <CheckIcon className="form-action-icons" />
            )}
        </div>
    )
}

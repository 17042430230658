import {getOS} from "../../../helpers/Tools";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {NavItem} from "reactstrap";
import React from "react";
import {useSelector} from "react-redux";

export const NavBarIdentificationNotConnected = (props) => {
    const style = useSelector(state => state.style);
    const PathIdentification = useSelector(state => state.routes.PathIdentification);

    if (getOS() === 'web_ios' || getOS() === 'web_android' || props.width <= 800) {
        // Mode Portrait
        return (
            <Button style={{ display: "inherit" }} onClick={props.closeMenu} >
                <div className="fa fa-lock" style={{color: style.menu.icon_color, marginRight: "3px", fontSize: style.menu.fontSize+"px", width: style.menu.fontSize+"px"}}/>
                <Link
                    style={{
                        textAlign: "left",
                        paddingLeft: "5px",
                        textTransform : "none",
                        fontSize: style.menu.fontSize + "px",
                        fontFamily: style.menu.text_font,
                        color: style.menu.text_color,
                        width: "100%",
                        height: "100%",
                    }}
                    to={PathIdentification ? PathIdentification : "/"}
                >
                    {props.identCompElement.title}
                </Link>
            </Button>
        );
    } else {
        // Mode Paysage
        return (
            <NavItem style={{ listStyle: "none", textAlign: "center" }}>
                <Button style={{backgroundColor: 'transparent', height:"100%"}}>
                    <Link
                        style={{ color: style.menu.text_color, textDecoration: "none", borderLeft: "1px solid #D3D3D3", paddingLeft: "30px"}}
                        to={PathIdentification ? PathIdentification : "/"}
                    >
                        <p style={{marginTop:"1rem", marginBottom:"1rem", color: style.menu.text_color_selected}}
                           onMouseEnter={(e) => e.target.style.color = style.menu.text_color}
                           onMouseLeave={(e) => e.target.style.color = style.menu.text_color_selected}
                        >
                            {props.identCompElement.title}
                        </p>
                    </Link>
                </Button>
            </NavItem>
        );
    }
}

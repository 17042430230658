import {ADD_VARIABLE_DATA, CLEAR_VARIABLE_DATA} from "../actions/index";


const variableStorageReducer = (state= {}, action) => {
    switch (action.type) {
        case ADD_VARIABLE_DATA:
            return {...state,  ...action.payload};
        case CLEAR_VARIABLE_DATA:
            return {};
        default:
            return state
    }
}

export default variableStorageReducer;

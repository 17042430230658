import { combineReducers } from "redux";

import content from "./content.reducer";
import contentauth from "./contentauth.reducer";
import contentauthSso from "./contentauthSso.reducer";
import cart from "./cart.reducer";
import plannings from "./plannings.reducer";
import connectors from "./connectors.reducer";
import market from "./market.reducer";
import variableStorage from "./variableStorage.reducer";
import playlistPlayer from "./playlist.reducer";
import style from "./style.reducer";
import application from "./app.reducer";
import profil from "./profil.reducer";
import routes from "./routes.reducer";
import announces from "./announces.reducer";
import loader from "./loader.reducer";

//Get All the reducers
export default combineReducers({
    contentauthSso,
    contentauth,
    content,
    cart,
    plannings,
    connectors,
    market,
    variableStorage,
    playlistPlayer,
    style,
    application,
    profil,
    routes,
    announces,
    loader
});


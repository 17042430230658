import React from "react";
import "./Signature/sigCanvas.css";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {ShapperButton} from "../../components/ShapperButton/ShapperButton";

class FormulaireButton extends React.Component{

    render() {

        const dyn_string  = this.props.t;

        switch (this.props.typeForm) {
            case "ProfilForm":
                return (
                    <ShapperButton
                        fullWidth
                        onClick={e => this.props.onSubmitProfil(e)}
                        style={{marginTop:"20px", marginBottom:"20px"}}
                    >
                        {dyn_string('form_btn_send')}
                    </ShapperButton>
                )
            case "RegisterForm":
                let textToSubscribe;
                if(Object.keys(this.props.profil).length !== 0){
                    if (this.props.profil.profil_form.label_validation_button === null || this.props.profil.profil_form.label_validation_button === undefined){
                        textToSubscribe = dyn_string('profile_title_signin');
                    } else {
                        textToSubscribe = this.props.profil.profil_form.label_validation_button;
                    }
                } else {
                    textToSubscribe = dyn_string('profile_title_signin');
                }

                return(
                    <ShapperButton
                        fullWidth
                        onClick={e => this.props.onSubmitRegister(e)}
                        style={{marginTop:"20px"}}
                    >
                        {textToSubscribe}
                    </ShapperButton>
                )
            case "event_creation":
                let validation = "";

                if(this.props.formInfo){
                    validation = this.props.formInfo.label_validation_button || dyn_string('form_btn_send');
                } else if(this.props.dataPage && this.props.dataPage.form && this.props.dataPage.form.validation_text){
                    validation = this.props.dataPage.form.validation_text || dyn_string('form_btn_send');
                } else {
                    validation = dyn_string('form_btn_send')
                }

                return (
                    <ShapperButton
                        fullWidth
                        onClick={e => this.props.onSubmitDynamicForm(e)}
                        style={{marginTop:"20px", marginBottom:"20px"}}
                    >
                        {validation}
                    </ShapperButton>
                )
            case "event_update":
                return (
                    <ShapperButton
                        fullWidth
                        disabled={this.props.formState.disabled}
                        onClick={(e) => {this.props.setUpdateEvent(e)}}
                        style={{marginTop:"20px", marginBottom:"20px"}}
                    >
                        {dyn_string('planning_btn_create_event_validate')}
                    </ShapperButton>
                )
            case "market":
                return (
                    <ShapperButton
                        fullWidth
                        disabled={this.props.formState.disabled}
                        onClick={ (e) => this.props.onSubmitCreateOrder(e)}
                        style={{marginTop:"20px", marginBottom:"20px"}}
                    >
                        {this.props.formInfo.label_validation_button || dyn_string('form_btn_send')}
                    </ShapperButton>
                )
            case "dynamic":
            case "local":
            case "post_data":
                return (
                    <ShapperButton
                        fullWidth
                        disabled={this.props.formState.disabled}
                        onClick={(e) => {this.props.onSubmitDynamicForm(e)}}
                        style={{marginTop:"20px", marginBottom:"20px"}}
                    >
                        {this.props.dataPage.form.label_validation_button}
                    </ShapperButton>
                )
            case "announce":
                return (
                    <ShapperButton
                        fullWidth
                        disabled={this.props.formState.disabled}
                        onClick={(e) => {this.props.setAnnounce(e)}}
                        style={{marginTop:"20px", marginBottom:"20px"}}
                    >
                        {this.props.market.result.form_announce.label_validation_button}
                    </ShapperButton>
                )
            default:
                return ""
        }
    }
}

const mapStateToProps = (state) => ({
    styles: state.style,
    application: state.application,
    profil: state.profil,
    market: state.market
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(FormulaireButton);

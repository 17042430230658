import {ADD_STYLE} from "../actions/index";


const style = (state= {}, action) => {
    switch (action.type) {
        case ADD_STYLE:
            return action.payload;
        default:
            return state
    }
}

export default style;

import React from "react";
import {Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import MediaQuery from 'react-responsive'
import {pushHistory} from "../../../helpers/Tools";

export const CartProduct = (props) => {
    const name = props.product.variation.id
    const min = parseFloat(props.product.product.min_quantity)
    const max = parseFloat(props.product.product.max_quantity)
    const step = parseFloat(props.product.product.quantity_step)

    let product_title = props.product.attributs_title ? props.product.product.title + " - " + props.product.attributs_title : props.product.product.title;
    product_title = props.product.product.quantity_unit ? product_title +" ("+props.product.product.quantity_unit+")" : product_title;

    const redirectToProduct = () => {
        if(props.allowUpdate)
            pushHistory(props.product.url)
    }

    const increaseQuantity = () => {
        let value = parseFloat(props.value)

        if(value < max){
            props.handleChange({
                name: name,
                value: parseFloat(props.value) + step
            })
        }
    }

    const decreaseQuantity = () => {
        let value = parseFloat(props.value)

        if(value > min){
            props.handleChange({
                name: name,
                value: parseFloat(props.value) - step
            })
        }
    }

    return (
        <>
            {props.displayOrderSummary && <Divider variant="middle" />}
            <List>
                <MediaQuery minDeviceWidth={500}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar variant="square" src={props.url_image} style={{maxWidth:'100%', borderRadius: "0.5rem", cursor: "pointer"}} onClick={() => redirectToProduct}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={product_title}
                            secondary={props.product.product.short_description}
                            style={{ color:'black', cursor: "pointer" }}
                            onClick={() => redirectToProduct()}
                        />

                        <Typography variant="body1" color="textPrimary" component="p" style={{margin:"0 10%"}}>{props.price_ttc} €</Typography>

                        {props.allowUpdate ?
                            (
                                <>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{cursor: "pointer"}}>
                                        <RemoveCircleOutlineIcon onClick={() => decreaseQuantity()} style={{marginRight: "8px"}} />
                                        {props.value}
                                        <AddCircleOutlineIcon onClick={() => increaseQuantity()} style={{marginLeft: "8px"}} />
                                    </Typography>

                                    <Typography variant="body2" color="textSecondary" component="p" style={{marginLeft: "10%", cursor: "pointer"}}>
                                        <DeleteOutlineIcon onClick={() => props.handleDelete(props.variationId)} />
                                    </Typography>
                                </>
                            ) : ""
                        }
                    </ListItem>
                </MediaQuery>

                <MediaQuery maxDeviceWidth={800}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar variant="square" src={props.url_image} style={{maxWidth:'100%', borderRadius: "0.5rem", cursor: "pointer"}} onClick={() => redirectToProduct}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary=
                                {
                                    <div>
                                        <Typography type="body1" color="textPrimary" component="p">{product_title}</Typography>
                                        <Typography variant="body1" color="textPrimary" component="p">{props.price_ttc} €</Typography>
                                    </div>
                                }
                            secondary={props.product.product.short_description}
                            style={{ color:'black', cursor: "pointer" }}
                            onClick={() => redirectToProduct()}
                        />

                        {props.allowUpdate ?
                            (
                                <>
                                    <Typography variant="body2" color="textSecondary" component="p" style={{cursor: "pointer"}}>
                                        <RemoveCircleOutlineIcon onClick={() => decreaseQuantity()} style={{marginRight: "8px"}} />
                                        {props.value}
                                        <AddCircleOutlineIcon onClick={() => increaseQuantity()} style={{marginLeft: "8px"}} />
                                    </Typography>

                                    <Typography variant="body2" color="textSecondary" component="p" style={{marginLeft: "10%", cursor: "pointer"}}>
                                        <DeleteOutlineIcon onClick={() => props.handleDelete(props.variationId)} />
                                    </Typography>
                                </>
                            ) : ""
                        }
                    </ListItem>
                </MediaQuery>
            </List>
            {!props.displayOrderSummary && <Divider variant="middle" />}
        </>
    )
}

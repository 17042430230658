import {CardElement ,useElements, useStripe} from "@stripe/react-stripe-js";
import {useTranslation} from "react-i18next";
import React, {useState, useEffect} from "react";
import "./stripe.css";
import {Loader} from "semantic-ui-react";
import {StripeCardCheckBox} from './Stripe.Card.CheckBox'
import {ErrorMessage} from "../../../../components/ErrorMessage/Error.Message";
import {StripeValidate} from './Stripe.Validate'
import {StripeButtons} from './Stripe.Buttons'
import {StripeCards} from './Stripe.Cards'
import {Divider, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import MediaQuery from 'react-responsive'
import {constructStripeCardUI} from "./Stripe.functions";
import {ShapperButton} from "../../../../components/ShapperButton/ShapperButton";

export const CheckoutForm = (props) => {

    console.log("CheckoutForm props => " + JSON.stringify(props));

    let stripe = useStripe();
    let elements = useElements();
    let { t } = useTranslation();
    let [error, setError] = useState(null);
    let [cardComplete, setCardComplete] = useState(false);
    let [loading, setLoading] = useState(false);
    let [terminateOrder, setTerminateOrder] = useState(false);

    //let [clientSecret] = useState(props.stripeData.clientSecret);
    //let [clientIntentSecret] = useState(props.stripeData.clientIntentSecret);


    const [clientSecret, setClientSecret] = useState(props.stripeData.clientSecret);
    const [clientIntentSecret, setClientIntentSecret] = useState(props.stripeData.clientIntentSecret);


    let [paymentMethods, setPaymentMethods] = useState(props.stripeData.cards.data ? props.stripeData.cards.data : []);
    let [createCard, authCreateCard] = useState(true);
    let [displayPickUpElement, setDisplayPickUpElement] = useState(false);
    let [displayCardElement, setDisplayCardElement] = useState(paymentMethods.length <= 0);
    let [paymentMethodSelected, setPaymentMethodSelected] = useState(paymentMethods.length > 0 ? constructStripeCardUI(paymentMethods[0]) : null);
    let [cardHolder, setCardHolder] = useState("");

    console.log("Stripe CheckoutForm clientIntentSecret => " + clientIntentSecret);
    console.log("Stripe CheckoutForm clientSecret => " + clientSecret);

    // Mettez à jour clientIntentSecret si props.stripeData.clientIntentSecret change.
    if (clientIntentSecret !== props.stripeData.clientIntentSecret) {
        setClientIntentSecret(props.stripeData.clientIntentSecret);
    }

    // Mettez à jour clientSecret si props.stripeData.clientSecret change.
    if (clientSecret !== props.stripeData.clientSecret) {
        setClientSecret(props.stripeData.clientSecret);
    }

    const allowCreateCard = async (event) => {
        const check = event.target.checked;
        authCreateCard(check);
    }

    function hydratePaymentMethods(pmId){
        let indexToRemove = paymentMethods.findIndex(element => element.id === pmId);

        if(indexToRemove !== -1){
            if(paymentMethods.length === 1){
                setDisplayCardElement(true)
                setDisplayPickUpElement(false)
                setPaymentMethodSelected(null)
            }

            setPaymentMethods([
                ...paymentMethods.slice(0, indexToRemove),
                ...paymentMethods.slice(indexToRemove + 1)
            ])
        }
    }

    function handleCardChoice(paymentMethod){
        setPaymentMethodSelected(constructStripeCardUI(paymentMethod));
        setDisplayPickUpElement(false)
    }

    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            console.log(" Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.")
            return;
        }

        if((cardComplete) || paymentMethodSelected){
            if(cardComplete && !cardHolder){
                setError({message: t('market_alert_msg_err_fill_card_holder')});
            } else {
                setLoading(true)

                if(props.handleBlur) props.handleBlur(true)

                setError(null);

                let currentCard = cardComplete ? elements.getElement(CardElement) : null ;
                let payment;

                if(cardComplete){
                    payment = {
                        card: currentCard,
                        billing_details: {
                            name: cardHolder,
                        }
                    }
                } else {
                    payment = paymentMethodSelected.id
                }

                if(createCard && cardComplete){

                    console.log("Stripe handleSubmit clientIntentSecret => " + clientIntentSecret);
                    console.log("Stripe handleSubmit clientSecret => " + clientSecret);

                    await stripe.confirmCardSetup(clientIntentSecret, {
                        payment_method: {
                            card: currentCard,
                            billing_details: {
                                name: cardHolder,
                            }
                        },
                    })
                }

                let confirmPayment;
                try {
                    confirmPayment = await stripe.confirmCardPayment(clientSecret, {
                        payment_method: payment
                    })
                } catch (e) {
                    setError({message: t('market_alert_msg_err_validate_order')});
                }

                if(confirmPayment){
                    if(confirmPayment.paymentIntent){

                        setTerminateOrder(true)

                        if(props.handleBlur) props.handleBlur(true)

                    } else if(confirmPayment.error) {

                        setError(confirmPayment.error);

                        if(props.handleBlur) props.handleBlur(false)

                    }
                }

                setLoading(false)
            }
        } else {
            setError({message: t('market_alert_msg_err_fill_card')});
        }
    };

    if(terminateOrder && props.type === "announce"){
        return (
            <React.Fragment>
                <h4>Opération validée !</h4>

                <div style={{display:"flex", justifyContent:"end"}}>
                    <ShapperButton onClick={() => props.onClose()}>
                        Fermer
                    </ShapperButton>
                </div>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Loader active={loading} size='large' />

            <form className="Form" onSubmit={handleSubmit} style={{opacity: loading || terminateOrder ? 0 : 1}}>
                <MediaQuery maxDeviceWidth={800}>
                    <Divider variant="middle" style={{margin: "0 0 20px"}}/>
                </MediaQuery>

                {displayCardElement ?
                    (
                        <>
                            <div className="CardHolder">
                                <h4 style={{margin: "0px"}}>{t('market_label_cart_holder_name')}</h4>
                                <fieldset className="FormGroup">
                                    <div className="FormRow">
                                        <input
                                            className="FormRowInput"
                                            id="cardHolder"
                                            type="text"
                                            placeholder={t('market_label_cart_holder_placeholder')}
                                            value={cardHolder}
                                            onChange={(e) => {setCardHolder(e.target.value)}}
                                        />
                                    </div>
                                </fieldset>
                            </div>

                            <div className="CardDetail">
                                <h4 style={{margin: "0px"}}>{t('market_label_cart_details')}</h4>
                                <fieldset className="FormGroup">
                                    <CardElement
                                        options={{style: {base: {fontSize: '16px',color: 'grey', '::placeholder': {color: '#aab7c4',},}, invalid: {color: 'red',},},}}
                                        onChange={(e) => {
                                            setError(e.error);
                                            setCardComplete(e.complete);
                                        }}
                                    />
                                </fieldset>
                            </div>

                            <StripeCardCheckBox createCard={createCard} allowCreateCard={allowCreateCard} />
                        </>
                    ) : ""
                }

                {paymentMethodSelected && !displayCardElement ?
                    (
                        <div id="CurrentPaymentMethod">
                            <h4 style={{margin: "0px"}}>{t('market_title_payment_method')}</h4>
                            <List className="CurrentPaymentMethod" onClick={() => setDisplayPickUpElement(true)}>
                                <ListItem button>
                                    <ListItemIcon >
                                        <div className={paymentMethodSelected.className}/>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={paymentMethodSelected.label}
                                        secondary={"... " + paymentMethodSelected.last4}
                                        style={{ color:'black' }}
                                    />

                                    <i className="fa fa-edit PickUpIcon" style={{cursor: "pointer", marginLeft:"8px", color:"grey"}}/>
                                </ListItem>
                            </List>
                        </div>
                    ) : ""
                }

                {error && <ErrorMessage>{error.message}</ErrorMessage>}

                <StripeButtons cancelOrder={props.cancelOrder} disabled={!stripe}/>

            </form>

            {props.type !== "announce" && (
                <StripeValidate isOpen={terminateOrder} />
            )}

            <StripeCards
                isOpen={displayPickUpElement}
                setDisplayPickUpElement={setDisplayPickUpElement}
                paymentMethods={paymentMethods}
                paymentMethodSelected={paymentMethodSelected}
                handleCardChoice={handleCardChoice}
                hydratePaymentMethods={hydratePaymentMethods}
                setDisplayCardElement={setDisplayCardElement}
                setPaymentMethodSelected={setPaymentMethodSelected}
            />
        </React.Fragment>
    )
};

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addVariableData, setPageTitle, setPageId, setIsIdentComp, setElementType} from "../actions";
import {Link, Route} from "react-router-dom";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import {ShapperButton} from "../components/ShapperButton/ShapperButton";

/**
 * Création & administration des routes
 */
const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);

    return (
        React.createElement(component, finalProps)
    );
}
export const PropsRoute = ({component, ...rest}) => {
    const [restriction, setRestriction] = useState(false);
    const [dialog_PNL, setDialogPNL] = useState(false);
    const dispatch = useDispatch();
    const titlePage = useSelector(state => state.application.title);
    const typeElement = useSelector(state => state.application.type);
    const idPage = useSelector(state => state.application.pageId);
    const isIdentComp = useSelector(state => state.application.isIdentComp);
    const PathIdentification = useSelector(state => state.routes.PathIdentification);
    const { t } = useTranslation();

    useEffect(() =>{
        const isIdentification = {...rest}.path === PathIdentification;
        let newTitle = isIdentification ? {...rest}.dataPage.subtitle : {...rest}.dataPage.title;

        if(newTitle !== titlePage){
            dispatch(setPageTitle({title: newTitle}))
        }

        if({...rest}.dataPage.type !== typeElement){
            dispatch(setElementType({type: {...rest}.dataPage.type}))
        }

        if(isIdentification !== isIdentComp){
            dispatch(setIsIdentComp({isIdentComp: isIdentification}))
        }

        if({...rest}.dataPage.id !== idPage && {...rest}.dataPage.type !== "identification")
            dispatch(setPageId({id: {...rest}.dataPage.id})) //stockage de l'id du content pour possible redirection

        if({...rest}.dataPage && {...rest}.dataPage.list_variables){
            dispatch(addVariableData({...rest}.dataPage.list_variables)) // Permet de dispatch les nouvelles variables dans le store redux
        }

        if(
            (
                {...rest}.dataPage.accessibility === "protege"
                || {...rest}.dataPage.accessibility === 'protege'
                || {...rest}.dataPage.type === "order_history"
                || {...rest}.dataPage.type === "planning_mobinaute"
                || {...rest}.dataPage.type === "wallet_stripe"
                || {...rest}.dataPage.type === "wallet"
                || {...rest}.dataPage.type === "list_announce_mobinaute"
            ) && !{...rest}.isConnected
        ){
            setRestriction(true);
            setDialogPNL(true);
        }

    }, [rest, dispatch, titlePage, idPage, t, PathIdentification, isIdentComp, typeElement])

    let protect = (
        <Dialog
            open={dialog_PNL}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>{t('auth_alert_title_info_protected_content')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {{...rest}.isConnected ? t('auth_btn_content_limited') : t('auth_alert_msg_info_protected_content')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Link to={{pathname: {...rest}.isConnected ? "/" : PathIdentification}}>
                    <ShapperButton onClick={() => {setDialogPNL(false)}}>
                        {{...rest}.isConnected ? t('g_btn_ok') : t('auth_btn_login')}
                    </ShapperButton>
                </Link>
            </DialogActions>
        </Dialog>
    );

    if(restriction){
        return <>
            {protect}
        </>
    } else {
        return (
            <Route {...rest} render={routeProps => {
                return renderMergedProps(component, routeProps, rest);
            }}/>
        );
    }
}

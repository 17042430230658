import React  from "react";
import {Container, IconButton} from "@material-ui/core";
import {Col, ListGroup, Row} from "react-bootstrap";
import OpenItem from "../../../helpers/OpenItem";
import PlaceTwoToneIcon from "@material-ui/icons/PlaceTwoTone";
import NearMeTwoToneIcon from "@material-ui/icons/NearMeTwoTone";
import {constructLinkDestination} from "../../../helpers/Tools";

export const ListMapItem = (props) => {

    const item = props.item;
    const destination_link = constructLinkDestination(item)

    return (
        <React.Fragment>
            <ListGroup.Item id="list-map-item-container">
                <Container>
                    <Row>
                        <Col id="list-map-item-body">
                            <div id="destination-info">
                                <OpenItem contentToRedirect={item.id} isFor={'listMap'}>
                                    <div className="d-flex w-100 justify-content-between" style={{ paddingLeft: "10px" }}>
                                        <h6 className="mb-1">{item.title}</h6>
                                    </div>

                                    <p className="mb-1" style={{ paddingLeft: "10px" }}>
                                        {item.subtitle}
                                    </p>
                                </OpenItem>
                            </div>
                            <div id="show-distance">
                                {item.distanceToCustomer && (
                                    <small>
                                        {item.distanceToCustomer + " km"}
                                    </small>
                                )}
                            </div>
                        </Col>
                        <Col id="list-map-item-buttons" xs md lg={3}>
                            <IconButton component="span" onClick={() => props.onMarkerClick(item.id)} id="list-map-item-button">
                                <PlaceTwoToneIcon />
                            </IconButton>
                            <IconButton component="span" id="list-map-item-button">
                                <a target="_blank" rel="noopener noreferrer" href={destination_link} style={{textDecoration:"none", color:"grey"}}>
                                    <NearMeTwoToneIcon />
                                </a>
                            </IconButton>
                        </Col>
                    </Row>
                </Container>
            </ListGroup.Item>
        </React.Fragment>
    );
}

import React from "react";
import {constructExternalLink, constructLinkDestination, prettyPrice} from "../../helpers/Tools";
import {SegmentedBar, SegmentedBarButton} from "../../components/SegmentedBar";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export const PageHeaderButtons = (props) => {

    const {dataPage} = props;
    const { t } = useTranslation();
    const style = useSelector(state => state.style);
    let address = constructAddressLink(dataPage);

    return (
        <SegmentedBar>
            {dataPage.contact_phone && (
                <SegmentedBarButton href={"tel:" + dataPage.contact_phone} icon={"fa fa-phone"}  >
                    {t('buttonbar_label_call')}
                </SegmentedBarButton>
            )}

            {dataPage.contact_mail && (
                <SegmentedBarButton href={"mailTo:" + dataPage.contact_mail} icon={"fa fa-envelope"}  >
                    {t('buttonbar_label_mail')}
                </SegmentedBarButton>
            )}

            {dataPage.web_url && (
                <SegmentedBarButton href={constructExternalLink(dataPage.web_url)} icon={"fa fa-globe"}  target={"_blank"}>
                    {t('buttonbar_label_web')}
                </SegmentedBarButton>
            )}

            {address && (
                <SegmentedBarButton href={address} icon={"fa fa-location-arrow"}  target={"_blank"}>
                    {t('buttonbar_label_location')}
                </SegmentedBarButton>
            )}

            {dataPage.type === "event" && (
                <SegmentedBarButton href="#!" icon={"fa fa-users"} onClick={props.displayGuest}>
                    {t('planning_label_participants')}
                </SegmentedBarButton>
            )}

            {dataPage.type === "announce" && (
                <div style={{padding: "1rem",width:"100%", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                    <span style={{color: style.segmentedbar.icon_color, fontSize: style.segmentedbar.fontSize}}>
                        {dataPage.created_at_string}
                    </span>

                    <span style={{color: style.segmentedbar.icon_color, fontSize: style.segmentedbar.fontSize}}>
                        {prettyPrice(dataPage.price_ttc)} €
                    </span>
                </div>
            )}

        </SegmentedBar>
    )
}

const constructAddressLink = (dataPage) => {
    let address = {}

    if(dataPage.address_lat && dataPage.address_lng){
        address.address_lat = dataPage.address_lat;
        address.address_lng = dataPage.address_lng;
    }

    if(dataPage.address_city && dataPage.address_street1 && dataPage.address_zip && dataPage.address_country){
        address.address_city = dataPage.address_city;
        address.address_street1 = dataPage.address_street1;
        address.address_zip = dataPage.address_zip;
        address.address_country = dataPage.address_country;
    }

    return constructLinkDestination(address);
}

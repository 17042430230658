
import React from "react";
import {NavBarIdentificationConnected} from "./NavBar.Identification.Connected";
import {NavBarIdentificationNotConnected} from "./NavBar.Identification.Not.Connected";

export const NavBarIdentification = (props) => {
    if (!props.connected) {
        return (
            <NavBarIdentificationNotConnected
                closeMenu={props.closeMenu}
                identCompElement={props.identCompElement}
                width={props.width}
            />
        )
    } else {
        return (
            <NavBarIdentificationConnected
                closeMenu={props.closeMenu}
                identCompElement={props.identCompElement}
                width={props.width}
                height={props.height}
            />
        )
    }
}

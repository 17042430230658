import React from "react";
import "./Signature/sigCanvas.css";
import TextField from "@material-ui/core/TextField/TextField";
import {bindActionCreators} from "redux";
import {addVariableData, requestGetPlanningsData} from "../../actions";
import {connect} from "react-redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class FormulaireRegister extends React.Component{
    render() {
        const dyn_string  = this.props.t;

        let label = {};
        let errorRegister = {};

        Object.entries(this.props.formData).forEach(([res]) => {
            if(this.props.formState[res+"error"]){
                label[res] = this.props.formState[res+"error"]
                errorRegister[res] = true;
            }else{
                label[res] = res;
                errorRegister[res] = false;
            }
        })

        let display_login_field;

        if(this.props.application.getApp.is_mail_login === "0"){
            display_login_field = <TextField
                key={"RegisterForm_login"}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="login"
                autoComplete="login"
                type="text"
                placeholder={dyn_string('profile_placeholder_login')}
                value={this.props.formState.value}
                onChange={this.props.handleChange}
                label={dyn_string('profile_label_login')}
                error={errorRegister["login"]}
            />
        } else {
            display_login_field = ""
        }

        return (
            <>
                <TextField
                    key={"RegisterForm_firstname"}
                    aria-required={true}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    autoComplete="firstname"
                    name="firstname"
                    type="text"
                    placeholder={dyn_string('profile_label_fistname')}
                    value={this.props.formState.value}
                    onChange={this.props.handleChange}
                    label={dyn_string('profile_label_fistname')}
                    error={errorRegister["firstname"]}
                />
                <TextField
                    key={"RegisterForm_lastname"}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    autoComplete="lastname"
                    name="lastname"
                    type="text"
                    placeholder={dyn_string('profile_label_lastname')}
                    value={this.props.formState.value}
                    onChange={this.props.handleChange}
                    label={dyn_string('profile_label_lastname')}
                    error={errorRegister["lastname"]}
                />

                {display_login_field}

                <TextField
                    key={"RegisterForm_email"}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="Email"
                    autoComplete="Email"
                    name="Email"
                    type="mail"
                    placeholder={dyn_string('profile_placeholder_mail')}
                    value={this.props.formState.value}
                    onChange={this.props.handleChange}
                    label={label["Email"]?label["Email"]:dyn_string('profile_label_mail')}
                    error={errorRegister["Email"]}
                />
                <TextField
                    key={"RegisterForm_password"}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    autoComplete="password"
                    name="password"
                    type="password"
                    placeholder={dyn_string('profile_placeholder_password')}
                    value={this.props.formState.value}
                    onChange={this.props.handleChange}
                    label={dyn_string('profile_label_password')}
                    error={errorRegister["pass"]}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    variableStorage: state.variableStorage,
    styles: state.style,
    application: state.application,
    profil: state.profil,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addVariableData,
            requestGetPlanningsData,
        },
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(FormulaireRegister);

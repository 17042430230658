//Reducers for GetPlannings
import { RECEIVE_GETPLANNINGS_DATA } from "../actions/index";

const plannings = (state = {}, { type, data }) => {
    switch (type) {
        case RECEIVE_GETPLANNINGS_DATA:
            return data;
        default:
            return state;
    }
};

export default plannings

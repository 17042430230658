import {
    RECEIVE_GET_ANNOUNCES_DATA,
    RECEIVE_GET_ANNOUNCES_PROPOSITIONS,
    RESET_ANNOUNCES_LIST,
    RESET_ANNOUNCES_PROPOSITIONS
} from "../actions/index";

const initState = {
    list:[],
    published_announces:[],
    interested_announces:[]
}

const announces = (state = initState, { type, data }) => {

    switch (type) {
        case RECEIVE_GET_ANNOUNCES_DATA:
            return {
                ...state,
                list: data
            };
        case RESET_ANNOUNCES_LIST:
            return {
                ...state,
                list: []
            }
        case RECEIVE_GET_ANNOUNCES_PROPOSITIONS:
            return {
                ...state,
                published_announces: data.published_announces,
                interested_announces: data.interested_announces,
            };
        case RESET_ANNOUNCES_PROPOSITIONS:
            return {
                ...state,
                published_announces: [],
                interested_announces: [],
            }
        default:
            return state;
    }
};

export default announces

import React, {useEffect, useState} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import "./Pdf.css";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import GetAppIcon from '@material-ui/icons/GetApp';
import {AppBar, Toolbar, IconButton} from '@material-ui/core';
import {Col} from "react-bootstrap";
import {MetroSpinner} from "react-spinners-kit";
import {useTranslation} from "react-i18next";
import {buildShapperPath} from "../../../helpers/Tools"
import {useSelector} from "react-redux";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Pdf(props) {
    const application = useSelector(state => state.application);
    const [totalPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    let maxWidth = application.getApp.webParams.content_max_width
    let initialWidth =(maxWidth && window.innerWidth >= maxWidth) ? parseFloat(maxWidth) : window.innerWidth;
    const [width, setWidth] = useState(initialWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [errorLoading, displayErrorLoading] = useState(false);
    const [loading, handleLoading] = useState(true);
    const [url] = useState(buildShapperPath(props.dataPage.media_url))
    const { t } = useTranslation();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        handleLoading(false)
    }

    function onDocumentLoadError(error) {
        console.log(error)
        handleLoading(false)
        displayErrorLoading(true);
    }

    function updateDimensions(maxWidth){
        setWidth((maxWidth && window.innerWidth >= maxWidth) ? parseFloat(maxWidth) : window.innerWidth);
        setHeight(window.innerWidth < 800 ? window.innerHeight : window.innerHeight - 200);
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions(maxWidth));

        console.log(" %c ////////CONTENU-PDF////////",  'background: #222; color: #bada55');

        // returned function will be called on component unmount
        return () => {
            window.removeEventListener("resize", updateDimensions(maxWidth));
        }
    }, [maxWidth])

    if(errorLoading){
        return (
            <div style={{textAlign: "center"}}>{t('loading_alert_msg_err_retry')}</div>
        );
    } else {

        let items = [];

        for (let index = 1; index<=totalPages; index++) {
            items.push(<Page
                pageNumber={index}
                width={width * (2 / 3)}
                height={height}
                className={"page_display"}
                loading={<div style={{marginTop: "20px"}}><MetroSpinner size={40} color={"black"}loading={true}/></div>}
            />)
        }

        return (
            <div className="pdf-container" style={{height: height}}>
                { !loading &&
                    <AppBar position="static" className="app-bar">
                        <Toolbar>
                            <Col md={11} sm={11} className="navigation">

                            </Col>
                            <Col md={1} sm={1} className="download">
                                <a href={url} className="download" target="_blank" rel="noopener noreferrer">
                                    <GetAppIcon />
                                </a>
                            </Col>
                        </Toolbar>
                    </AppBar>
                }

                <Document
                    file={url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                    className={"document_display"}
                    loading={<div style={{marginTop: "20px"}}><MetroSpinner size={40} color={"black"} loading={true} /></div>}
                >

                    {items}

                </Document>
            </div>
        );
    }
}

import React from "react";
import "../mobinaute.wallet.css"
import NfcIcon from '@material-ui/icons/Nfc';

export const MobinauteWalletCardElementLogo = (props) => {
    if(props.type === "stripe"){
        return <div id="card-logo" className={props.currentCard.className}/>
    } else if(props.type === "tag"){
        return props.currentCard.tag_type === "qrcode" ? <div id="card-logo" className="fa fa-qrcode"/> : <div><NfcIcon id="card-logo-nfc"/></div>;
    } else {
        return <div id="card-logo" className="fa fa-credit-card"/>
    }
}

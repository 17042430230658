import {
    RECEIVE_GETCONTENT_DATA,
    NEW_DATA_CONTENT_FROM_CONNECTOR,
    REQUEST_GETCONTENT_DATA,
    RESET_GETCONTENT_DATA
} from "../actions/index";

const content = (state = {loading: false}, action) => {
    switch (action.type) {
        case REQUEST_GETCONTENT_DATA:
            return {
                loading: true
            }
        case RECEIVE_GETCONTENT_DATA:
            return action.payload;
        case NEW_DATA_CONTENT_FROM_CONNECTOR:
            return {
                ...state,
                result: action.payload
            }
        case RESET_GETCONTENT_DATA:
            return {
                loading: false
            };
        default:
            return state;
    }
};

export default content

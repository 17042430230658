import React from "react";
import {Link} from "react-router-dom";
import {Alert, AlertTitle} from "@material-ui/lab";
import 'font-awesome/css/font-awesome.min.css';
import {prettyPrice} from "../../helpers/Tools";
import {CardActionArea, CardContent, Card, Typography, Grid, CardActions, IconButton} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import { withTranslation } from 'react-i18next';
import {OrderSummary} from "./OrderSummary";

class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDisplayOrderSummary: false,
            order:"",
            width: window.innerWidth,
            height: window.innerHeight,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight});
    };
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    handleClick(order){
        this.setState({ isDisplayOrderSummary: true, order: order});
    }

    render() {
        console.log(" %c ////////CONTENU-HISTO COMMANDE////////",  'background: #222; color: #bada55');

        const dyn_string = this.props.t;

        if(this.props.isConnected === false){
            return (
                <div style={{height:this.state.height}}>
                    <Alert severity="error">
                        <AlertTitle>Contenu Protégé </AlertTitle>
                        Veuillez vous <Link to={{pathname: this.props.routes.PathIdentification, state: {id: this.props.dataPage.id}}}>connecter</Link> pour
                        accèder à cette page
                    </Alert>
                </div>
            );
        } else {
            let orders = this.props.market.orders;
            let displayOrders ;

            if (orders.length > 0){
                displayOrders = (
                    <Grid container spacing={3} alignItems="center" justify="center">
                        {orders.map((order, key) => {
                            return (
                                <Grid item xs={12} sm={3} key={key}>
                                    <Card style={{marginTop:"15px"}}>
                                        <CardActionArea style={{textAlign:"center"}}>
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    Commande n°{order.reference}
                                                </Typography>
                                                <Typography gutterBottom variant="h6" component="h4">
                                                    {prettyPrice(parseFloat(order.amount_ttc).toFixed(2))} €
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" component="p">
                                                    {order.created_at}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <IconButton
                                                onClick={() => this.handleClick(order)}
                                                aria-label="Détails"
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                );
            } else {
                displayOrders = (
                    <h4>{dyn_string('g_label_no_result')}</h4>
                );
            }

            return (
                <div style={{textAlign:'center',height:this.state.height}}>
                    {displayOrders}
                    <div style={{marginTop:"20px"}}>
                        {this.state.isDisplayOrderSummary ?
                            (
                                <OrderSummary order={this.state.order}/>
                            )
                            : ""
                        }
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
   routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(OrderHistory);

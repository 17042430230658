
import {store} from "../store/store";
import axios from "axios";
import * as Constants from "./api.constants";
import {requestGetPlanningsData} from "../actions";
import {baseApiUrl} from "./api";

//GetPlannings
export const fetchGetPlannings = async () => {
    try {
        let bodyFormData = new FormData();
        let state = store.getState()

        bodyFormData.set("appId", state.application.getApp.id);

        if(state.profil.mobinaute)
            bodyFormData.set("mobinauteId", parseInt(state.profil.mobinaute.id, 10));

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_GET_PLANNINGS,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response.data;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//joinEvent
export const joinEvent = async (props) => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("appId", props.appId);
        bodyFormData.set("eventId", props.eventId);
        bodyFormData.set("mobinauteId", props.mobinauteId);
        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_JOIN_EVENT,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

//leaveEvent
export const leaveEvent = async (props) => {
    try {
        let bodyFormData = new FormData();
        bodyFormData.set("appId", props.appId);
        bodyFormData.set("eventId", props.eventId);
        bodyFormData.set("mobinauteId", props.mobinauteId);
        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_LEAVE_EVENT,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                return response;
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            });
    } catch (e) {
        console.log(e);
    }
};

export const deleteEvtEvent = async (props) => {
    try {
        let bodyFormData = new FormData();

        bodyFormData.set("eventId", props.eventId);
        bodyFormData.set("planningId", props.planningId);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_DELETE_EVT_EVENT,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(function (response) {

            store.dispatch(requestGetPlanningsData())

            return response.data
        });
    } catch (e) {
        console.log(e);
    }
};

export const setEvtEvent = async (props) => {
    try {
        let bodyFormData = new FormData();

        bodyFormData.set("eventId", props.eventId);
        bodyFormData.set("planningId", props.planningId);
        bodyFormData.set("mobinauteId", props.$mobinauteId);
        bodyFormData.set("title", props.title);
        bodyFormData.set("description", props.description);
        bodyFormData.set("startDate", props.startDate);
        bodyFormData.set("endDate", props.endDate);
        bodyFormData.set("startTime", props.startTime);
        bodyFormData.set("endTime", props.endTime);
        bodyFormData.set("addressStreet1", props.addressStreet1);
        bodyFormData.set("addressStreet2", props.addressStreet2);
        bodyFormData.set("addressZip", props.addressZip);
        bodyFormData.set("addressCity", props.addressCity);
        bodyFormData.set("addressCountry", props.addressCountry);

        return await axios({
            method: "post",
            url: baseApiUrl + Constants.WS_SET_EVT_EVENT,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(function (response) {

            store.dispatch(requestGetPlanningsData())

            return response.data
        });
    } catch (e) {
        console.log(e);
    }
};

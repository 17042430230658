export const REQUEST_GETAUTHENTICATION_DATA = "REQUEST_GETAUTHENTICATION_DATA";
export const RECEIVE_GETAUTHENTICATION_DATA = "RECEIVE_GETAUTHENTICATION_DATA";
export const RESET_GETAUTHENTICATION_DATA = "RESET_GETAUTHENTICATION_DATA";
export const NEW_DATA_CONTENT_AUTH_FROM_CONNECTOR = "NEW_DATA_CONTENT_AUTH_FROM_CONNECTOR";

export const requestGetAuthenticationData =  data => ({
    type: REQUEST_GETAUTHENTICATION_DATA,
    data
});

export const receiveGetAuthenticationData = data => {
    return {
        type: RECEIVE_GETAUTHENTICATION_DATA,
        payload : data
    };
}

export const resetAuthenticationData = () => ({
    type: RESET_GETAUTHENTICATION_DATA
});

export const newDataContentAuthFromConnector = result => {
    return {
        type: NEW_DATA_CONTENT_AUTH_FROM_CONNECTOR,
        payload : result
    }
};

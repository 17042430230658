import React from "react";
import {Col} from "react-bootstrap";
import {TextField, Typography} from "@material-ui/core";
import {prettyPrice} from "../../../helpers/Tools";
import {ShapperButton} from "../../../components/ShapperButton/ShapperButton";

export const CartFormResponse = (props) => {

    const dyn_string  = props.t;

    return (
        <div>
            <div className="row justify-content-md-center" style={{marginTop:"20px"}}>
                <Col>
                    {props.order.discounts.map((response, index) => {
                        return (
                            <div key={index}>
                                <Typography>
                                    {response.title} : {prettyPrice((parseFloat(response.amount)).toFixed(2))} €
                                </Typography>
                            </div>
                        );
                    })}
                </Col>
            </div>
            <div className="row justify-content-md-center" style={{marginTop:"20px"}}>
                <Col>
                    {props.order.form_response.map((response, index) => {
                        return (
                            <div key={index}>
                                <Typography>
                                    {response.key} : {response.value}
                                </Typography>
                            </div>
                        );
                    })}
                </Col>
            </div>
        </div>
    )
}

import {useSelector} from "react-redux";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

export const ConfirmDeleteCard = (props) => {
    const style = useSelector(state => state.style);
    const { t } = useTranslation();

    function onDeleteCard(){
        props.deleteCard(props.cardId);
        props.onClose();
    }

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>{t('market_alert_title_info_delete_card')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('market_alert_msg_info_delete_card')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onDeleteCard}
                    style={{backgroundColor: "white",border: "solid 1px", borderColor: style.button.backgroundColor, color: style.button.color}}
                >
                    {t('market_label_remove_card')}
                </Button>

                <Button
                    onClick={props.onClose}
                    style={{backgroundColor: style.button.backgroundColor, borderColor: style.button.backgroundColor, color: style.button.color}}
                >
                    {t('g_btn_cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export const ErrorDeleteCard = (props) => {
    const style = useSelector(state => state.style);
    const { t } = useTranslation();

    return (
        <Dialog
            open={props.isOpen}
            onClose={props.onClose}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>{t('market_alert_title_err_warning')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('market_alert_msg_err_delete_card')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                    style={{backgroundColor: style.button.backgroundColor, borderColor: style.button.backgroundColor, color: style.button.color}}
                >
                    {t('g_btn_ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

 import React from "react";
import {Link} from "react-router-dom";
import {urlrewriting} from "../../helpers/Tools";
import {
    Paper,
    IconButton,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions, DialogTitle
} from "@material-ui/core";
 import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { ViewState } from "@devexpress/dx-react-scheduler";
import {Scheduler, WeekView, Toolbar, DateNavigator, Appointments, AllDayPanel, ViewSwitcher, MonthView, DayView, AppointmentTooltip, Resources} from "@devexpress/dx-react-scheduler-material-ui";
import {Image} from "react-bootstrap";
import no from '../../assets/no.png'
import low from '../../assets/low.png'
import high from '../../assets/high.png'
import {bindActionCreators} from "redux";
import {requestGetPlanningsData} from "../../actions";
import {connect} from "react-redux";
import {PopUpLoader, ShapperLoader} from "../../components/ShapperLoader/ShapperLoader";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Formulaire from "../Formulaire/Formulaire";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUndoAlt} from "@fortawesome/free-solid-svg-icons";
import {deleteEvtEvent, setEvtEvent} from "../../api/api"
 import OpenItem from "../../helpers/OpenItem";


class Planning extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            planning_name: "",
            current_planning: null,
            current_event: null,
            display_form: false,
            is_update_form: false,
            confirm_delete_event: false,
            isPlanning: true,
            loader:false
        };

        this.createResources = this.createResources.bind(this);
        this.updateEvent = this.updateEvent.bind(this);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    async componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);

        //met a jour les données des plannings pour eviter rechargement de la page
        this.props.requestGetPlanningsData();

        if(this.props.dataPage.planning_id){
            let current_planning = this.props.plannings.find((element) => element.id === this.props.dataPage.planning_id)
            this.setState({planning_name: current_planning.title, current_planning: current_planning, display_form:false})
        } else if(this.props.dataPage.type === "planning_mobinaute"){
            if(this.props.profil && this.props.profil.mobinaute){
                let full_name = this.props.profil.mobinaute.firstname +" "+ this.props.profil.mobinaute.lastname;
                this.setState({planning_name: full_name})
            }
        } else {
            //this.setState({isPlanning: false})
        }
    }

    handleDeleteEvtEvent(){
        deleteEvtEvent({
            eventId: this.state.current_event.id,
            planningId: this.state.current_planning.id
        }).then(() => {
            this.setState({
                confirm_delete_event: false,
                current_event: null
            })
        })
    }

    closeToolTip(appointmentData_id){
        //trouver meilleur moyen de close le tooltip
        let toolTipHeader =  document.querySelector('#custom_button_'+appointmentData_id);
        let toolTip = toolTipHeader.parentElement;
        if(toolTip)
            toolTip.remove();
    }

    createResources(plannings){
        let resources = [];
        let resource = {};
        let instances = [];

        plannings.forEach((planning) => {
            let instance = {};
            instance.id = planning.title;
            instance.text = planning.title;
            instance.color = planning.color;
            instances.push(instance)
        })

        resource.fieldName = "planning";
        resource.title = "Planning";
        resource.instances = instances;

        resources.push(resource)

        return resources;
    }

    async updateEvent(event){
        await this.setState({
            loader:true
        })

        event.eventId = this.state.current_event.id;
        event.planningId = this.state.current_planning.id;
        event.mobinauteId = this.props.profil.mobinaute.id;

        setEvtEvent(event).then(async () => {
            await this.setState({
                display_form: false,
                is_update_form: false,
                loader:false
            })
        })
    }

    render() {
        console.log(" %c ////////CONTENU-PLANNING////////",  'background: #222; color: #bada55');

        const dyn_string = this.props.t;

        let confirm_delete_event = (
            <Dialog
                open={this.state.confirm_delete_event}
                onClose={() => this.setState({confirm_delete_event: false})}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>{dyn_string('planning_alert_title_info_remove_event')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dyn_string('planning_alert_msg_info_remove_event')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.handleDeleteEvtEvent()}
                        style={{backgroundColor: "white", borderColor: this.props.styles.button.backgroundColor, color:this.props.styles.button.backgroundColor, border: "solid 1px"}}
                    >
                        {dyn_string('g_btn_yes')}
                    </Button>
                    <Button
                        onClick={() => this.setState({confirm_delete_event: false})}
                        style={{backgroundColor: this.props.styles.button.backgroundColor, borderColor: this.props.styles.button.backgroundColor, color: this.props.styles.button.color}}
                    >
                        {dyn_string('g_btn_no')}
                    </Button>
                </DialogActions>
            </Dialog>
        );

        if(this.state.isPlanning){
            if((this.props.plannings && this.state.current_planning) || (this.props.plannings && this.props.dataPage.type === "planning_mobinaute" )){
                if(this.state.display_form){
                    let formInfo = {
                        title: this.state.current_planning.form_event_creation.title,
                        label_validation_button: this.state.current_planning.form_event_creation.label_validation_button,
                        formulaire_id: this.state.current_planning.form_event_creation.id,
                        inputs_form: this.state.is_update_form ? null : this.state.current_planning.form_event_creation.inputs_form,
                        type_form: this.state.is_update_form ? "event_update" : this.state.current_planning.form_event_creation.type_form,
                        event : this.state.current_event
                    }

                    return (
                        <div>
                            <Formulaire
                                typeForm={formInfo.type_form}
                                Form={formInfo.inputs_form}
                                styles={this.props.styles}
                                formInfo={formInfo}
                                isEventCreated={() => {this.setState({display_form: false, is_update_form: false})}}
                                updateEvent={this.updateEvent}
                            >
                                <Button
                                    onClick={() => this.setState({ display_form: false, is_update_form: false})}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{backgroundColor:this.props.styles.button.backgroundColor, opacity: "0.8", marginBottom:"80px"}}
                                >
                                    {dyn_string('form_btn_product_cancel')}
                                    <FontAwesomeIcon
                                        icon={faUndoAlt}
                                        style={{ marginLeft: "10px"}}
                                    />
                                </Button>
                            </Formulaire>

                            <PopUpLoader active={this.state.loader} />
                        </div>
                    )
                } else {
                    let event_content = (({classes, data, formatDate, ...restProps}) => {
                        let availability;

                        if(data.availability === "high"){
                            availability = high;
                        } else if (data.availability === "no"){
                            availability = no;
                        } else if (data.availability === "low"){
                            availability = low;
                        }

                        return (
                            <Appointments.AppointmentContent {...restProps} formatDate={formatDate} data={data}>
                                <div style={{width:"100%"}}>
                                    <div style={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',}}>
                                        <Image src={availability} style={{width:"10px", marginRight:"5px"}}/>
                                        {data.title}
                                    </div>
                                    <div style={{ lineHeight: 1, whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%',}}>
                                        <div style={{display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis',}}>
                                            {formatDate(data.startDate, { hour: 'numeric', minute: 'numeric' })}
                                        </div>
                                        <div style={{display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis',}}>
                                            {' - '}
                                        </div>
                                        <div style={{display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis',}}>
                                            {formatDate(data.endDate, { hour: 'numeric', minute: 'numeric' })}
                                        </div>
                                    </div>
                                </div>
                            </Appointments.AppointmentContent>
                        );
                    });

                    let event_header = (({children, appointmentData, classes, ...restProps}) => {
                        return (
                            <AppointmentTooltip.Header
                                {...restProps}
                                appointmentData={appointmentData}
                                id={"custom_button_"+appointmentData.id}
                            >
                                {appointmentData.organizer_mobinaute_id === this.props.profil.mobinaute.id && (
                                    <div >
                                        <IconButton style={{backgroundColor: 'rgba(255,255,255,0.65)'}} onClick={() => {
                                            this.closeToolTip(appointmentData.id)

                                            this.setState({confirm_delete_event: true, current_event: appointmentData})
                                        }}>
                                            <DeleteOutlineIcon/>
                                        </IconButton>

                                        <IconButton style={{backgroundColor: 'rgba(255,255,255,0.65)'}} onClick={() => {
                                            this.setState({current_event: appointmentData, display_form: true, is_update_form: true})
                                        }}>
                                            <EditIcon/>
                                        </IconButton>
                                    </div>
                                )}
                                {
                                    this.props.isCategory &&
                                    <OpenItem
                                        contentToRedirect={appointmentData.id}
                                    >
                                        <IconButton style={{backgroundColor: 'rgba(255,255,255,0.65)'}}>
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </OpenItem>
                                }
                                {
                                    !this.props.isCategory &&
                                    <Link
                                        to={"/" + urlrewriting(appointmentData.planning) + "/" + urlrewriting(appointmentData.title) + "-" + appointmentData.id}>
                                        <IconButton style={{backgroundColor: 'rgba(255,255,255,0.65)'}}>
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </Link>
                                }
                            </AppointmentTooltip.Header>
                        );
                    });

                    let  FlexibleSpace = (({ classes, ...restProps }) => {
                        let create_event_btn = "";
                        if(this.state.current_planning && this.state.current_planning.participation_open && this.state.current_planning.form_event_creation &&  this.props.profil.mobinaute && this.props.profil.mobinaute.id ){
                            create_event_btn = (
                                <Button
                                    onClick={() => {this.setState({display_form: true, is_update_form: false})}}
                                    style={{marginRight: "15px", backgroundColor: this.props.styles.button.backgroundColor, borderColor: this.props.styles.button.backgroundColor, color: this.props.styles.button.color}}
                                >
                                    {dyn_string('planning_btn_create_event')}
                                </Button>
                            )
                        }

                        return (
                            <Toolbar.FlexibleSpace {...restProps} style={{flex :"none"}} >
                                <div style={{display: "flex", alignItems:"center"}}>
                                    <Typography variant="body1" style={{ marginLeft: '10px', marginRight: "15px" }}>{this.state.planning_name}</Typography>
                                    {create_event_btn}
                                </div>
                            </Toolbar.FlexibleSpace>
                        )
                    });

                    let inner_event_content = (({children, appointmentData, classes, ...restProps}) => {
                        let displayLine = false;
                        let availability;
                        let places_info;

                        if(this.state.current_planning.participationOpen === 1) {
                            if(isNaN(appointmentData.places)){
                                places_info = <p>{dyn_string('planning_label_unlimited_spots')}</p>
                            } else {
                                places_info = (appointmentData.places === 0) ? <p>{dyn_string('planning_label_no_remaining_spots')}</p> : <p>{dyn_string("planning_label_remaining_spots", {remaining_spots: appointmentData.places})}</p>;
                            }

                            if(appointmentData.availability === "high"){
                                availability = high;
                            } else if (appointmentData.availability === "no"){
                                availability = no;
                            } else if (appointmentData.availability === "low"){
                                availability = low;
                            }

                            displayLine = true
                        }

                        return (
                            <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
                                {
                                    displayLine &&
                                    <Grid container alignItems="center" style={{marginTop:"10px"}}>
                                        <Grid item xs={2} style={{textAlign: "center"}}>
                                            <Image src={availability} style={{width:"15px", textAlign:"center"}}/>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <span> {places_info}</span>
                                        </Grid>
                                    </Grid>
                                }
                            </AppointmentTooltip.Content>
                        );
                    });

                    let defaultViewName = "Month";
                    let display = this.props.dataPage.display_type;

                    if(display){
                        switch (display) {
                            case "month":
                                defaultViewName ="Month";
                                break;
                            case "week":
                                defaultViewName ="Week";
                                break;
                            case "day":
                                defaultViewName ="Day";
                                break;
                            default:
                                defaultViewName ="Month";
                        }
                    }

                    return (
                        <div>
                            <Paper>
                                <Scheduler
                                    data={this.props.events}
                                    locale={this.props.application.language}
                                >
                                    <ViewState defaultCurrentDate={Date.now()} defaultCurrentViewName={defaultViewName}/>
                                    <MonthView startDayHour={7} endDayHour={22} displayName="Mois"/>
                                    <WeekView startDayHour={7} endDayHour={22} displayName="Semaine"/>
                                    <DayView startDayHour={7} endDayHour={22} displayName="Jour"/>
                                    <AllDayPanel messages={{allDay: "Toute la journée"}}/>

                                    <Toolbar
                                        flexibleSpaceComponent={FlexibleSpace}
                                    />
                                    <DateNavigator />
                                    <ViewSwitcher />

                                    <Appointments
                                        appointmentContentComponent={event_content}
                                    />

                                    <AppointmentTooltip
                                        headerComponent={event_header}
                                        contentComponent={inner_event_content}
                                        showCloseButton
                                    />
                                    <Resources data={this.createResources(this.props.plannings)} />

                                </Scheduler>
                            </Paper>

                            {confirm_delete_event}
                        </div>
                    );
                }
            } else {
                return <ShapperLoader width={this.state.width} height={this.state.height}/>
            }
        } else {
            return <div style={{height: this.state.height-250}}><h4 style={{marginTop:"70px", textAlign:"center"}}>{dyn_string('g_label_no_result')}</h4></div>
        }
    }
}

const mapStateToProps = (state) => ({
    styles: state.style,
    profil: state.profil,
    application: state.application,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetPlanningsData,
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Planning);

import React  from "react";
import {ImageCustom} from "../../helpers/Tools";
import image_not_found from "../../assets/image_not_found.png";
import "./CardLandscape.css"
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export const CardLandscape = (props) => {

    const {item} = props;

    return (
        <div className="card-landscape-item">

            <div className="card-landscape-item-preview">
                <ImageCustom
                    src={item.media}
                    alt={item.title}
                    fallback={
                        <img style={{width: "100%", height:"100%", objectFit:"cover"}} src={image_not_found} alt={"image_not_found"}/>
                    }
                    type="category"
                />
            </div>

            <div className="card-landscape-item-info-wrapper">
                <div className="card-landscape-item-info">
                    <CardLandscapeInfo {...props} />
                </div>

                <CardLandscapeSubInfo {...props} />

            </div>

            <div className="card-landscape-item-icon">
                <ArrowForwardIosIcon />
            </div>

        </div>
    );
}

const CardLandscapeInfo = (props) => {

    const {item, type} = props;

    if(type === "announce") {
        return (
            <React.Fragment>
                <h4 className="break-spaces">{item.title}</h4>
                <span className="break-spaces">{item.short_description}</span>

                {item.price_ttc && (
                    <span style={{fontWeight: "600", fontSize:"1.5em"}}>{item.price_ttc} €</span>
                )}

            </React.Fragment>
        );
    }

    if(type === "product"){
        return (
            <React.Fragment>
                <h4 className="break-spaces">{item.title}</h4>
                <span className="break-spaces">{item.short_description}</span>
            </React.Fragment>
        );
    }
}

const CardLandscapeSubInfo = (props) => {

    const {item, type} = props;

    if(type === "announce") {
        return (
            <div className="card-landscape-item-sub-info">
                <span >{item.location}</span>
                <span >{item.created_at_string}</span>
            </div>
        );
    }

    if(type === "product"){
        return (
            <div>
                <span style={{fontWeight: "600", fontSize:"1.5em"}}>{item.price_ttc} €</span>
            </div>
        );
    }
}



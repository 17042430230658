import React from "react";
import {Divider, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {detachPaymentMethod} from "../../../../api/api";
import "./stripe.css"

export const StripeCardsElement = (props) => {
    let card = props.card;

    const removePaymentMethod = async (paymentMethod) => {
        detachPaymentMethod({pmId: paymentMethod.id})
            .then(data => {
                if(data.success && !data.result.customer){
                    props.hydratePaymentMethods(data.result.id);
                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    return (
        <div>
            <List>
                <ListItem button>
                    <ListItemIcon onClick={() => {props.handleCardChoice(props.paymentMethod)}}>
                        <div className={card.className}/>
                    </ListItemIcon>
                    <ListItemText
                        primary={<span>{card.label} {card.isSelected ? <i className="fa fa-check-circle" style={{color: "green"}} /> : ""}</span>}
                        secondary={"... " + card.last4}
                        style={{ color:'black' }}
                        onClick={() => {props.handleCardChoice(props.paymentMethod)}}
                    />

                    <i className="fa fa-times PickUpIcon" style={{cursor: "pointer", marginLeft:"8px", color:"gray"}} onClick={() => removePaymentMethod(props.paymentMethod)}/>
                </ListItem>
            </List>
            <Divider variant="middle" />
        </div>
    );
}

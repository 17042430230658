import React, {Component} from "react";
import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import {SpinnerLoader} from "../../../components/ShapperLoader/ShapperLoader";
import {ListMapInfoWindow} from "./ListMap.InfoWindow";
import "./ListMap.css"
import icon_customer_location from "../../../assets/icon_customer_location.svg"
import {pushHistory} from "../../../helpers/Tools";

const LoadingContainer = () => (
    <SpinnerLoader width="800px" height="200px" />
)

export class MapContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingInfoWindow: false, //Hides or the shows the infoWindow
            activeMarker: {}, //Shows the active marker upon click
            selectedPlace: {}, //Shows the infoWindow to the selected place upon a marker
            urlRedirect: ""
        };
    }

    onMarkerClick = (props, marker) => {
        let itemRedirect = this.props.RoutePathArray[props.id];

        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
            urlRedirect: itemRedirect ? itemRedirect.url : ""
        })
    }
    ;

    onClose = () => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null,
                urlRedirect: ""
            });
        }
    };

    redirectPlace = () => {
        pushHistory(this.state.urlRedirect)
    };

    render() {
        let containerStyle;
        if(this.props.width <= 800){
            containerStyle = {position: 'relative', width: "100%", height:"100%" , minHeight: "250px"}
        } else {
            containerStyle = {position: 'relative', width: "100%", height:"100%" , minHeight: "500px"}
        }

        return (
            <>
                <Map
                    zoom={this.state.width <= 800? 13 : 10}
                    google={this.props.google}
                    containerStyle={containerStyle}
                    initialCenter={this.props.initialCenter}
                    center={this.props.targetDestination}
                >

                    {this.props.pointMap.map((data,key) =>{
                        if(data && data.position.lat && data.position.lng){
                            return <Marker key={key} id={data.id} name={data.title} title={data.title} position={{lat: data.position.lat, lng: data.position.lng}} onClick={this.onMarkerClick}  />
                        } else return ""
                    })}

                    {this.props.customerEmplacement && (
                        <Marker key="customer"
                                position={{lat: this.props.customerEmplacement.lat, lng: this.props.customerEmplacement.lng}}
                                icon={{
                                    url: icon_customer_location,
                                }}
                        />
                    )}

                    <ListMapInfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}
                        onClose={this.onClose}
                    >
                        <div id="list-map-info-window" onClick={this.redirectPlace.bind(this, this.state.selectedPlace)}>
                            <h3 style={{margin: "0"}}>{this.state.selectedPlace.name}</h3>
                            <i className="fa fa-plus-circle" style={{color: this.props.principal_color, padding: "8px"}} />
                        </div>
                    </ListMapInfoWindow>
                </Map>
            </>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyAhntmTSeXUwKG8vPR2LRZpUf2cdHRshCo",
    LoadingContainer: LoadingContainer
})(MapContainer);

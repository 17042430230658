import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import React, {useRef, useState} from "react";
import "./sigCanvas.css"
import SignaturePad from "react-signature-canvas";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab} from "@material-ui/core";
import {faTimesCircle, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BorderColorIcon from '@material-ui/icons/BorderColor';

export const Signature = (props) => {
    const style = useSelector(state => state.style);
    const sigCanvas = useRef({})
    const { t } = useTranslation();
    const [openSignature, setOpenSignature] = useState(false);
    const [trimmedDataURL, setTrimmedDataURL] = useState(null);

    const clear = (e) => {
        e.preventDefault()
        if(sigCanvas.current){
            sigCanvas.current.clear()
        }
        props.clearState(props.name);
        setTrimmedDataURL(null)
    };

    const save = async (e) => {
        e.preventDefault()

        if(!sigCanvas.current.isEmpty()) {
            let dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");

            setTrimmedDataURL(dataUrl);

            let blob = await fetch(dataUrl).then((res) => res.blob());
            let file = new File([blob], "signature.png", {type: "image/png"})

            props.handleSignature(props.name, file)
        }

        setOpenSignature(false)
    };

    let SignaturePopUp = (
        <Dialog
            open={openSignature}
            onClose={() => setOpenSignature(false)}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>Signature</DialogTitle>
            <DialogContent>
                <SignaturePad
                    ref={sigCanvas}
                    canvasProps={{
                        className: "signatureCanvas"
                    }}
                />
                <FontAwesomeIcon
                    icon={faTrash}
                    style={{marginLeft: "1vw", cursor:"pointer", fontSize:"2em"}}
                    onClick={clear}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(e) => {
                        setOpenSignature(false);
                        clear(e)
                    }}
                    style={{backgroundColor: style.button.backgroundColor, borderColor: style.button.backgroundColor, color: style.button.color}}
                >
                    {t('g_btn_cancel')}
                </Button>
                <Button
                    onClick={save}
                    style={{backgroundColor: style.button.backgroundColor, borderColor: style.button.backgroundColor, color: style.button.color}}
                >
                    {t('g_btn_ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <div id="signature_pad">
            {trimmedDataURL ?
                (
                    <div style={{display: "flex", width:"inherit"}}>
                        <div className="signatureCanvasPreview">
                            <img src={trimmedDataURL} alt={""} style={{width: "inherit"}}/>
                        </div>
                        <span style={{marginLeft: "1vw", display: "flex", alignItems: "center"}}>
                            <FontAwesomeIcon
                                icon={faTimesCircle}
                                style={{marginLeft: "1vw", cursor: "pointer"}}
                                onClick={clear}
                            />
                        </span>
                    </div>
                ) : (
                    <label onClick={(e) => {
                        e.preventDefault();
                        if(!props.disabled){
                            setOpenSignature(true)
                        }
                    }} >
                        <Fab variant="extended" component="span" style={{color: props.disabled ? "rgba(0, 0, 0, 0.54)" : style.button.backgroundColor, margin: "10", backgroundColor: style.button.color}}>
                            <BorderColorIcon style={{marginRight: "8px"}}/>
                            {t('g_btn_signature')}
                        </Fab>
                    </label>
                )
            }

            {SignaturePopUp}
        </div>
    )
}

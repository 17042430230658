import React from "react";
import md5 from 'md5';
import {buildShapperPath, extractShapperStaticEndPoint, isJsonParsable} from "../../helpers/Tools"
import {store} from "../../store/store";

export const REGEX_URL = new RegExp('(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})', 'mi');

export const conditionalScript = (tab, script, js_input = false) => {
    let allowToExamine = true;

    if(!js_input){
        let regex = /v_\d+/gim
        let keys = script.match(regex)

        keys.forEach((key) => {
            if(!(key in tab)){
                allowToExamine = false
            }
        })
    }


    if(allowToExamine){
        let overscript = "function (tab) {" +
            "for (var key in tab){" +
            "var value = tab[key]; " +
            "window[key] = value; " +
            "} " +script+"}";

        function parseFunction (str) {
            let fn_body_idx = str.indexOf('{'),
                fn_body = str.substring(fn_body_idx+1, str.lastIndexOf('}')),
                fn_declare = str.substring(0, fn_body_idx),
                fn_params = fn_declare.substring(fn_declare.indexOf('(')+1, fn_declare.lastIndexOf(')')),
                args = fn_params.split(',');

            args.push(fn_body);

            function Fn () {
                return Function.apply(this, args);
            }
            Fn.prototype = Function.prototype;

            return new Fn();
        }

        //console.log("overscript overscript overscript overscript overscript");
        //console.log(overscript);

        let devFunc = (parseFunction(overscript));
        //console.log("devFunc devFunc devFunc devFunc devFunc");
        //console.log(devFunc);
        // Return True ou False selon les données passées en paramètre :

        let res = devFunc(tab);

        //console.log("res res res res res");
        //console.log(res);

        return res;
    } else {
        return false
    }
};

export function constructDynamicFormData (formData, Form, responses, idName, formId, uniqId){
    const persistStore = store.getState()
    const mobinaute = persistStore.profil.mobinaute;
    const application = persistStore.application;

    formData.formId = formId;

    if(mobinaute){
        formData.mobinauteId = mobinaute.id;
    }else {
        formData.mobinauteId = null;
    }
    formData.appId = application.getApp.id;
    formData.responses = responses;
    formData.isImageStatic = true;
    formData.deviceId = application.deviceId;

    if(uniqId) {
        formData.uniqID = uniqId;
    }

    //gere le non envoi des conditionnels non selectionnés
    Form.forEach((res) => {
        if(res.conditional_script !== null){
            if (!conditionalScript(idName, res.conditional_script)){
                delete formData.responses[res.id];
            }
        }
    })

    return formData;
}

export function jsOutputTreatment(inputs, variableContainer){
    let jsOutputs = inputs.filter(element => element.type === "js_output");
    let states_tmp = {}
    let responses_tmp = {}
    let variable_container = {}

    jsOutputs.forEach(input => {
        let value = null;
        let resultConditionalScript = conditionalScript(variableContainer, input.js_output, true);
        if(resultConditionalScript){
            value = resultConditionalScript.toString();
        }

        states_tmp = {...states_tmp, ["v_"+input.id]:value}
        responses_tmp = {...responses_tmp, [input.id]:value}

        //console.log(input.js_input + " => " + value);

        variableContainer = {...variableContainer, [input.js_input]:value}

    })

    return {
        states_tmp: states_tmp,
        responses_tmp: responses_tmp,
        variable_container: variableContainer
    }
}

export function constructTitleAndInstruction(typeForm, getApp, formInfo, dataPage, dyn_string){
    let title = "";
    let instruction = "";

    switch (typeForm) {
        case "ProfilForm":
            instruction = getApp.account_instruction;
            break
        case "RegisterForm":
            instruction = getApp.register_instruction;
            break
        case "market":
            if(!formInfo){
                return <div><h4 style={{marginTop:"70px", textAlign:"center"}}>{dyn_string('g_label_no_result')}</h4></div>
            }

            title = formInfo.title;

            break;
        case "event_creation":
            if(formInfo){
                title = formInfo.title;
            } else if(dataPage && dataPage.form && dataPage.form.title){
                title = dataPage.form.title;
            } else {
                return <div><h4 style={{marginTop:"70px", textAlign:"center"}}>{dyn_string('g_label_no_result')}</h4></div>
            }

            break;
        case "event_update":
            if(!formInfo){
                return <div><h4 style={{marginTop:"70px", textAlign:"center"}}>{dyn_string('g_label_no_result')}</h4></div>
            }

            title = formInfo.event.title
            break
        case "dynamic":
        case "local":
        case "announce":
            title = dataPage.title;
            break
        default :
            title = "";
            instruction = "";
            break;
    }

    return {
        title: title,
        instruction: instruction
    }
}

export function constructFormData(typeForm, profil, application, formState, formInfo){
    let formData_tmp = {};

    if(typeForm === "ProfilForm"){
        formData_tmp = {
            mobinauteId : profil.mobinaute.id,
            appId : application.getApp.id,
            login : formState["login"],
            Email : formState["Email"],
            firstname : formState["firstname"],
            lastname : formState["lastname"],
            responses : formState.responses
        }

        if (parseInt(application.getApp.password_update) === 1){
            if(formState.password){

                formData_tmp.pass = formState.password;
                if (parseInt(application.getApp.hash_pass_serverside) === 0){
                    formData_tmp.pass = md5(formState.password);
                }

            } else {
                delete formData_tmp.pass;
            }
        }

    } else if(typeForm === "RegisterForm"){
        formData_tmp = {
            deviceId : parseInt(application.deviceId),
            appId : parseInt(application.getApp.id),
            login : formState.login,
            Email : formState.Email,
            firstname : formState.firstname,
            lastname : formState.lastname,
            responses : formState.responses
        }

        if(formState.password){
            formData_tmp.pass = formState.password;
            if (parseInt(application.getApp.hash_pass_serverside) === 0){
                formData_tmp.pass = md5(formState.password);
            }
        } else {
            formData_tmp.pass = undefined;
        }

    } else if(typeForm === "market"){
        formData_tmp = {
            formId : formInfo.formulaire_id,
            appId : parseInt(application.getApp.id),
            isImageStatic : true,
            deviceId : parseInt(application.deviceId),
            responses : formState.responses
        }

        if(profil.mobinaute){
            formData_tmp.mobinauteId = profil.mobinaute.id;
        }else {
            formData_tmp.mobinauteId = null;
        }

    } else if(typeForm === "event_creation"){
        formData_tmp = {
            formId : formInfo.formulaire_id,
            appId : parseInt(application.getApp.id),
            deviceId : parseInt(application.deviceId),
            responses : formState.responses
        }

        if(profil.mobinaute){
            formData_tmp.mobinauteId = profil.mobinaute.id;
        }else {
            formData_tmp.mobinauteId = null;
        }
    }

    return formData_tmp;
}

function initializeVariableContainer(res, variableStorage){
    let VariableContainer_tmp = {};

    if(res.type === 'list' || res.type === 'List_Multi' || res.type === 'list_btn' || res.type === 'list_multi_btn'){
        console.log(res.js_input + " => ");
        VariableContainer_tmp[res.js_input] = "";
    } else {
        if(res.default_value){
            var varValue = res.type === 'Boolean' ? true : getValueFromVariable(res.default_value, variableStorage);
            console.log(res.js_input + " => " + varValue);
            console.log(varValue);
            VariableContainer_tmp[res.js_input] = varValue;
        } else {
            var varValue = res.type === 'Boolean' ? false : "";
            console.log(res.js_input + " => " + varValue);
            VariableContainer_tmp[res.js_input] = varValue;
        }
    }

    console.log("initializeVariableContainer");
    console.log(VariableContainer_tmp);

    return VariableContainer_tmp;
}

function getValueFromVariable(value, variableStorage){

    let regEx = /{([^}]*)}/gi;
    let matches = value.matchAll(regEx)
    let new_value = value;

    for(let match of matches){
        if(variableStorage[match[1]]){

            if(new_value === "{" + match[1] + "}"){
                return variableStorage[match[1]];
            }
            else {
                new_value = new_value.replaceAll(match[0], variableStorage[match[1]])
            }

        } else {
            new_value = "";
        }
    }

    return new_value;
}

export function initializeDefaultValue(res, variableStorage){
    let VariableContainer_tmp = {};
    let input_value_tmp;
    let responses_tmp = null;

    if(res.js_input){

        console.log(res + " => " + variableStorage);
        console.log(res);
        console.log(variableStorage);
        VariableContainer_tmp = initializeVariableContainer(res, variableStorage);
    }

    //Utilisé dans le cas du profil
    if(res.response){
        if(res.response.value_text){
            res.default_value = res.response.value_text;
        }
        else if(res.response.value_datetime){
            res.default_value = res.response.value_datetime;
        }
        else if(res.response.value_list_id){
            res.default_value = res.response.value_list_id;
        }
        else if(res.response.value_num){
            res.default_value = res.response.value_num;
        }
    }
    else {
        if(res.value_text){
            res.default_value = res.value_text;
        }
        else if(res.value_datetime){
            res.default_value = res.value_datetime;
        }
        else if(res.value_list_id){
            res.default_value = res.value_list_id;
        }
        else if(res.value_num){
            res.default_value = res.value_num;
        }
    }


    console.log(res);

    switch (res.type){
        case 'text':
        case 'Long_text':
        case 'URL':
        case 'telephone':
        case 'mail':
        case 'numeric':
        case 'integer':
            if(res.default_value){

                let new_default_value = getValueFromVariable(res.default_value, variableStorage);

                input_value_tmp = new_default_value;
                responses_tmp = new_default_value;
            } else {
                input_value_tmp = "";
            }
            break;
        case 'List_Multi':
        case 'list_multi_btn':
            if(res.default_value){
                let vals = res.default_value.split(",");
                input_value_tmp = vals;
            }
            else {
                input_value_tmp = [];
            }
            break;
        case 'list_btn':
        case 'list':
            if(res.default_value){
                input_value_tmp = res.default_value;
            }
            else {
                input_value_tmp = "";
            }

            break;
        case 'Time':
            if(res.default_value){
                let time = res.default_value.split(" ")[1]

                input_value_tmp = time;
                responses_tmp = "1111-11-11 "+ time;
            } else {
                input_value_tmp = "";
            }
            break;
        case 'Date_Time':
            if(res.default_value){
                let dateTime_tmp = res.default_value.split(" ");
                let dateTime = dateTime_tmp.join("T");

                input_value_tmp = dateTime;
                responses_tmp = dateTime;
            } else {
                input_value_tmp = "";
            }
            break;
        case 'date':
            if(res.default_value){
                let date = res.default_value.split(" ")[0]

                input_value_tmp = date;
                responses_tmp = date;
            } else {
                input_value_tmp = "";
            }
            break;
        case 'Boolean':
            if(res.default_value === "1"){
                input_value_tmp = true;
                responses_tmp = true;
            } else {
                input_value_tmp = false;
                responses_tmp = false;
            }
            break;
        case "file":
        case "Picture":
        case "video":
            if(res.default_value){
                let medias =  res.default_value.split(";")
                let response_value_tmp;

                if(medias.length === 1){
                    input_value_tmp = buildShapperPath(res.default_value);
                    response_value_tmp = extractShapperStaticEndPoint(res.default_value);
                } else {
                    let tmp_medias = []
                    let tmp_response = []
                    medias.forEach(media => {
                        tmp_medias.push(buildShapperPath(media))
                        tmp_response.push(extractShapperStaticEndPoint(media))
                    })

                    input_value_tmp = tmp_medias.join(";");
                    response_value_tmp = tmp_response.join(";");
                }
                responses_tmp = response_value_tmp
            }
            break;
        case "address":
            if(res.default_value && res.default_value && isJsonParsable(res.default_value)){
                let address = JSON.parse(res.default_value);
                var addressData_tmp = {};

                Object.entries(address).forEach(addressArray => {
                    if(addressArray[1]){
                        addressData_tmp[addressArray[0]] = addressArray[1];
                    }
                })

                responses_tmp = addressData_tmp;
            }
            break
        default :
            input_value_tmp = "";
            break;
    }




    console.log("----------variableStorage----------");
    store.getState().variableStorage = {...store.getState().variableStorage, ...VariableContainer_tmp};
    console.log("----------variableStorage----------");

    return {
        "VariableContainer_tmp":VariableContainer_tmp,
        "input_value_tmp" : input_value_tmp,
        "responses_tmp": responses_tmp
    }
}

export function constructVariableElement(my_input, target, check, value, VariableContainer){
    //let VariableContainer_tmp = {};

    if(target.type === 'checkbox'){
        //console.log(my_input.js_input + " => " + check);
        VariableContainer[my_input.js_input] = check;
    } else {
        if(my_input.type === "list" || my_input.type === "list_btn" ){
            var val_output = my_input.list_value.find((element) => element.id === value).id_output;
            if(!val_output){
                val_output = my_input.list_value.find((element) => element.id === value).text_value;
            }
            //console.log(my_input.js_input + " => " + val_output);
            VariableContainer[my_input.js_input] = val_output;
        }
        else if(my_input.type === "List_Multi" || my_input.type === "list_multi_btn"){
            let value_tmp = [];

            value.forEach(val =>{
                var val_output = my_input.list_value.find((element) => element.id === val).id_output;
                if(!val_output) {
                    val_output = my_input.list_value.find((element) => element.id === val).text_value;
                }

                value_tmp.push(val_output)
            })

            //console.log(my_input.js_input + " => " + value_tmp.join(","));
            VariableContainer[my_input.js_input] = value_tmp.join(",");
        }
        else if (my_input.type === "address") {

            let propertie = target.name.match(/\[(.*?)\]/gm)[0].replace("['", "").replace("']", "");
/*
            console.log("GLE - GLE - GLE - GLE");
            console.log(my_input);
            console.log(VariableContainer);
            console.log(my_input.js_input);
            console.log(propertie);
            console.log(VariableContainer[my_input.js_input]);
            console.log(value);
*/
            if(!VariableContainer[my_input.js_input]){
                VariableContainer[my_input.js_input] = {};
            }
            //console.log(my_input.js_input + "["+propertie+"] => " + value);
            VariableContainer[my_input.js_input][propertie] = value;

            //console.log("-OK-");

        } else {
            //console.log(my_input.js_input + " => " + value);
            VariableContainer[my_input.js_input] = value;
        }
    }

    /*console.log("constructVariableElement");
    console.log(my_input.js_input + " => " + value);
    console.log(VariableContainer);*/

    return VariableContainer;
}

export function constructEventElement(event){
    return {
        evt_update_title: event.title,
        evt_update_description: event.description,
        evt_update_start_date: event.startDateString,
        evt_update_start_time: event.startTimeString,
        evt_update_end_date: event.endDateString,
        evt_update_end_time: event.endTimeString,
        evt_update_address_street1: event.address_street1,
        evt_update_address_street2: event.address_street2,
        evt_update_address_city: event.address_city,
        evt_update_address_country: event.address_country,
        evt_update_address_zip: event.address_zip,
    }
}

export function decodeHtmlAscii( str ) { //remove & replace ascii char in string
    return str.replace( /&#([0-9]{1,7});/g, function( g, m1 ){
        return String.fromCharCode( parseInt( m1, 10 ) );
    }).replace( /&#[xX]([0-9a-fA-F]{1,6});/g, function( g, m1 ){
        return String.fromCharCode( parseInt( m1, 16 ) );
    });
}

export function checkDisabled(input, Files){

    let isDisabled = false;
    let max_value = input.max_value ? parseInt(input.max_value) : 1;

    if (input.visibility === "visible_read_only" || input.type === "qrcode"){
        isDisabled = true;
    } else {
        if((input.type === "Picture" || input.type === "video" || input.type === "file") &&  Array.isArray(Files[input.id]) && Files[input.id].length === max_value){
            isDisabled = true;
        } else if((input.type === "Picture" || input.type === "video" || input.type === "file") && Files[input.id] && max_value === 1){
            isDisabled = true;
        }
    }

    return isDisabled
}

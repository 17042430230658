import {store} from "./store/store";
import {addStyle} from "./actions";
import {buildShapperPath} from "./helpers/Tools";
import {
    fetchGetAppColor,
    fetchGetAppFonts, fetchGetApplication,
    fetchGetAppStyle,
    fetchGetSecretKey,
    fetchRegisterDevice
} from "./api/api.app";
import localForage from "localforage";

export function findColorById (TabColor,value,param){
    let color = TabColor.find(url => url.id === value);

    if(typeof color !== "undefined")
    {
        if(param === "color"){
            return color.color;
        }else if( param === "alpha")
            return  color.alpha;
    }
}

export function findFontById (TabFont,value){
    let font = TabFont.find(font => font.id === value)

    if(typeof font !== "undefined") {
        return font.name_web
    } else return ""
}

export async function initApp(){
    let device, color, font, style, getApp;

    let secret_key = await fetchGetSecretKey();

    if(secret_key){

        getApp = await fetchGetApplication();

        window.document.title = getApp.result.title;

        if(getApp.result && getApp.result.webParams){

            if(getApp.result.webParams.logo_url){

                const favicon = window.document.getElementById('favicon');

                favicon.href = buildShapperPath(getApp.result.webParams.logo_url);
            }
        }

        device = await  fetchRegisterDevice();
        color = await fetchGetAppColor ();
        font =  await fetchGetAppFonts();
        style =  await fetchGetAppStyle();

        if(device && style && color && font && style){
            await createStyle(style, color, font);

            return getApp;

        } else return null
    } else return null
}

export async function createStyle(style, color, font) {

    let styleTab = {};
    Object.values(style).forEach((value) =>
        {
            if(value){
                switch (value.component)
                {
                    case 'button':
                        styleTab[value.component] =  {
                            "alpha" : value.bg_alpha,
                            ":disabled{backgroundColor}" : findColorById(color,value.bg_color_disabled_id,"color"),
                            "backgroundColor": findColorById(color, value.bg_color_id, "color"),
                            "borderColor" : findColorById(color, value.bg_color_id, "color"),
                            "::selection{backgroundColor}": findColorById(color, value.bg_color_selected_id, "color"), //utilisation de pseudo classe?
                            "height" : value.cell_height !==  "0" ? value.cell_height+'%' : "", //% ou px
                            "cell_img_circular" : value.cell_img_circular, // disign -> cellules
                            "margin" : value.cell_margin !==  "0" ? value.cell_margin+'%' : "", // px ou %
                            "borderSpacing" : value.cell_separator+'px', //type de separateur ? boolen ?
                            "border": "1px solid",
                            "borderRadius" : value.corner_radius,
                            "icon_color_disabled_id" : findColorById(color, value.icon_color_disabled_id, "color"),
                            "icon_color_id" : findColorById(color, value.icon_color_id, "color"),
                            "icon_color_selected_id" : findColorById(color, value.icon_color_selected_id, "color"),
                            ":disabled{color}" : findColorById(color, value.text_color_disabled_id, "color"),
                            "color" :  findColorById(color,value.text_color_id,"color"),
                            "::selection{color}" : findColorById(color, value.text_color_selected_id, "color"),
                            "textShadow" : findColorById(color, value.text_color_shadow_id, "color"),
                            "fontSize" : value.text_size,
                            "text_color_disabled" : findColorById(color, value.text_color_disabled_id, "color"),
                            "text_color" : findColorById(color, value.text_color_id, "color"),
                            "text_color_selected" : findColorById(color, value.text_color_selected_id, "color"),
                            "text_color_shadow" : findColorById(color, value.text_color_shadow_id, "color"),
                            "text_font" : findFontById(font, value.text_font_id),
                        }

                        styleTab["principal_color"] = findColorById(color, value.bg_color_id, "color");
                        break;
                    case 'navbar':
                    case 'subtitle':
                    case 'menu':
                    case 'cell':
                    case 'listview':
                    case 'screen':
                    case 'textview':
                    case 'segmentedbar':
                    case 'textfield':
                    case 'pin':
                    case 'tabbar':
                    case 'image_label':
                        styleTab[value.component] =  {
                            "bg_alpha" : value.bg_alpha,
                            "bg_color_disabled" : findColorById(color,value.bg_color_disabled_id,"color"),
                            "bg_color_selected" : findColorById(color,value.bg_color_selected_id,"color"),
                            "backgroundColor": findColorById(color, value.bg_color_id, "color"),
                            "color" :  findColorById(color,value.bg_color_id,"color"),
                            "cell_height": value.cell_height,
                            "cell_img_circular": value.cell_img_circular,
                            "cell_separator": value.cell_separator,
                            "borderColor" : findColorById(color, value.bg_color_id, "color"),
                            "margin" : value.cell_margin !== "0" ? value.cell_margin : "", // px ou %
                            "height" : value.cell_height !== "0" ? value.cell_height : "", //% ou px,
                            "corner_radius" : value.corner_radius,
                            "icon_color_disabled" : findColorById(color, value.icon_color_disabled_id, "color"),
                            "icon_color" : findColorById(color, value.icon_color_id, "color"),
                            "icon_color_selected" : findColorById(color, value.icon_color_selected_id, "color"),
                            "text_color_disabled" : findColorById(color, value.text_color_disabled_id, "color"),
                            "text_color" : findColorById(color, value.text_color_id, "color"),
                            "text_color_selected" : findColorById(color, value.text_color_selected_id, "color"),
                            "text_color_shadow" : findColorById(color, value.text_color_shadow_id, "color"),
                            "text_font" : findFontById(font, value.text_font_id),
                            "is_default": value.is_default,
                            "os_name" : value.os_name,
                            "text_shadow_x" : value.text_shadow_x,
                            "text_shadow_y" : value.text_shadow_y,
                            "fontSize": value.text_size
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    );

    store.dispatch(addStyle(styleTab))
}

export function cleanLocalForage(){
    localForage.clear().then(() => {

        window.localStorage.clear();

        window.location.reload(false);

        //store.dispatch(resetApp())

    }).catch((e) => {
        console.log(e)
    })
}

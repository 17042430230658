import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {resetAuthenticationData} from "../../../actions";
import md5 from "md5";
import Cookies from "universal-cookie";
import {Typography, Button, TextField, Grid, Container, Checkbox, FormControlLabel, withStyles, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Alert } from "@material-ui/lab";
import {fetchGetAppStyle, fetchUpdatePassword, PasswordLost} from "../../../api/api";
import Formulaire from "../../Formulaire/Formulaire";
import { faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "semantic-ui-react";
import 'semantic-ui-css/semantic.min.css'
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import "../../Formulaire/Formulaire.css"

export const cookies = new Cookies();

class MobinauteUpdatePasswordFormulaire extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            login: "",
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            success: this.props.dataAuth ? this.props.dataAuth : "",
            loader:false,
            blur : 1,
            pass: false,
            checked: false,
            clicked: !!this.props.dataAuth
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showLogin = this.showLogin.bind(this);
    }


    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.dataAuth.success !== prevProps.dataAuth.success){
            if(!this.props.dataAuth.success){
                this.setState({ success: this.props.dataAuth, pass: true});
            }
        } else if (this.state.pass){
            if (this.state.loader === true && !this.state.success.success){
                this.setState({loader: false, blur: 1})
                this.setState({ success: this.props.dataAuth});
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({success: "", clicked:false, pass:false})

        this.setState({
            [name]: value,
        });
    }

    handleClick(e) {
        e.preventDefault();

        if (Object.keys(this.state.newPassword).length === 0 || Object.keys(this.state.oldPassword).length === 0 || Object.keys(this.state.confirmPassword).length === 0) {
            this.setState({success: {error: "Veuillez ne pas laisser de champs vides", error_code: 1201}, clicked:true})
        } else {
            if(this.state.newPassword !== this.state.confirmPassword){
                this.setState({success: {error: this.props.t('auth_alert_msg_err_new_pass'), error_code: 1201}, clicked:true})
            }
            else {
                this.setState({success: "",loader : true, blur : 0.2, clicked:true})

                let updatePassword = fetchUpdatePassword({mobinauteId: this.props.mobinaute_id, oldPassword: this.state.oldPassword, newPassword: this.state.newPassword}).then(() => {
                    window.location.href = "/" ;
                })
            }
        }
    }

    //Affichage du menu Login
    showLogin() {
        this.setState({ inscription: false, passwordlost: false });
    }

    render() {

        //alert("ok");
        let errorMessage = (this.state.success.error_code === 1200 || this.state.success.error_code === 1201 || this.state.success.error_code === 1072 || this.state.success.error_code === 1073) && this.state.clicked ?
            (
                <Alert severity="error">{this.state.success.error} </Alert>
            ) :  ""

        let dyn_string  = this.props.t;

        return (
            <>
                <Loader active={this.state.loader} size='large' />
                <Container component="main" maxWidth="xs" style={{opacity : this.state.blur}}>
                    <CssBaseline />
                    {errorMessage}
                    <div className="paper">
                        <Typography component="h1" variant="h5" style={{marginBottom : "10px"}}>
                            {this.props.application.getApp.update_password_required_title}
                        </Typography>

                        {this.props.application.getApp.update_password_required_info}

                        <form className="form" noValidate>
                            {this.props.application.getApp.authShapper === "1" && (
                                <>

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        label={dyn_string('auth_placeholder_password_old')}
                                        id="oldPassword"
                                        name="oldPassword"
                                        value={this.state.oldPassword}
                                        onChange={this.handleChange}
                                        type="password"
                                        placeholder={dyn_string('auth_placeholder_password_old')}
                                    />

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        label={dyn_string('auth_placeholder_password_new')}
                                        id="newPassword"
                                        name="newPassword"
                                        value={this.state.newPassword}
                                        onChange={this.handleChange}
                                        type="password"
                                        placeholder={dyn_string('auth_placeholder_password_new')}
                                    />

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        label={dyn_string('auth_placeholder_password_confirm')}
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        onChange={this.handleChange}
                                        type="password"
                                        placeholder={dyn_string('auth_placeholder_password_confirm')}
                                    />

                                    <Button
                                        disabled={this.state.loader}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            background: this.props.styles.button.backgroundColor,
                                            marginBottom: "10px",
                                            marginTop: "10px",
                                        }}
                                        onClick={this.handleClick}
                                    >
                                        {this.state.loader? dyn_string('loading_label_authentication') : dyn_string('form_btn_send')}
                                    </Button>
                                </>
                            )}
                        </form>
                    </div>
                </Container>
            </>
        );

    }
}


const mapStateToProps = (state) => ({
    data: state.content,
    dataAuth: state.contentauth,
    dataSSO : state.contentauthSso,
    styles: state.style,
    routes: state.routes,
    application: state.application
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(MobinauteUpdatePasswordFormulaire);

import React, {useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useTranslation} from "react-i18next";
import "../../../../Market/Cart/Stripe/stripe.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faUndoAlt} from "@fortawesome/free-solid-svg-icons";
import {Button, CircularProgress} from "@material-ui/core";
import {useSelector} from "react-redux";
import {ErrorMessage} from "../../../../../components/ErrorMessage/Error.Message";

export const StripeWalletCreateCard = (props) => {
    const style = useSelector(state => state.style);
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const [cardHolder, setCardHolder] = useState("");
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [loading, setLoading] = useState(false);

    async function handleCreateCard(){
        if (!stripe || !elements) {
            console.log(" Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.")
            return;
        }

        if(cardComplete){
            if(cardComplete && !cardHolder){
                setError({message: t('market_alert_msg_err_fill_card_holder')});
            } else {
                setError(null);
                let currentCard = cardComplete ? elements.getElement(CardElement) : null ;

                if(cardComplete){
                    setLoading(true)

                    let newCard = await stripe.confirmCardSetup(props.clientIntentSecret, {
                        payment_method: {
                            card: currentCard,
                            billing_details: {
                                name: cardHolder,
                            }
                        },
                    })

                    if(newCard && newCard.setupIntent && newCard.setupIntent.status === "succeeded"){
                        setLoading(false)

                        props.onClose();
                        props.hydrateInfoMobinaute();
                    } else {
                        setLoading(false)

                        setError({message: t('market_alert_msg_err_save_card')});
                    }

                } else {
                    setError({message: t('market_alert_msg_err_fill_card')});
                }
            }
        } else {
            setError({message: t('market_alert_msg_err_fill_card')});
        }
    }

    return (
        <div id="container-add-card-wallet">
            <div className="CardHolder">
                <h4 style={{margin: "0px"}}>{t('market_label_cart_holder_name')}</h4>
                <fieldset className="FormGroup">
                    <div className="FormRow">
                        <input
                            className="FormRowInput"
                            id="cardHolder"
                            type="text"
                            placeholder={t('market_label_cart_holder_placeholder')}
                            value={cardHolder}
                            onChange={(e) => {setCardHolder(e.target.value)}}
                        />
                    </div>
                </fieldset>
            </div>

            <div className="CardDetail">
                <h4 style={{margin: "0px"}}>{t('market_label_cart_details')}</h4>
                <fieldset className="FormGroup">
                    <CardElement
                        options={{style: {base: {fontSize: '16px',color: 'grey', '::placeholder': {color: '#aab7c4',},}, invalid: {color: 'red',},},}}
                        onChange={(e) => {
                            setError(e.error);
                            setCardComplete(e.complete);
                        }}
                    />
                </fieldset>
            </div>

            {error && <ErrorMessage>{error.message}</ErrorMessage>}

            <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ backgroundColor:style.button.backgroundColor, marginTop:"20px", marginBottom:"10px"}}
                onClick={handleCreateCard}
                disabled={loading || !!error}
            >
                {loading ? (
                    <CircularProgress size={25} style={{color:"white"}}/>
                ) : (
                   <>
                       {t('market_label_save_card')}
                       <FontAwesomeIcon
                           icon={faLock}
                           style={{ marginLeft: "10px"}}
                       /></>
                )}
            </Button>

            {!loading && (
                <Button
                    onClick={props.onClose}
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ backgroundColor:style.button.backgroundColor, opacity: "0.8"}}
                >
                    {t('form_btn_product_cancel')}
                    <FontAwesomeIcon
                        icon={faUndoAlt}
                        style={{ marginLeft: "10px"}}
                    />
                </Button>
            )}
        </div>
    )
};

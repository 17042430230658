/**
 * Permet d'effectuer une migration du store redux lors de la mise en prod
 * Il faut effectuer une migration a chaque changement de l'infrastructure du store redux et augmenter de 1 la version du store
 */
export const migrations = {
    3: state => {
        return {
            ...state,
            application: {
                ...state.application,
                redirectError: false
            },
            cart: {
                currentSupplier: null,
                items: [],
            },
        };
    },
    4: state => {
        return {
            ...state,
            application: {
                ...state.application,
                type: ""
            }
        };
    },
};


export const HttpsRedirect = (props) => {

    if (
        typeof window !== 'undefined'
        && window.location
        && window.location.protocol === 'http:'
        && process.env.NODE_ENV === "production"
        && props.force_https === "1"
    ) {
        window.location.href = window.location.href.replace(/^http(?!s)/, 'https');

        return null;
    }

    return props.children;
};

import {useSelector} from "react-redux";
import React from "react";
import {NavBarIdentification} from "../NavBarIdentification/NavBar.Identification";
import {Button} from "@material-ui/core";
import OpenItem from "../../../helpers/OpenItem";
import {buildShapperPath} from "../../../helpers/Tools";

export const NavBarMobileBody = (props) => {

    return (
        <React.Fragment>
            {props.items.map((item, key) => {

                if (item.title) {
                    if (item.type === "identification") {
                        return (
                            <NavBarIdentification
                                connected={props.connected}
                                identCompElement={item}
                                key={key}
                                width={props.width}
                                height={props.height}
                                closeMenu={props.closeMenu}
                            />
                        );
                    } else {
                        return (
                            <Button
                                style={{ display: "inherit" }}
                                key={key}
                                onClick={props.closeMenu}
                            >
                                <NavBarMobileBodyItemPreview item={item}/>

                                <OpenItem
                                    contentToRedirect={item.id}
                                    key={key}
                                    isFor={"navResp"}
                                >
                                    {item.title}
                                </OpenItem>
                            </Button>
                        );
                    }
                } else return "";
            })}
        </React.Fragment>
    )
}

const NavBarMobileBodyItemPreview = (props) => {
    const style = useSelector(state => state.style);

    if(props.item.preview_url){
        return (
            <img
                src={buildShapperPath(props.item.preview_url)}
                className="d-inline-block align-top"
                alt="logo-menu-item"
                style={{ marginRight: "3px", width: style.menu.fontSize+"px" }}
            />
        )
    } else {
        let full_className = constructClassName(props.item.icon_name);

        return (
            <div className={full_className} style={{color: style.menu.icon_color, marginRight: "3px", fontSize: style.menu.fontSize+"px", width:style.menu.fontSize+"px"}}/>
        )
    }
}

function constructClassName (icon_name){
    let full_className;
    if (icon_name){
        full_className = "fa ".concat(icon_name); //FontAwesome
    } else {
        full_className = "fa fa-arrow-circle-right"; //FontAwesome default
    }

    return full_className;
}

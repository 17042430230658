//Reducers for GetPlannings
import { RECEIVE_GETCONNECTORS_DATA } from "../actions/index";

const initState = {
    success: false,
    result: [],
}

const connectors = (state = initState, action) => {
    switch (action.type) {
        case RECEIVE_GETCONNECTORS_DATA:
            return {
                success: action.payload.success,
                result: action.payload.result,
            };
        default:
            return state;
    }
}

export default connectors
